import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "page_components/blog/Breadcrumbs"
import Hero from "page_components/blog/Hero"
import Post from "page_components/blog/Post"
import Grid from "page_components/blog/Grid"

const SinglePost = ({ pageContext, data, location }) => {
  const pageData = pageContext.data
  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: pageData.title,
    image: pageData?.featuredImage?.node?.localFile?.publicURL
      ? `https://4fchange.com${pageData.featuredImage.node.localFile.publicURL}`
      : "",
    editor: `${pageData?.author?.node?.firstName} ${pageData?.author?.node?.lastName}`,
    genre:
      pageData?.categories?.nodes?.length > 0
        ? pageData.categories.nodes.map(({ name }) => name).join(", ")
        : "",
    url: `https://4fchange.com/aktualnosci/${pageData.slug}`,
    datePublished: pageData.date,
    dateCreated: pageData.date,
    dateModified: pageData.date,
    description: pageData.excerpt,
    author: {
      "@type": "Person",
      name: `${pageData?.author?.node?.firstName} ${pageData?.author?.node?.lastName}`,
    },
  }
  return (
    <Layout
      location={location}
      translations={pageContext.data.translations}
      seo={{
        title: pageData.seo.title ? pageData.seo.title : pageData.title,
        description: pageData.seo.metaDesc
          ? pageData.seo.metaDesc
          : pageData.excerpt,
        image:
          pageData.featuredImage &&
          pageData.featuredImage.node.localFile.publicURL,
        schemaMarkup: schema,
      }}
    >
      <Hero post={pageData} />
      <Breadcrumbs title={pageData?.title} />
      <Post data={pageData} />
      <Grid posts={data?.allPost?.nodes} type="post" query="" />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $code: WpLanguageCodeEnum) {
    allPost: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 3
      filter: { slug: { ne: $slug }, language: { code: { eq: $code } } }
    ) {
      nodes {
        id
        slug
        date
        title
        excerpt
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default SinglePost
