import React from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Button from "components/Button"
import ThanksIcon from "icons/thanks.svg"

import StyledContactThankYouPage from "styles/ContactThankYouStyles"

const ContactThankYouPage = ({ location }) => (
  <Layout seo={{ title: "Thank you" }} location={location}>
    <PageHeader title="Thank you!" />
    <StyledContactThankYouPage>
      <div className="main-container">
        <div className="main-wrapper">
          <div className="icon-holder">
            <ThanksIcon />
          </div>
          <h4>Thank you for sending the message.</h4>
          <p>We will contact you within 24 hours.</p>
          <Button text="Home" as="link" href="/en/" />
        </div>
      </div>
    </StyledContactThankYouPage>
  </Layout>
)

export default ContactThankYouPage
