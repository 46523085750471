import StyledGiveSecondChance from "./styles"

import React, { useState } from "react"
import ReactPlayer from "react-player"
import { useLocale } from "context/locale"

import Button from "components/Button"

import IcoPlay from "icons/play.svg"

const GiveSecondChance = () => {
  const { t } = useLocale()

  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <StyledGiveSecondChance>
      <div className="give-second-chance">
        <div className="give-second-chance__video">
          <h4>{t("Zobacz jak to działa!")}</h4>

          <div className="give-second-chance__video-wrapper">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={require("videos/give-second-chance.mp4").default}
                width="100%"
                height="100%"
                playing={isPlaying}
                controls={isPlaying}
                muted={false}
              />
            </div>
            {!isPlaying && (
              <button onClick={() => setIsPlaying(true)}>
                <IcoPlay />
              </button>
            )}
          </div>
          <p>
            {t(
              "Zapraszamy do stref Wear_Fair oraz do punktów zbiórki, które znajdziesz w każdym salonie 4F."
            )}
          </p>
          <Button
            text={t("Zobacz mapę")}
            as="link"
            href={`${t("join_link")}?shop=true`}
          />
        </div>

        <div className="give-second-chance__row">
          <div className="give-second-chance__col">
            <div className="give-second-chance__item">
              <div className="give-second-chance__item-logo">
                <img
                  src={
                    require("images/blog/give-second-change-logo-1.svg").default
                  }
                  alt=""
                />
              </div>
              <p>
                {t(
                  "Oddając ubrania, nie tylko pomożesz planecie, ale również wesprzesz Fundację 4F Pomaga: 1kg = 1zł na jej działania."
                )}
              </p>
              <a href="https://4fpomaga.org" target="_blank" rel="noreferrer">
                {t("more")}
              </a>
            </div>
          </div>

          <div className="give-second-chance__col">
            <div className="give-second-chance__item">
              <div className="give-second-chance__item-logo">
                <img
                  src={
                    require("images/blog/give-second-change-logo-2.svg").default
                  }
                  alt=""
                />
              </div>
              <p>
                {t(
                  "Parterem programu Wear_Fair jest lider cyrkularnego rynku odzieży - Ubrania do Oddania"
                )}
              </p>
              <a
                href="https://www.ubraniadooddania.pl"
                target="_blank"
                rel="noreferrer"
              >
                {t("more")}
              </a>
            </div>
          </div>
        </div>

        <div className="give-second-chance__meet">
          <div className="give-second-chance__meet-inner">
            <h4>{t("POZNAJMY SIĘ!")}</h4>
            <p>
              {t(
                "Jesteśmy przekonani, że zmiana zaczyna się od małych, codziennych kroków każdego z nas. My naszą zmianę zaczęliśmy od siebie i chcemy inspirować do niej innych."
              )}
            </p>
            <Button text={t("DOŁĄCZ DO NAS")} as="link" href={t("join_link")} />
          </div>
          <img
            src={
              require("images/blog/give-second-change-changemakers.jpg").default
            }
            alt=""
          />
        </div>

        <div className="give-second-chance__support">
          <div className="give-second-chance__support-inner">
            <h4>{t("Kto nas wspiera?")}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "Nasz ruch Changemakerów zatacza coraz szersze kręgi. To już nie tylko pracownicy firmy, ale również znani sportowcy, aktywiści i influencerzy.<br />Zobacz, kto wspiera nasz projekt!<br />Bądź z nami na bieżąco i zajrzyj na nasz profil na Instagramie!"
                ),
              }}
            />

            <div>
              <Button
                className="bordered-hover"
                text={t("DOŁĄCZ DO NAS")}
                as="link"
                href="https://www.instagram.com/4f_change/"
              />
            </div>
            <img
              src={require("images/blog/give-second-change-phone.jpg").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </StyledGiveSecondChance>
  )
}

export default GiveSecondChance
