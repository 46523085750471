import * as React from "react"

import Layout from "components/Layout"
import Main from "page_components/strategy/Main"
import Program from "page_components/strategy/Program"
import ChangeDirection from "page_components/strategy/ChangeDirection"

const Strategy = ({ location }) => (
  <Layout
  location={location}
    seo={{
      title: "Strategia – zmieniamy siebie i świat na lepsze – 4F Change",
      description:
        "Wdrażamy filozofię Kaizen dla wszystkich działań związanych ze zrównoważonym rozwojem: dokonujemy zmian poprzez małe ulepszenia, by z czasem przejść do coraz większych wyzwań.",
    }}
  >
    <Main location={location} />
    <Program />
    <ChangeDirection />
  </Layout>
)

export default Strategy
