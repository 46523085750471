import StyledUpcycledJacketsDogApproved from "./styles"

import React from "react"
import { navigate, Link } from "gatsby"
import { useLocale } from "context/locale"

import LinkIco from "icons/link.svg"

const UpcycledJacketsDogApproved = () => {
  const { t, lang } = useLocale()

  return (
    <StyledUpcycledJacketsDogApproved
      text={lang === "en" ? "See location" : "Zobacz lokalizację"}
    >
      <div className="upcycled-jackets-post">
        <p>
          {t(
            "Ubranka zostały uszyte w naszej krakowskiej szwalni. Wszystkie tkaniny, suwaki, metki pochodzą z kurtek, które zebraliśmy w ramach projektu 4F Change lub z ubrań z drugiego obiegu."
          )}
        </p>

        <div className="upcycled-jackets-post__row">
          <div className="upcycled-jackets-post__col">
            <img
              src={
                require("images/blog/upcycled-jacket-dog-approved-aleksandra.jpg")
                  .default
              }
              alt=""
            />
          </div>
          <div className="upcycled-jackets-post__col">
            <div className="upcycled-jackets-post__col-inner">
              <div>
                <Link to="/o-nas/">
                  <h4>
                    Aleksandra <LinkIco />
                  </h4>
                </Link>
                <span>Senior Fashion Designer</span>
                <p>
                  {t(
                    "„Pomysł na psie ubranka pojawił się w naszych głowach jakiś czas temu. Chcieliśmy dać drugie życie kurtkom, które trafiały do nas ze skazami produkcyjnymi, albo w wyniku reklamacji konsumenckich, a przy tym zachować ich funkcję. I tak postanowiliśmy uszyć kurtki dla naszych pupili! To pierwszy projekt upcyclingowy 4F. Jesteśmy z niego bardzo dumni!”"
                  )}
                </p>
              </div>
              <Link
                to={t(
                  "upcycling_clothes_what_is_it_and_why_is_it_worth_it_link"
                )}
                className="link"
              >
                {t("więcej o idei upcyclingu")}
              </Link>
            </div>
          </div>
        </div>

        <div className="upcycled-jackets-post__locations">
          <h4>{t("Kurteczki dla psów są dostępne w strefach Wear_Fair")}</h4>
          <div>
            <button
              onClick={() => navigate(`${t("join_link")}?shop=ch-arkadia`)}
            >
              <strong>{t("Warszawa")}</strong> <span>{t("CH Arkadia")}</span>
            </button>
            <button
              onClick={() => navigate(`${t("join_link")}?shop=ch-bonarka`)}
            >
              <strong>{t("Kraków")}</strong> <span>{t("CH Bonarka")}</span>
            </button>
            <button
              onClick={() =>
                navigate(`${t("join_link")}?shop=pasaz-grunwaldzki`)
              }
            >
              <strong>{t("Wrocław")}</strong>{" "}
              <span>{t("Pasaż Grunwaldzki")}</span>
            </button>
          </div>
        </div>

        <div className="upcycled-jackets-post__dogs">
          <div className="upcycled-jackets-post__dogs-col">
            <div
              className="upcycled-jackets-post__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jacket-dog-approved-dog-kielek.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <strong>Kiełek</strong>
                <p>
                  Ambasador krzywego zgryzu, model, aktywista, artysta lubujący
                  się w czerwonym, smakosz masła orzechowego i jajka na miękko
                  oraz fanatyk długich spacerków. Oto Kiełek. Po latach
                  spędzonych w schroniskach Kiełek powiedział DOŚĆ. MISJA –
                  znaleźć rodziców i zmienić Planetę. Pierwsze się udało, drugie
                  jest w trakcie. Wg niego tylko „eko” podejście może zmienić
                  ludzki świat. Więc do dzieła!
                </p>
              </div>
            </div>
          </div>

          <div className="upcycled-jackets-post__dogs-col">
            <div
              className="upcycled-jackets-post__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jacket-dog-approved-dog-irenka.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <strong>Irenka</strong>
                <p>
                  To ok. 5-letni piesek. Jest ze mną od 3 lat, znalazłam jej
                  zdjęcie na grupie fejsbukowej z ogłoszeniami zwierzaków, które
                  poszukują domu. Jak tylko ją zobaczyłam, wiedziałam, że będzie
                  moja. Jest to piesek po przejściach. Irenka bywa strachliwa,
                  ale jest też najsłodszą istotką, w której wszyscy zakochują
                  się od razu – z wzajemnością. Lubi się przytulać i pozować z
                  gracją do zdjęć.
                </p>
              </div>
            </div>
          </div>

          <div className="upcycled-jackets-post__dogs-col">
            <div
              className="upcycled-jackets-post__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jacket-dog-approved-dog-ronald.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <strong>Ronald</strong>
                <p>
                  Zodiakalna waga, jest duszą towarzystwa. Uwielbia być w
                  centrum uwagi, chociaż ceni sobie spokój i odpoczynek przez co
                  najmniej kilkanaście godzin na dobę. W czasie wolnym od spania
                  - model, filantrop, miłośnik długich spacerów, dobrego
                  jedzenia i zabawy. Zamiłowanie do mody odziedziczył po swojej
                  mamie, ponieważ już od wieku szczenięcego towarzyszył jej na
                  wielu produkcjach i to właśnie tam zrozumiał, że moda to nie
                  tylko ubrania, ale również okazja do świadomego wyboru i bycia
                  bardziej „eko”.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="upcycled-jackets-post__table">
          <h3>Tabela rozmiarów</h3>
          <img
            className="img-fluid"
            src={
              require("images/blog/upcycled-jacket-dog-approved-table.svg")
                .default
            }
            alt=""
          />
          <div className="upcycled-jackets-post__table-header">
            <p>Size</p>
            <p>Klatka</p>
            <p>Długość</p>
            <p>Szyja</p>
          </div>
          <div className="upcycled-jackets-post__table-row">
            <p>XS</p>
            <p>34 cm</p>
            <p>49 cm</p>
            <p>36 cm</p>
          </div>
          <div className="upcycled-jackets-post__table-row">
            <p>S</p>
            <p>37 cm</p>
            <p>54 cm</p>
            <p>39 cm</p>
          </div>
          <div className="upcycled-jackets-post__table-row">
            <p>M</p>
            <p>43 cm</p>
            <p>60 cm</p>
            <p>42 cm</p>
          </div>

          <small>
            Podane wymiary są wymiarami produktu, a nie wymiarami psa. Daj
            swojemu psu odrobinę luzu i dobierz odpowiedni rozmiar.
          </small>
        </div>

        <div className="upcycled-jackets-post__adopt">
          <h3>DON'T BUY – ADOPT!</h3>
          <div className="upcycled-jackets-post__adopt-row">
            <div className="upcycled-jackets-post__adopt-col">
              <img
                src={
                  require("images/blog/upcycled-jacket-dog-approved-adopt.jpg")
                    .default
                }
                alt=""
              />
            </div>
            <div className="upcycled-jackets-post__adopt-col">
              <div>
                <p>
                  <strong>Lidia, Szufcio, Szymcio i Psotnica</strong> ze
                  schroniska Psie Pole – czekają na dom! Pomóż nam go znaleźć
                  przed zimą!
                </p>
                <Link to="/aktualnosci/nie-kupuj-adoptuj/">więcej</Link>
              </div>
            </div>
          </div>
        </div>

        <p>
          Chcesz dowiedzieć się więcej?
          <br />
          Zajrzyj na profil @4f_change na Instagramie!
          <br />
          <a
            href="https://www.instagram.com/4f_change/"
            target="_blank"
            rel="noreferrer"
          >
            ZOBACZ
          </a>
        </p>
        <img
          src={
            require("images/blog/upcycled-jacket-dog-approved-qr.jpg").default
          }
          alt=""
        />
      </div>
    </StyledUpcycledJacketsDogApproved>
  )
}

export default UpcycledJacketsDogApproved
