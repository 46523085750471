import styled from "styled-components"

const StyledChangeMakers = styled.section`
  padding-bottom: 122px;
  overflow: hidden;
  .btn-next {
    display: none;
    border: none;
  }
  .changemakers-header {
    text-align: center;
    margin-bottom: 40px;
  }
  .changemakers-cta {
    text-align: center;
    padding: 0 15px;
    margin: 0 0 60px 0;
  }
  .changemakers-grid {
    display: flex;
    flex-flow: wrap;
    margin: 0 -15px;
    .changemaker {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 700px;
      margin: 0 15px 45px;
      flex-basis: calc(33.3333% - 30px);
      max-width: calc(33.3333% - 30px);
      position: relative;
      overflow: hidden;
      .arrow {
        position: absolute;
        width: 21px;
        height: 21px;
        bottom: 27px;
        right: 20px;
        svg {
          [stroke] {
            transition: 0.3s;
          }
        }
      }
      .description {
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 21px 22px;
        border: 5px solid #00fb6e;
        background-color: #fff;
        transition: 0.3s;
        transform: translateY(100%);
        .changemaker-name {
          text-transform: uppercase;
          margin-bottom: 7px;
          line-height: 31px;
        }
        .changemaker-position {
          text-transform: uppercase;
          margin-bottom: 17px;
        }
      }

      .arrow.active {
        svg {
          [stroke] {
            stroke: #00fb6e;
          }
        }
      }
      .description.active {
        transform: translateY(0);
      }
    }
  }
  @media screen and (max-width: 1800px) {
    .changemakers-grid {
      .changemaker {
        min-height: 600px;
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .changemakers-grid {
      .changemaker {
        min-height: 500px;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .changemakers-grid {
      .changemaker {
        min-height: 431px;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .changemakers-grid {
      .changemaker {
        margin: 0 15px 45px;
        flex-basis: calc(50% - 30px);
        max-width: calc(50% - 30px);
        min-height: 600px;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .changemakers-grid {
      .changemaker {
        min-height: 500px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    position: relative;
    .main-container {
      padding-right: 0;
    }
    .btn-next {
      position: absolute;
      display: block;
      padding: 10px;
      right: 5px;
      bottom: 100px;
      z-index: 2;
      svg {
        width: 21px;
        height: 21px;
        [stroke] {
          transition: 0.3s;
        }
      }
      &:hover {
        svg {
          [stroke] {
            stroke: #00fb6e;
          }
        }
      }
    }
    .changemakers-grid {
      .slick-slide {
        width: 303px;
        margin-right: 30px;
      }
      .changemaker {
        flex-basis: 100%;
        max-width: 100%;
        min-height: 431px;
        .description {
          padding: 15px;
          .changemaker-name {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 4px;
          }
          .changemaker-position {
            margin-bottom: 4px;
          }
          .changemaker-desc {
            font-size: 13px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 50px;
    .btn-next {
      bottom: 40px;
    }
    .changemakers-header {
      .title {
        font-size: 34px;
        line-height: 43px;
      }
    }
  }
`

export default StyledChangeMakers
