import * as React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import ChangeMakers from "components/ChangeMakers"
import Hero from "page_components/home/Hero"
import Motivation from "page_components/home/Motivation"
import Strategy from "page_components/home/Strategy"
import Circle from "page_components/home/Circle"
import HelpPlanet from "page_components/home/HelpPlanet"

const IndexPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "4F Change - Moving towards circularity",
        description:
          "Zostań changemakerem i daj odzieży drugie życie. Dołącz do zmiany i razem z nami zadbaj o środowisko!",
      }}
      transparent={true}
    >
      <Hero heroPosts={data.heroPosts} />
      <Motivation />
      <Strategy />
      <Circle />
      <HelpPlanet />
      <ChangeMakers />
    </Layout>
  )
}

export const query = graphql`
  query {
    heroPosts: allWpPost(
      limit: 1
      sort: { fields: date, order: DESC }
      filter: {
        acfPost: { isHomeHeroPost: { eq: true } }
        language: { code: { eq: EN } }
      }
    ) {
      nodes {
        id
        slug
        date
        title
        excerpt
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPost {
          isHomeHeroPost
          postHomeHeroExcerpt
          postHomeHeroImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default IndexPage
