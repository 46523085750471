import React from "react"
import { Link } from "gatsby"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const CookiePolicy = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Polityka cookies - 4F Change",
      description:
        "Niniejsza polityka plików cookies określa zasady plików „cookies”, używanych przez stronę internetową pod adresem https://www.4Fchange.com.",
    }}
  >
    <PageHeader title="Polityka plików cookies" />

    <StyledDescriptionPage>
      <div className="description-page">
        <p>obowiązująca od dnia 9 stycznia 2023 r.</p>
        <p>
          1. Niniejsza polityka plików cookies określa zasady używania
          niewielkich plików, zwanych „cookies”, używanych przez stronę
          internetową pod adresem https://www.4Fchange.com (dalej: „Strona
          Internetowa”).
        </p>
        <p>
          2. Właścicielem Strony Internetowej jest OTCF S.A. z siedzibą w
          Krakowie przy ulicy Saskiej 25C (30- 720 Kraków), wpisana do Krajowego
          Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Krakowa
          Śródmieścia w Krakowie Wydział XI Gospodarczy Krajowego Rejestru
          Sądowego pod numerem 0000555276, wysokość kapitału zakładowego
          7.384.500 PLN, wpłacony w całości, NIP: 9451978451, REGON: 356630870
          (dalej: „OTCF”).
        </p>
        <p>
          3. Pliki cookies to dane informatyczne, w szczególności pliki
          tekstowe, które przechowywane są w urządzeniu końcowym użytkowników
          odwiedzających stronę 4fchange.pl (np. na komputerze, telefonie lub
          innym urządzeniu użytkownika, z którego dokonano połączenia ze Sklepem
          Internetowym) i przeznaczone są do korzystania ze Sklepu
          Internetowego, jeżeli przeglądarka internetowa na to pozwala.
        </p>
        <p>
          Plik cookie zwykle zawiera nazwę domeny, z której pochodzi, czas
          przechowywania ich na urządzeniu końcowym użytkownika oraz
          indywidualną, losowo wybraną liczbę identyfikującą ten plik. Głównym
          celem plików cookies jest ułatwienie użytkownikowi korzystania ze
          Strony Internetowej i spowodowanie, by stała się bardziej przyjazna
          dla użytkownika, nie wyrządzając przy tym żadnej szkody komputerowi
          lub innemu urządzeniu końcowemu użytkownika. Informacje zbierane za
          pomocą plików tego typu przechowywane są w celach utrzymania sesji
          Użytkownika w ramach Strony Internetowej, mogą ulepszać Stronę
          Internetową poprzez dokonywanie szacunków dotyczycących statystyk
          użytkowania Strony Internetowej, pomagają dostosowywać oferowane przez
          OTCF produkty do indywidualnych preferencji i rzeczywistych potrzeb
          użytkowników, przyspieszenie wyszukiwania etc., a w przypadku
          uzyskania przez OTCF wcześniej świadomej zgody użytkowania, OTCF może
          używać plików cookies, tagów lub innych podobnych funkcjonalności, aby
          uzyskać informacje, które umożliwiają pokazywanie reklam, zarówno ze
          Strony Internetowej, jak i z witryn internetowych osób trzecich lub w
          inny sposób, na podstawie analizy nawyków przeglądania użytkownika.
        </p>
        <p>
          Przechowywana informacja lub uzyskiwanie do niej dostępu nie spowoduje
          zmian konfiguracyjnych w telekomunikacyjnym urządzeniu końcowym
          użytkownika lub w oprogramowaniu zainstalowanym w tym urządzeniu.
        </p>
        <p>
          4. OTCF wykorzystuje na tej Stronie Internetowej cztery typy plików
          cookies:
        </p>
        <ul>
          <li>
            Pliki cookies sesyjne: zbierają informacje o czynnościach
            użytkownika i istnieją tylko w czasie trwania danej sesji, która
            rozpoczyna się w momencie otworzenia Strony Internetowej i kończy
            jej zamknięciem. Po zakończeniu sesji danej przeglądarki lub
            wyłączeniu komputera zapisane informacje są usuwane z pamięci
            urządzenia końcowego użytkownika. Mechanizm cookies sesyjnych nie
            pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych
            informacji poufnych z komputera użytkownika.
          </li>
          <li>
            Pliki cookies trwałe: są przechowywane w pamięci urządzenia
            końcowego Użytkownika i pozostają tam do momentu ich skasowania
            przez użytkownika lub wygaśnięcia. Mechanizm plików cookies trwałych
            nie pozwala na pobieranie jakichkolwiek danych osobowych, ani
            żadnych informacji poufnych z komputera Użytkownika.
          </li>
          <li>Pliki cookies własne: zamieszczane przez OTCF.</li>
          <li>
            Pliki cookies zewnętrzne: umieszczane przez podmioty trzecie,
            zatwierdzone przez OTCF, w tym cookies narzędzia Google Analytics,
            używane do analizy działań użytkowników Strony Internetowej, w
            celach statystycznych.
          </li>
        </ul>
        <p>5. OTCF wykorzystuje cookies własne w celu:</p>
        <ul>
          <li>
            uwierzytelniania użytkownika na Stronie Internetowej i zapewnienia
            sesji użytkownika na tej Stronie(po zalogowaniu), dzięki której
            użytkownik nie musi na każdej podstronie ponownie wpisywać loginu i
            hasła;
          </li>
          <li>
            analiz i badań oraz audytu oglądalności, a w szczególności do
            tworzenia anonimowych statystyk, które pomagają zrozumieć, w jaki
            sposób użytkownicy korzystają ze Strony Internetowej, co umożliwia
            ulepszanie jej struktury i zawartości.
          </li>
        </ul>
        <p>6. OTCF wykorzystuje cookies zewnętrzne w celu:</p>
        <ul>
          <li>
            popularyzacji Strony Internetowej za pomocą serwisu
            społecznościowego facebook.com (administrator cookies zewnętrznego:
            Facebook Inc z siedzibą w USA lub Facebook Ireland z siedzibą w
            Irlandii);
          </li>
          <li>
            zbierania ogólnych i anonimowych danych statycznych za pośrednictwem
            narzędzi analitycznych Google Analytics (administrator cookies
            zewnętrznego: Google Inc. z siedzibą w USA).
          </li>
          <li>
            umożliwienia ocenę poprawności i skuteczności prowadzonej
            działalności reklamowej z wykorzystaniem w szczególności sieci
            AdWords i DoubleClick (administrator cookies zewnętrznego Google
            AdWords i DoubleClic: Google Inc. z siedzibą w USA), w celu usług
            takich jak:
          </li>
          <ul>
            <li>remarketing,</li>
            <li>kategorie zainteresowań,</li>
            <li>podobni odbiorcy,</li>
            <li>inne rodzaje reklam opartych na zainteresowaniach,</li>
            <li>kierowanie demograficzne i na lokalizację.</li>
          </ul>
        </ul>
        <p>
          7. Pliki cookies są bezpieczne dla komputerów użytkownika Strony
          Internetowej. W szczególności tą drogą nie jest możliwe przedostanie
          się do komputera użytkowników wirusów lub innego niechcianego
          oprogramowania lub oprogramowania złośliwego.
        </p>
        <p>
          8. Pliki cookies są wykorzystywane na Stronie Internetowej za zgodą
          użytkownika. Zgoda może zostać wyrażona przez użytkownika poprzez
          odpowiednie ustawienia oprogramowania, w szczególności przeglądarki
          internetowej, zainstalowanego w urządzeniu wykorzystywanym przez
          użytkownika do przeglądania zawartości Strony Internetowej.
        </p>
        <p>
          9. Użytkownik może w każdym czasie cofnąć lub zmienić zakres wcześniej
          wyrażonej zgody na wykorzystywanie na Stronie Internetowej plików
          cookies oraz usunąć je ze swojej przeglądarki. Chociaż ustawienia są
          różne w każdej przeglądarce, pliki cookies są zwykle konfigurowane w
          menu "Preferencje" lub "Narzędzia". Szczegółowe informacje o
          możliwości i sposobach obsługi plików cookies dostępne są w
          ustawieniach oprogramowania (przeglądarki internetowej). W przypadku
          skorzystania z tej opcji korzystanie ze Strony Internetowej będzie
          możliwe, poza funkcjami, które ze swojej natury wymagają plików
          cookies. Ograniczenie stosowania plików cookies może wpłynąć na
          niektóre funkcjonalności dostępne na Stronie Internetowej i utrudnić
          poprawne działanie Strony Internetowej.
        </p>
        <p>
          10. W plikach cookies nie są przechowywane informacje stanowiące dane
          osobowe użytkowników Strony Internetowej. Pliki cookies nie są
          wykorzystywane do określenia tożsamości Użytkownika lub do zbierania
          lub przechowywania wrażliwych danych osobowych.
        </p>
        <p>
          11. Sklep Internetowy zawiera linki i odnośniki do innych stron
          internetowych. OTCF nie ponosi odpowiedzialności za zasady ochrony
          prywatności na nich obowiązujące.
        </p>
        <p>
          12. Polityka plików coookies może ulec zmianie, o czym OTCF
          poinformuje Użytkowników z wyprzedzeniem 1 dnia.
        </p>
        <p>
          13. Pytania związane z Polityką plików cookies prosimy kierować na
          adres: info@4fchange.com
        </p>
        <p>Dodatkowe materiały:</p>
        <ul>
          <li>
            <Link to="/lista-cookies-podmiotow-trzecich/">
              Lista cookies podmiotów trzecich
            </Link>
          </li>
        </ul>
      </div>
    </StyledDescriptionPage>
  </Layout>
)

export default CookiePolicy
