import styled from "styled-components"

const StyledGrid = styled.section`
  padding: 55px 0 120px;
  @media screen and (max-width: 600px) {
    padding: 40px 0;
  }
  .empty-box {
    p {
      font-weight: 500;
    }
  }
  .grid {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }
    &__col {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding: 0 15px;

      @media (max-width: 1199px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (max-width: 600px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &__item {
      display: block;
      position: relative;
      margin: 0 0 30px 0;
      .item-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        z-index: 1;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.45));
        z-index: 2;
        transition: all 150ms linear;
      }
      .item-content {
        position: relative;
        z-index: 3;
        min-height: 340px;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        .item-date {
          position: absolute;
          top: 20px;
          left: 20px;
          display: flex;
          font-weight: 700;
          padding: 3px 5px 3px 25px;
          background-color: #fff;
          &:before {
            content: "";
            width: 14px;
            height: 13px;
            position: absolute;
            top: 6px;
            left: 5px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbl9pb25pYy1pb3MtY2FsZW5kYXIiIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtY2FsZW5kYXIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjEyLjkyMyIgdmlld0JveD0iMCAwIDE0IDEyLjkyMyI+CiAgPHBhdGggaWQ9IlBhdGhfMzkxIiBkYXRhLW5hbWU9IlBhdGggMzkxIiBkPSJNMTYuMDI5LDYuNzVIMTQuNjgzdi44MDhhLjI3LjI3LDAsMCwxLS4yNjkuMjY5aC0uNTM4YS4yNy4yNywwLDAsMS0uMjY5LS4yNjlWNi43NUg3LjE0NHYuODA4YS4yNy4yNywwLDAsMS0uMjY5LjI2OUg2LjMzN2EuMjcuMjcsMCwwLDEtLjI2OS0uMjY5VjYuNzVINC43MjFBMS4zNSwxLjM1LDAsMCwwLDMuMzc1LDguMVYxNy4yNUExLjM1LDEuMzUsMCwwLDAsNC43MjEsMTguNkgxNi4wMjlhMS4zNSwxLjM1LDAsMCwwLDEuMzQ2LTEuMzQ2VjguMUExLjM1LDEuMzUsMCwwLDAsMTYuMDI5LDYuNzVabS4yNjksMTAuMWEuNjc1LjY3NSwwLDAsMS0uNjczLjY3M0g1LjEyNWEuNjc1LjY3NSwwLDAsMS0uNjczLS42NzNWMTAuNzg4YS4yNy4yNywwLDAsMSwuMjY5LS4yNjlIMTYuMDI5YS4yNy4yNywwLDAsMSwuMjY5LjI2OVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjM3NSAtNS42NzMpIiBmaWxsPSIjZmZmIi8+CiAgPHBhdGggaWQ9IlBhdGhfMzkyIiBkYXRhLW5hbWU9IlBhdGggMzkyIiBkPSJNMTAuMDc3LDQuNzY5QS4yNy4yNywwLDAsMCw5LjgwOCw0LjVIOS4yNjlBLjI3LjI3LDAsMCwwLDksNC43Njl2LjgwOGgxLjA3N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjMwOCAtNC41KSIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGlkPSJQYXRoXzM5MyIgZGF0YS1uYW1lPSJQYXRoIDM5MyIgZD0iTTI1LjgyNyw0Ljc2OWEuMjcuMjcsMCwwLDAtLjI2OS0uMjY5aC0uNTM4YS4yNy4yNywwLDAsMC0uMjY5LjI2OXYuODA4aDEuMDc3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0LjUxOSAtNC41KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            filter: invert(100%);
          }
        }
        .item-desc {
          .item-label {
            display: inline-flex;
            padding: 3px 10px;
            color: #00fb6e;
            background-color: #000;
            margin: 0 0 5px 0;
            font-weight: 700;
          }
          .item-title {
            min-height: 62px;
            font-size: 24px;
            line-height: 31px;
            color: #fff;
            text-transform: uppercase;
          }
        }
      }
      &:hover {
        &:after {
          height: 100%;
        }
      }
      @media (max-width: 600px) {
        display: flex;
        margin: 0 0 20px 0;
        .item-bg {
          position: static;
          flex-basis: 93px;
          height: 93px;
        }
        &:after {
          display: none;
        }
        .item-content {
          min-height: initial;
          padding: 0 13px;
          display: block;
          flex: 1;
          .item-date {
            position: relative;
            top: 0;
            left: 0;
            font-size: 12px;
            line-height: 20px;
            padding: 3px 5px 3px 21px;
            &:before {
              width: 12px;
              height: 11px;
              top: 7px;
              left: 5px;
            }
          }
          .item-desc {
            .item-label {
              font-size: 12px;
              line-height: 16px;
              padding: 2px 6px;
            }
            .item-title {
              min-height: initial;
              font-size: 18px;
              line-height: 23px;
              color: #000;
              transition: 0.2s;
              text-transform: none;
            }
          }
        }
        &:hover {
          .item-content {
            .item-desc {
              .item-title {
                color: #00fb6e;
              }
            }
          }
        }
      }
    }
  }
  .posts-list {
    margin-top: 50px;
    @media screen and (max-width: 600px) {
      margin-top: 0;
    }

    &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px 0 0 0;

      button {
        color: #000;
        font-size: 24px;
        line-height: 31px;
        text-decoration: underline;
        font-weight: 700;
        border: none;
        margin: 0;
        padding: 0;
        background: transparent;
        transition: all 150ms linear;

        &:hover {
          color: #00fb6e;
        }
      }
    }

    &__item {
      display: flex;
      margin: 0 0 20px 0;
      .item-bg {
        flex-basis: 93px;
        height: 93px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .item-content {
        padding: 0 18px;
        flex: 1;
        .item-date {
          position: relative;
          font-size: 12px;
          line-height: 24px;
          font-weight: 700;
          padding: 0 0 0 20px;
          @media screen and (max-width: 600px) {
            padding: 0 0 0 16px;
          }
          &:before {
            content: "";
            width: 12px;
            height: 11px;
            position: absolute;
            top: 2px;
            left: 0px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbl9pb25pYy1pb3MtY2FsZW5kYXIiIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtY2FsZW5kYXIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjEyLjkyMyIgdmlld0JveD0iMCAwIDE0IDEyLjkyMyI+CiAgPHBhdGggaWQ9IlBhdGhfMzkxIiBkYXRhLW5hbWU9IlBhdGggMzkxIiBkPSJNMTYuMDI5LDYuNzVIMTQuNjgzdi44MDhhLjI3LjI3LDAsMCwxLS4yNjkuMjY5aC0uNTM4YS4yNy4yNywwLDAsMS0uMjY5LS4yNjlWNi43NUg3LjE0NHYuODA4YS4yNy4yNywwLDAsMS0uMjY5LjI2OUg2LjMzN2EuMjcuMjcsMCwwLDEtLjI2OS0uMjY5VjYuNzVINC43MjFBMS4zNSwxLjM1LDAsMCwwLDMuMzc1LDguMVYxNy4yNUExLjM1LDEuMzUsMCwwLDAsNC43MjEsMTguNkgxNi4wMjlhMS4zNSwxLjM1LDAsMCwwLDEuMzQ2LTEuMzQ2VjguMUExLjM1LDEuMzUsMCwwLDAsMTYuMDI5LDYuNzVabS4yNjksMTAuMWEuNjc1LjY3NSwwLDAsMS0uNjczLjY3M0g1LjEyNWEuNjc1LjY3NSwwLDAsMS0uNjczLS42NzNWMTAuNzg4YS4yNy4yNywwLDAsMSwuMjY5LS4yNjlIMTYuMDI5YS4yNy4yNywwLDAsMSwuMjY5LjI2OVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjM3NSAtNS42NzMpIiBmaWxsPSIjZmZmIi8+CiAgPHBhdGggaWQ9IlBhdGhfMzkyIiBkYXRhLW5hbWU9IlBhdGggMzkyIiBkPSJNMTAuMDc3LDQuNzY5QS4yNy4yNywwLDAsMCw5LjgwOCw0LjVIOS4yNjlBLjI3LjI3LDAsMCwwLDksNC43Njl2LjgwOGgxLjA3N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjMwOCAtNC41KSIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGlkPSJQYXRoXzM5MyIgZGF0YS1uYW1lPSJQYXRoIDM5MyIgZD0iTTI1LjgyNyw0Ljc2OWEuMjcuMjcsMCwwLDAtLjI2OS0uMjY5aC0uNTM4YS4yNy4yNywwLDAsMC0uMjY5LjI2OXYuODA4aDEuMDc3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0LjUxOSAtNC41KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            filter: invert(100%);
          }
        }
        .item-desc {
          .item-label {
            display: inline-flex;
            color: #00fb6e;
            background-color: #000;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            padding: 2px 6px;
          }
          .item-title {
            margin-top: 5px;
            font-size: 24px;
            line-height: 32px;
            color: #000000;
            transition: 0.2s;
            @media screen and (max-width: 600px) {
              font-size: 18px;
              line-height: 23px;
            }
          }
        }
      }
      &:hover {
        .item-content {
          .item-desc {
            .item-title {
              color: #00fb6e;
            }
          }
        }
      }
    }
  }
`

export default StyledGrid
