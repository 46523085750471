import React from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Button from "components/Button"
import ThanksIcon from "icons/rental_thanks.svg"

import StyledRentalThankYouPage from "styles/RentalThankYouStyles"

const RentalThankYouPage = ({ location }) => (
  <Layout
    seo={{ title: "Thank you" }}
    show_fixed_buttons={false}
    location={location}
  >
    <PageHeader title="Thank you!" />
    <StyledRentalThankYouPage>
      <div className="main-container">
        <div className="main-wrapper">
          <div className="icon-holder">
            <ThanksIcon />
          </div>
          <h4>Thank you for placing your order!</h4>
          <p>You will receive a confirmation to the email address you provided.</p>
          <div className="button-group">
            <Button theme="black" text="Home" as="link" href="/en/" />
            <Button text="Rent" as="link" href="/en/rent/" />
          </div>
        </div>
      </div>
    </StyledRentalThankYouPage>
  </Layout>
)

export default RentalThankYouPage
