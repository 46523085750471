import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/Layout"
import Hero from "page_components/rental/Hero"
import Steps from "page_components/rental/Steps"
// import Reservation from "page_components/rental/Reservation"
import IconsHeader from "page_components/rental/IconsHeader"
// import Why from "page_components/rental/Why"
// import Help from "page_components/rental/Help"
import Thanks from "page_components/rental/Thanks"

const Rental = ({ location }) => {
  // useEffect(() => {
  //   const scriptNode = document.createElement("script")
  //   scriptNode.src =
  //     "https://otcf.rentsoft.pl/rent-module/540aaa5ae8d750b48b6714240d8a8e0e/?type=script"
  //   scriptNode.type = "text/javascript"
  //   scriptNode.defer = true
  //   document.querySelector("head").appendChild(scriptNode)
  //   return () => {
  //     document.getElementById("octf-module-rentsoft--product-basket")?.remove()
  //     document.getElementById("ui-datepicker-div")?.remove()
  //   }
  // }, [])

  const data = useStaticQuery(graphql`
    query {
      acf: wpPage(databaseId: { eq: 505 }) {
        title
        acfRental {
          rentalInfoHeader
          rentalInfoIcons {
            rentalInfoIcon {
              localFile {
                publicURL
              }
            }
            rentalInfoIconHeader
            rentalInfoIconSubheader
          }
          rentalInfoSteps {
            rentalInfoStepDescription
          }
          rentalHelpHeader
          rentalHelpDescription
          rentalTermsLinkText
          rentalTermsLinkFile {
            localFile {
              publicURL
            }
          }
          rentalWhyHeader
          rentalWhyContent {
            rentalWhyContentIcon {
              localFile {
                publicURL
              }
            }
            rentalWhyContentHeader
            rentalWhyContentDescription
          }
        }
      }
    }
  `)

  return (
    <Layout
      location={location}
      seo={{
        title: `${data.acf.title} - rent instead of buy – 4F Change`,
        description:
          "By renting, you make the clothes last and you don't have to buy more and more. After the season, they will get a second life and will be put on sale through the Wear_Fair program.",
      }}
    >
      {/* <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
      </Helmet> */}

      <Hero />
      <Thanks />
      <IconsHeader data={data} />
      <Steps data={data} />

      {/* <Reservation />
      <Help data={data} />
      <Why data={data} /> */}
    </Layout>
  )
}

export default Rental
