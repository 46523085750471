import styled from "styled-components"

const StyledWoshGo = styled.div`
  .wosh-go-post {
    &__video {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      max-width: 100%;
      height: auto;
      margin: 0 0 30px 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    &__items {
      margin: 0 0 50px 0;

      @media (max-width: 480px) {
        margin: 0 0 30px 0;
      }
    }

    &__item {
      border: 3px solid #000;
      margin: 0 0 15px 0;
      transition: all 150ms linear;

      button {
        display: block;
        text-align: left;
        width: 100%;
        border: none;
        background: transparent;
        margin: 0;
        padding: 0;
        padding: 15px 25px;

        @media (max-width: 480px) {
          padding: 15px;
        }

        h5 {
          font-size: 15px;
          text-transform: uppercase;
        }
      }

      > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 25px 15px 25px;

        @media (max-width: 480px) {
          flex-direction: column;
          padding: 0 15px 15px 15px;
        }

        img {
          margin: 0 30px 0 0;

          @media (max-width: 480px) {
            margin: 0 0 15px 0;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        font-size: 12px;
        font-weight: 700;
        margin: -15px 0 0 0;

        @media (max-width: 480px) {
          margin: 0;
        }

        svg {
          margin: 0 0 0 10px;
          position: relative;
          top: -1px;
        }
      }

      &.current,
      &:hover {
        border-color: #00fb6e;
        background-color: #00fb6e;
      }
    }
  }
`

export default StyledWoshGo
