import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Form from "page_components/contact/Form"

import StyledContactPage from "styles/ContactStyles"

const ContactPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mobile_image: file(relativePath: { eq: "refresh/1920x404_KONTAKT.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      desktop_image: file(
        relativePath: { eq: "refresh/1920x404_KONTAKT.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      acf: wpPage(databaseId: { eq: 111 }) {
        title
        acfContact {
          contactDescription
          contactInfoColumns {
            contactInfoContent
          }
        }
      }
    }
  `)

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "4F CHANGE",
    email: "info@4fchange.co",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Saska 25C",
      addressLocality: "Kraków",
      postalCode: "30-720",
      addressCountry: "PL",
    },
  }

  const sources = [
    data.mobile_image.childImageSharp.fluid,
    {
      ...data.desktop_image.childImageSharp.fluid,
      media: `(min-width: 600px)`,
    },
  ]

  return (
    <Layout
      location={location}
      seo={{
        title: `${data?.acf?.title} – 4F Change`,
        description:
          "Porozmawiajmy o tym jak wspólnie możemy zadbać o naszą planetę.",
        schemaMarkup: schema,
      }}
    >
      <PageHeader title={data?.acf?.title} />
      <div className="main-container">
        <StyledContactPage>
          <section className="contact__image">
            <Img fluid={sources} alt="" />
          </section>
          <div className="contact__form">
            <div className="box-text">
              <p>{data?.acf?.acfContact?.contactDescription}</p>
            </div>
            <Form />
          </div>
          <div className="contact__details">
            {data?.acf?.acfContact?.contactInfoColumns.map((item, index) => (
              <div
                className="contact-box"
                dangerouslySetInnerHTML={{ __html: item?.contactInfoContent }}
                key={index}
              />
            ))}
          </div>
        </StyledContactPage>
      </div>
    </Layout>
  )
}

export default ContactPage
