import React from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const CookiePolicy = ({ location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Cookie policy - 4F Change",
        description:
          'This cookie policy defines the rules of "cookies" used by the website at https://www.4Fchange.com.',
      }}
    >
      <PageHeader title="COOKIE POLICY" />

      <StyledDescriptionPage>
        <div className="description-page">
          <p>in force from 16 January 2023</p>
          <p>
            1. This cookie policy sets out the rules for the use of small files
            known as cookies, used by the website at https://www.4Fchange.com
            (hereinafter referred to as the “Website”).
          </p>
          <p>
            2. The owner of the Website is OTCF S.A. with its registered office
            in Kraków, ul. Saska 25C (30-720 Kraków), entered in the National
            Court Register kept by the District Court for Kraków Śródmieście in
            Kraków, 12th Business Division of the National Court Register under
            No. 0000555276, share capital of PLN 7,384,500, paid in full, NIP
            [VAT No.]: 9451978451, REGON [National Official Business Register
            No.]: 356630870 (hereinafter referred to as “OTCF”).
          </p>
          <p>
            3. Cookies are IT data, in particular text files stored in the
            device of the users visiting 4fchange.pl (e.g. on a computer, phone,
            or another user’s device used to connect to the Online Shop),
            intended for the use of the Online Shop if the Internet browser
            allows it.
          </p>
          <p>
            A cookie usually contains the name of the domain it comes from, the
            storage time on the user’s device, and an individual random number
            identifying the file. The main purpose of cookies is to make it
            easier for the user to use Website and to make it more
            user-friendly, without causing any damage to the user’s computer or
            other user’s devices. The information collected using cookies is
            stored to maintain the user’s session on the website. It can improve
            the Website by means of estimates regarding website usage
            statistics, help to adjust the services offered by OTCF S.A.
            products to the individual preferences and actual needs of the
            users, speed up the search process, etc. If OTCF obtains the user’s
            prior informed consent, OTCF may use cookies, tags, or other similar
            functionality to obtain information that enables it to display
            advertisements, whether from the Website, from third party websites,
            or otherwise, based on the analysis of the user’s browsing habits.
          </p>
          <p>
            The information stored or accessed will not cause any configuration
            changes to the user’s telecommunications device or the software
            installed on the device.
          </p>
          <p>4. OTCF uses four types of cookies on the Website:</p>
          <ul>
            <li>
              Session cookies collect information about the user’s activities
              and exist only for the duration of the session, which begins when
              the Website is opened and expires when it is closed. When the
              browser session expires or the computer is shut down, the
              information is deleted from the memory of the user’s device. The
              mechanism of session cookies does not allow collecting any
              personal data or any confidential information from the user’s
              computer.
            </li>
            <li>
              Persistent cookies are stored in the memory of the user’s device
              until deleted by the user or until they expire. The mechanism of
              persistent cookies does not allow collecting any personal data or
              any confidential information from the user’s computer.
            </li>
            <li>First-party cookies are files placed by OTCF.</li>
            <li>
              Third-party cookies are placed by third parties and approved by
              OTCF. They include Google Analytics cookies. The are used to
              analyse the activities of Website users for statistical purposes.
            </li>
          </ul>
          <p>5. OTCF uses first-party cookies for the following purposes:</p>
          <ul>
            <li>
              to authenticate the user on the Website and ensure the user’s
              session on the Website (after logging in) to make it possible for
              the user to use the Website without the need to re-enter its login
              and password on each page;
            </li>
            <li>
              to conduct analyses, studies, and viewing activity audit, and in
              particular to compile anonymous statistics that help to understand
              how users use the Website so that its structure and content can be
              improved.
            </li>
          </ul>
          <p>6. OTCF uses third-party cookies for the following purposes:</p>
          <ul>
            <li>
              to popularise the Website by means of facebook.com (external
              cookies administered by Facebook Inc based in the USA or Facebook
              Ireland based in Ireland);
            </li>
            <li>
              to collect general and anonymous statistical data via Google
              Analytics Tools (third-party cookies administered by Google Inc.
              based in the USA).
            </li>
            <li>
              to make it possible to assess whether the advertising activities
              are correct and effective, using in particular the AdWords and
              DoubleClick networks (third-party cookies administered by Google
              AdWords and DoubleClic: Google Inc. based in the USA), to provide
              services such as:
            </li>
            <ul>
              <li>remarketing,</li>
              <li>categories of interest,</li>
              <li>similar recipients,</li>
              <li>other types of interest-based advertising,</li>
              <li>demographic and location targeting.</li>
            </ul>
          </ul>
          <p>
            7. Cookies are safe for the Website user’s computers. In particular,
            they do not allow penetrating the Users' computers with viruses or
            other unwanted software or malware.
          </p>
          <p>
            8. Cookies are used on the Website with the user’s consent. The user
            can give consent by using appropriate settings of the software, in
            particular the Internet browser, installed on the device used by the
            user to view the content of the Website.
          </p>
          <p>
            9. The user may, at any time, withdraw the previously given consent
            to the use of cookies on the Website, or change its scope, and
            delete cookies from the browser. While the settings are different in
            each browser, cookies are normally configured in the “Preferences”
            or “Tools” menu. Detailed information on the possibilities and ways
            of handling cookies is available in the software settings (web
            browser). When using this option, the user will be able to use the
            Website, except for functions that require cookies by their nature.
            Restricting the use of cookies may affect some of the
            functionalities available on the Website and prevent the correct
            operation of the Website.
          </p>
          <p>
            10. Cookies do not store information that constitutes personal data
            of the Website users. Cookies are not used to determine the identity
            of the user or to collect or store sensitive personal data.
          </p>
          <p>
            11. The Online Shop contains links and references to other websites.
            OTCF is not responsible for the privacy protection rules applicable
            at these websites.
          </p>
          <p>
            12. The cookie policy is subject to change. OTCF will inform the
            users of any changes in the cookie policy one day in advance.
          </p>
          <p>
            13. If you have any questions about the cookie policy, please
            contact us at info@4fchange.com
          </p>
        </div>
      </StyledDescriptionPage>
    </Layout>
  )
}

export default CookiePolicy
