/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from "react"

import ArrowUpIcon from "icons/arrow_up.svg"
import classNames from "classnames"

const ChangeMaker = ({ item }) => {
  const [active, setActive] = useState(false)

  return (
    <div
      className="changemaker"
      style={{
        backgroundImage: `url('${item?.aboutChangemakerImage?.localFile?.publicURL}')`,
      }}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onTouchEnd={() => setActive(!active)}
    >
      <div className={classNames("arrow", { active })}>
        <ArrowUpIcon />
      </div>
      <div className={classNames("description", { active })}>
        <h4
          className="changemaker-name"
          dangerouslySetInnerHTML={{ __html: item.aboutChangemakerName }}
        />
        {item.aboutChangemakerPosition && (
          <p className="changemaker-position">
            {item.aboutChangemakerPosition}
          </p>
        )}
        <div
          className="changemaker-desc"
          dangerouslySetInnerHTML={{ __html: item.aboutChangemakerDescription }}
        />
      </div>
    </div>
  )
}

export default ChangeMaker
