import StyledContent from "./styles"

import React from "react"

import Button from "components/Button"
import Label from "components/Label"

const Content = () => {
  return (
    <StyledContent>
      <div className="main-container">
        <div className="rental-content__hero">
          <h3>
            Pilotażowy projekt wypożyczalni ubrań narciarskich został
            zakończony.
          </h3>
          <p>
            Dziękujemy wszystkim, którzy skorzystali z usługi i dzięki temu
            przyczynili się do realizacji strategii 4F Change. Pracujemy nad
            nowymi projektami, dlatego też bądźcie na bieżąco z naszymi
            aktualnościami.
          </p>
          <Button
            text="dowiedz się wiecej"
            as="link"
            href="/aktualnosci/rent-enjoy-return-podsumowanie-pilotazowego-projektu-wypozyczalni-ubran-narciarskich/"
          />
        </div>

        <div className="rental-content__join">
          <Label text="dołącz" />
          <h3>
            Chcesz być CHANGEMAKEREM
            <br /> i ubrać się na stok w duchu strategii 4F Change?
          </h3>
          <p>
            Stroje narciarskie mogą znajdować się wśród ubrań z drugiego obiegu.
            Sprawdź również naszą ofertę ubrań oznaczonych metką „wear_fair”.
          </p>
        </div>

        <div className="rental-content__row">
          <div className="rental-content__col">
            <div className="rental-content__item">
              <div
                className="rental-content__item-thumb"
                style={{
                  backgroundImage: `url('${
                    require("images/rental/rental-content-image-1.jpg").default
                  }')`,
                }}
              >
                <img
                  src={
                    require("images/rental/rental-content-icon-1.svg").default
                  }
                  alt=""
                />
              </div>
              <h4>Tu kupisz ubrania oznaczone metką 4F Change</h4>
              <Button
                text="KUP ONLINE"
                as="link"
                href="https://4f.com.pl/dyscypliny/cechy_specjalne-wear_fair.html"
              />
            </div>
          </div>

          <div className="rental-content__col">
            <div className="rental-content__item">
              <div
                className="rental-content__item-thumb"
                style={{
                  backgroundImage: `url('${
                    require("images/rental/rental-content-image-2.jpg").default
                  }')`,
                }}
              >
                <img
                  src={
                    require("images/rental/rental-content-icon-2.svg").default
                  }
                  alt=""
                />
              </div>
              <h4>Tu kupisz ubrania z drugiego obiegu</h4>
              <Button text="ZNAJDŹ SKLEP" as="link" href="/dolacz?shop=true" />
            </div>
          </div>
        </div>
      </div>
    </StyledContent>
  )
}

export default Content
