import styled from "styled-components"

const StyledPageHeader = styled.header`
  text-align: center;
  padding: 50px 0 100px;
  .title {
    text-transform: uppercase;
  }
  @media screen and (max-width: 1100px) {
    padding: 30px 0 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 12px 0 60px;
    .title {
      font-size: 34px;
      line-height: 43px;
    }
  }
`

export default StyledPageHeader
