import React from "react"

import StyledSteps from "./styles"

const Steps = ({ data }) => {
  return (
    <StyledSteps>
      <div className="main-container">
        <div className="steps__wrapper">
          <div className="steps__row">
            {data?.acf?.acfRental?.rentalInfoSteps.map((item, index) => (
              <div className="steps__col" key={index}>
                <div className="steps__item">
                  <h3>{index + 1}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.rentalInfoStepDescription,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyledSteps>
  )
}

export default Steps
