import React from "react"

import Layout from "components/Layout"

import Hero from "page_components/rental/Hero"
import Content from "page_components/rental/Content"

const Rental = ({ location }) => {
  // useEffect(() => {
  //   const scriptNode = document.createElement("script")
  //   scriptNode.src =
  //     "https://otcf.rentsoft.pl/rent-module/540aaa5ae8d750b48b6714240d8a8e0e/?type=script"
  //   scriptNode.type = "text/javascript"
  //   scriptNode.defer = true
  //   document.querySelector("head").appendChild(scriptNode)
  //   return () => {
  //     document.getElementById("octf-module-rentsoft--product-basket")?.remove()
  //     document.getElementById("ui-datepicker-div")?.remove()
  //   }
  // }, [])

  return (
    <Layout
      location={location}
      seo={{
        title: "Wypożyczalnia - wypożycz zamiast kupować – 4F Change",
        description:
          "Wypożyczając, sprawiasz, że ubrania są cały czas w użyciu, a ty nie musisz kupować kolejnych i kolejnych. Po sezonie zyskają drugie życie i zostaną wprowadzone do sprzedaży w ramach programu Wear_Fair.",
      }}
    >
      {/* <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
      </Helmet> */}
      <Hero />
      <Content />
    </Layout>
  )
}

export default Rental
