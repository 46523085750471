import React from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Button from "components/Button"
import ThanksIcon from "icons/rental_thanks.svg"

import StyledRentalThankYouPage from "styles/RentalThankYouStyles"

const RentalThankYouPage = ({ location }) => (
  <Layout
    seo={{ title: "Dziekujemy" }}
    show_fixed_buttons={false}
    location={location}
  >
    <PageHeader title="Dziękujemy!" />
    <StyledRentalThankYouPage>
      <div className="main-container">
        <div className="main-wrapper">
          <div className="icon-holder">
            <ThanksIcon />
          </div>
          <h4>Dziękujemy za złożenie zamówienia!</h4>
          <p>Potwierdzenie otrzymasz na podany adres email.</p>
          <div className="button-group">
            <Button theme="black" text="Strona główna" as="link" href="/" />
            <Button text="Wypożyczalnia" as="link" href="/wypozyczalnia/" />
          </div>
        </div>
      </div>
    </StyledRentalThankYouPage>
  </Layout>
)

export default RentalThankYouPage
