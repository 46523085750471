import React from "react"
import {useLocale} from 'context/locale'
import hands_image from "images/hands.png"

import StyledThanks from "./styles"
const Thanks = () => {
  const {t} = useLocale()
  return (
    <StyledThanks>
      <div className="main-container">
        <h2>{t("Thank you for using our rental service!")}</h2>
        <p>{t("Please join us again in November.")}</p>
        <div className="image-holder">
          <img src={hands_image} alt="Second life" />
        </div>
      </div>
    </StyledThanks>
  )
}

export default Thanks
