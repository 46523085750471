import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Hero from "page_components/blog/Hero"
import Filters from "page_components/blog/Filters"
import Grid from "page_components/blog/Grid"

function capitalizeFirstLetter(string) {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const Category = ({ pageContext, data, location }) => {
  const pageData = pageContext.data
  const slug = pageData.slug
  const [query, setQuery] = useState("")
  const blogData = {
    query,
    setQuery,
    slug,
  }
  return (
    <Layout
      location={location}
      translations={pageContext.translations}
      seo={{
        title: `${capitalizeFirstLetter(pageData.name)} - ${
          pageContext.code === "EN" ? "News" : "Aktualności"
        } - 4F Change`,
      }}
    >
      <Hero post={data?.allPost?.nodes[0]} />
      <Filters categories={data?.allCategory} {...blogData} type="category" />
      <Grid posts={data?.allPost?.nodes} {...blogData} type="category" />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $code: WpLanguageCodeEnum) {
    allPost: allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        language: { code: { eq: $code } }
      }
    ) {
      nodes {
        id
        slug
        date
        title
        excerpt
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allCategory: allWpCategory(
      filter: {
        name: { ne: "Uncategorized" }
        language: { code: { eq: $code } }
      }
    ) {
      nodes {
        slug
        name
        posts {
          nodes {
            id
          }
        }
      }
    }
  }
`

export default Category
