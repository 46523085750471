import styled from "styled-components"

const StyledNotFound = styled.div`
  text-align: center;
  padding: 170px 0 270px;
  min-height: 100vh;
  .title {
    margin-bottom: 30px;
  }
  .subtitle {
    font-size: 34px;
    line-height: 43px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
`

export default StyledNotFound
