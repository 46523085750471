import StyledUpcycledJacketsDogSecondEdition from "./styles"

import React from "react"
import { navigate, Link } from "gatsby"
import { useLocale } from "context/locale"

import LinkIco from "icons/link.svg"

const UpcycledJacketsDogSecondEdition = () => {
  const { t, lang } = useLocale()

  return (
    <StyledUpcycledJacketsDogSecondEdition
      text={lang === "en" ? "See location" : "Zobacz lokalizację"}
    >
      <div className="upcycled-jackets-post-second-edition">
        <p>
          {t(
            "Ubranka, które stworzyliśmy w trosce o ciepło i komfort Waszych pupili, zostały uszyte w pełni z recyklingu. Powstały w naszej krakowskiej szwalni, a do ich produkcji wykorzystaliśmy tylko tkaniny, suwaki i metki, które pochodzą z ubrań z drugiego obiegu zebranych w ramach projektu 4F Change."
          )}
        </p>

        <div className="upcycled-jackets-post-second-edition__row">
          <div className="upcycled-jackets-post-second-edition__col">
            <img
              src={
                require("images/blog/upcycled-jacket-dog-approved-aleksandra.jpg")
                  .default
              }
              alt=""
            />
          </div>
          <div className="upcycled-jackets-post-second-edition__col">
            <div className="upcycled-jackets-post-second-edition__col-inner">
              <div>
                <Link to="/o-nas/">
                  <h4>
                    Aleksandra <LinkIco />
                  </h4>
                </Link>
                <span>Senior Fashion Designer</span>
                <p>
                  {t(
                    "„Pomysł na psie ubranka pojawił się w naszych głowach jakiś czas temu. Chcieliśmy dać drugie życie kurtkom, które trafiały do nas ze skazami produkcyjnymi, albo w wyniku reklamacji konsumenckich, a przy tym zachować ich funkcję. I tak postanowiliśmy uszyć kurtki dla naszych pupili! To pierwszy projekt upcyclingowy 4F. Jesteśmy z niego bardzo dumni!”"
                  )}
                </p>
              </div>
              <Link
                to={t(
                  "upcycling_clothes_what_is_it_and_why_is_it_worth_it_link"
                )}
                className="link"
              >
                {t("więcej o idei upcyclingu")}
              </Link>
            </div>
          </div>
        </div>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Co zmieniło się w tym roku? Kolekcja powiększyła się o dodatkowe rozmiary <strong>XXS</strong>, <strong>L</strong> i <strong>XL</strong>, a kurteczki zyskały specjalny otwór na smycz."
            ),
          }}
        ></p>

        <div className="upcycled-jackets-post-second-edition__table">
          <img
            src={
              require("images/blog/upcycled-jackets-dogs-second-edition-table.svg")
                .default
            }
            alt=""
          />
        </div>

        <div className="upcycled-jackets-post-second-edition__dogs">
          <div className="upcycled-jackets-post-second-edition__dogs-col">
            <div
              className="upcycled-jackets-post-second-edition__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jackets-dogs-second-edition-dogs-image-1.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <p>
                  {t(
                    "Upcyclingowe kurteczki stworzyliśmy w trosce o ciepło i komfort Twojego pupila, tak by zabawy na świeżym powietrzu mogły trwać długo, niezależnie od pogody."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="upcycled-jackets-post-second-edition__dogs-col">
            <div
              className="upcycled-jackets-post-second-edition__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jackets-dogs-second-edition-dogs-image-2.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <p>
                  {t(
                    "W wybranych kurteczkach i bluzach wycięliśmy otwór na smycz, by zapewnić komfort użytkowania zarówno właścicielom czworonogów, jak i samym pieskom."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="upcycled-jackets-post-second-edition__dogs-col">
            <div
              className="upcycled-jackets-post-second-edition__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jackets-dogs-second-edition-dogs-image-3.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <p>
                  {t(
                    "Ubranka dla psów uszyliśmy z materiałów z drugiego obiegu, zebranych w ramach projektu Wear_Fair. Wybierając produkty upcyclingowe, Ty i Twój pupil dołączacie do grona Changemakerów!"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="upcycled-jackets-post-second-edition__locations">
          <h4>{t("Ubranka dla psów są dostępne w strefach Wear_Fair")}</h4>
          <div>
            <button
              onClick={() => navigate(`${t("join_link")}?shop=ch-arkadia`)}
            >
              <strong>{t("Warszawa")}</strong> <span>{t("CH Arkadia")}</span>
            </button>
            <button
              onClick={() => navigate(`${t("join_link")}?shop=ch-bonarka`)}
            >
              <strong>{t("Kraków")}</strong> <span>{t("CH Bonarka")}</span>
            </button>
            <button
              onClick={() =>
                navigate(`${t("join_link")}?shop=pasaz-grunwaldzki`)
              }
            >
              <strong>{t("Wrocław")}</strong>{" "}
              <span>{t("Pasaż Grunwaldzki")}</span>
            </button>
          </div>
        </div>

        <div className="upcycled-jackets-post-second-edition__adopt">
          <h3>{t("NIE KUPUJ, ADOPTUJ!")}</h3>
          <div className="upcycled-jackets-post-second-edition__adopt-row">
            <div className="upcycled-jackets-post-second-edition__adopt-col">
              <img
                src={
                  require("images/blog/upcycled-jackets-dogs-second-edition-adopt.jpg")
                    .default
                }
                alt=""
              />
            </div>
            <div className="upcycled-jackets-post-second-edition__adopt-col">
              <div>
                <p>
                  {t(
                    "Czworonogi ze schroniska Psie Pole czekają na dom! Pomóż nam go znaleźć przed zimą!"
                  )}
                </p>
                <Link to="/aktualnosci/nie-kupuj-adoptuj/">{t("więcej")}</Link>
              </div>
            </div>
          </div>
        </div>

        <p>
          {t("Chcesz dowiedzieć się więcej?")}
          <br />
          {t("Zajrzyj na profil @4f_change na Instagramie!")}
          <br />
          <a
            href="https://www.instagram.com/4f_change/"
            target="_blank"
            rel="noreferrer"
          >
            {t("ZOBACZ")}
          </a>
        </p>
        <img
          src={
            require("images/blog/upcycled-jacket-dog-approved-qr.jpg").default
          }
          alt=""
        />
      </div>
    </StyledUpcycledJacketsDogSecondEdition>
  )
}

export default UpcycledJacketsDogSecondEdition
