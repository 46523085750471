import React from "react"
import StyledLabel from "./styles"

const Label = ({ text,lg }) => (
  <StyledLabel lg={lg} className="text-label">
    <span className="bolder">{text}</span>
  </StyledLabel>
)

export default Label
