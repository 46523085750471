import React from "react"

import StyledIconsHeader from "./styles"

const IconsHeader = ({ data }) => {
  return (
    <StyledIconsHeader>
      <div className="main-container">
        <div className="icons-header__title">
          <h2>{data?.acf?.acfRental?.rentalInfoHeader}</h2>
        </div>

        <div className="icons-header__grid">
          {data?.acf?.acfRental?.rentalInfoIcons.map((item, index) => (
            <div className="icon-box" key={index}>
              <div className="icon-holder">
                <img
                  src={item.rentalInfoIcon.localFile.publicURL}
                  alt={`4F Change - ${item?.rentalInfoIconHeader} ${item?.rentalInfoIconSubheader}`}
                />
              </div>
              <div className="text-holder">
                <p className="title bolder">{item?.rentalInfoIconHeader}</p>
                <p className="desc bolder">{item?.rentalInfoIconSubheader}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledIconsHeader>
  )
}

export default IconsHeader
