import styled from "styled-components"

const StyledGiveSecondChance = styled.div`
  .give-second-chance {
    &__video {
      margin: 130px 0 0 0;
      width: calc(200% + 30px);
      left: calc(-50% - 15px);
      text-align: center;
      position: relative;

      @media (max-width: 1199px) {
        margin: 30px 0 0 0;
        width: 100%;
        left: 0;
      }

      &-wrapper {
        position: relative;
        margin: 0 0 30px 0;
      }

      &-poster {
        min-height: 354px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      button {
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 150ms linear;

        &:hover {
          transform: translate(-50%, -50%) scale(1.05);
        }

        svg {
          @media (max-width: 768px) {
            max-height: 45px;
          }
        }
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 75px -15px 0;

      @media (max-width: 768px) {
        margin: 35px -15px 0;
      }
    }

    &__col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;
    }

    &__item {
      text-align: center;

      &-logo {
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        margin: 15px 0 !important;
      }

      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &__meet {
      background-color: #f3f3f3;
      margin: 75px 0 0 0;

      @media (max-width: 768px) {
        margin: 35px 0 0 0;
      }

      &-inner {
        padding: 50px 15px 0 15px;
        text-align: center;

        @media (max-width: 768px) {
          padding: 30px 15px 0 15px;
        }
      }

      p {
        max-width: 474px;
        margin: 0 auto 30px !important;
      }

      img {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }

    &__support {
      background-color: #00fb6e;
      margin: 75px 0 0 0;
      overflow: hidden;

      @media (max-width: 768px) {
        margin: 35px 0 0 0;
      }

      &-inner {
        padding: 75px 15px 0 15px;
        text-align: center;

        @media (max-width: 768px) {
          padding: 30px 15px 0 15px;
        }
      }

      p {
        max-width: 474px;
        margin: 0 auto 60px !important;

        @media (max-width: 768px) {
          margin: 0 auto 30px !important;
        }
      }

      img {
        height: auto;
        max-width: 100%;
        margin: 75px 0 -7px 0;

        @media (max-width: 768px) {
          margin: 30px 0 -7px 0;
        }
      }
    }
  }
`

export default StyledGiveSecondChance
