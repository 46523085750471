import styled from "styled-components"

const StyledFixWinter = styled.div`
  .fix-winter-post {
    &__dates {
      background-color: #00fb6e;
      padding: 50px 15px;
      text-align: center;
      margin: 0 0 25px 0;

      @media (max-width: 575px) {
        padding: 30px 15px;
      }

      h4 {
        margin: 0;
      }

      div {
        margin: 30px 0;

        @media (max-width: 768px) {
          margin: 30px 0 15px;
        }

        * {
          &:not(:last-child) {
            margin: 0 30px 0 0;

            @media (max-width: 768px) {
              margin: 0 15px 15px 15px;
            }
          }

          @media (max-width: 768px) {
            @media (max-width: 768px) {
              margin: 0 15px 15px 15px;
            }
          }
        }
      }
    }

    &__locations {
      background-color: #e9e9e9;
      padding: 50px 15px;

      @media (max-width: 575px) {
        padding: 30px 15px;
      }

      &-wrapper {
        max-width: 474px;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 575px) {
          flex-direction: column;
        }

        img {
          margin: 0 30px 0 0;

          @media (max-width: 575px) {
            margin: 0 auto 30px;
          }
        }

        div {
          width: 100%;
          max-width: 333px;
        }
      }

      button,
      a {
        font-size: 16px;
        border: 1px solid #000;
        color: #000;
        min-height: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 15px 20px;
        width: 100%;
        max-width: 333px;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        transition: all 150ms linear;

        &:after {
          content: ${props => props.text};
          font-weight: 700;
          background-color: #00fb6e;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 150ms linear;
          transform: translate(0%, 100%);
        }

        &:not(:last-child) {
          margin: 0 0 15px 0;
        }

        strong {
          font-size: 21px;
          display: inline-flex;
          margin: 0 5px 0 0;
        }

        span {
          position: relative;
          top: -1px;
        }

        &:hover {
          border-color: #00fb6e;
          &:after {
            transform: translate(0%, 0%);
          }
        }
      }
    }

    &__pricing {
      background-color: #00fb6e;
      padding: 30px 25px;
      margin: 0 0 25px 0;

      @media (max-width: 575px) {
        padding: 30px 15px;
      }

      h3 {
        text-align: center;
      }

      &-header {
        display: flex;
        background-color: #000;
        margin: 0 0 30px 0;
        padding: 3px 15px;

        @media (max-width: 575px) {
          padding: 3px 10px;
        }

        p {
          margin: 0 !important;
          color: #fff;
          font-size: 12px;
          font-weight: 700;

          @media (max-width: 575px) {
            line-height: 18px;
          }

          &:nth-child(1) {
            flex: 0 0 auto;
            width: 50%;
          }

          &:nth-child(2) {
            flex: 0 0 auto;
            width: 20%;
            text-align: center;
          }

          &:nth-child(3) {
            flex: 0 0 auto;
            width: 30%;
            text-align: center;
          }
        }
      }

      &-row {
        display: flex;
        padding: 0 15px;

        @media (max-width: 575px) {
          padding: 0 10px;
        }

        &:not(:last-child) {
          margin: 0 0 5px 0;
        }

        p {
          margin: 0 !important;
          font-size: 12px;

          @media (max-width: 575px) {
            line-height: 21px;
          }

          &:nth-child(1) {
            flex: 0 0 auto;
            width: 50%;
            font-weight: 700;
          }

          &:nth-child(2) {
            flex: 0 0 auto;
            width: 20%;
            text-align: center;
            font-weight: 700;
          }

          &:nth-child(3) {
            flex: 0 0 auto;
            width: 30%;
            text-align: center;
          }
        }
      }
    }
  }
`

export default StyledFixWinter
