import styled from "styled-components"

const StyledWoshGoSub = styled.div`
  .wosh-go-sub-post {
    &__video {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;
      max-width: 100%;
      height: auto;
      margin: 0 0 30px 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    p {
      br {
        @media (max-width: 480px) {
          display: none;
        }
      }
    }

    &__header {
      margin: 0 0 50px 0;

      @media (max-width: 480px) {
        margin: 0 0 30px 0;
      }
    }

    &__items {
      margin: 0 0 90px 0;

      @media (max-width: 480px) {
        margin: 0 0 30px 0;
      }
    }

    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0 0 30px 0;

      img {
        margin: 0 30px 0 0;

        @media (max-width: 480px) {
          max-width: 40px;
          max-height: 40px;
          margin: 0 15px 0 0;
        }
      }
    }
  }
`

export default StyledWoshGoSub
