import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Hero from "page_components/blog/Hero"
import Filters from "page_components/blog/Filters"
import Grid from "page_components/blog/Grid"

const Blog = ({ data, location }) => {
  const [query, setQuery] = useState("")

  const blogData = {
    query,
    setQuery,
  }

  const featuredPost = data?.allPost?.nodes?.filter(
    item => item.acfPost.isFeatured
  )

  const gridPosts = data?.allPost?.nodes.filter(
    item => item.id !== featuredPost[0]?.id && item.id !== featuredPost[1]?.id
  )

  return (
    <Layout
      location={location}
      seo={{
        title: "Aktualności – 4F Change",
        description:
          "Bądź na bieżąco. Śledź nasze działania w zakresie wdrażania strategii 4F Change i razem z nami zadbaj o środowisko.",
      }}
    >
      <Hero
        post={featuredPost[0] ? featuredPost[0] : data?.allPost?.nodes[0]}
      />
      <Filters categories={data?.allCategory} {...blogData} />
      <Grid posts={[...featuredPost, ...gridPosts]} {...blogData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allPost: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: PL } } }
    ) {
      nodes {
        id
        slug
        date
        title
        excerpt
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPost {
          isFeatured
        }
      }
    }
    allCategory: allWpCategory(
      filter: { name: { ne: "Uncategorized" }, language: { code: { eq: PL } } }
    ) {
      nodes {
        slug
        name
        posts {
          nodes {
            id
          }
        }
      }
    }
  }
`

export default Blog
