import StyledWoshGoSub from "../WoshGoNubuk/styles"

import React from "react"

import Button from "components/Button"

const WoshGoEveryday = () => {
  const data = [
    {
      icon: require("images/blog/wosh-go-everyday-ico-1.svg").default,
      description:
        "Buty ze skóry licowej przecieramy suchą, miękką, najlepiej białą szmatką lub, jeśli są bardzo brudne, wilgotną. Po wyczyszczeniu obuwia nakładamy odrobinę środka pielęgnacyjnego i polerujemy. Chroni to buty przed wilgocią i sprawia, że skóra jest bardziej miękka.",
    },
    {
      icon: require("images/blog/wosh-go-everyday-ico-2.svg").default,
      description:
        "Obuwie zamszowe należy oczyścić z kurzu, brudu i śladów wilgoci za pomocą specjalnych szczotek, a następnie użyć specjalnych produktów do pielęgnacji.",
    },
    {
      icon: require("images/blog/wosh-go-everyday-ico-3.svg").default,
      description:
        "Czyszczenie i impregnowanie butów najlepiej przeprowadzić z wyprzedzeniem, a nie tuż przed wyjściem. Kremy i spraye do pielęgnacji potrzebują czasu, by prawidłowo wchłonąć się w skórę i dzięki temu być maksymalnie skuteczne.",
    },
    {
      icon: require("images/blog/wosh-go-everyday-ico-4.svg").default,
      description:
        "A jak przechowywać buty, kiedy z nich nie korzystamy? Każdy ma swój sposób na przechowywanie butów: ktoś lubi trzymać wszystko w pudełkach, ktoś w plastikowych pojemnikach, a jeszcze ktoś trzyma je na otwartych półkach. Nie robi to zasadniczej różnicy, warto jednak pamiętać o kilku uniwersalnych zasadach. Buty, które nosisz rzadko, powinny być przechowywane szczelnie wypchane cienkim papierem – ochroni je to przed wilgocią i deformacją. Można też zaopatrzyć się w drewniane prawidła, które pozwolą uniknąć nieładnych zgięć. Ważne! Nigdy nie chowaj mokrych butów do szafy – wysusz je najpierw w wentylowanym pomieszczeniu.",
    },
  ]

  return (
    <StyledWoshGoSub>
      <div className="wosh-go-sub-post">
        <div className="wosh-go-sub-post__video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/E9uTMTeM698"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div className="wosh-go-sub-post__header">
          <img src={require("images/blog/wosh-go-header.svg").default} alt="" />
        </div>

        <div className="wosh-go-sub-post__items">
          {data.map((item, index) => (
            <div className="wosh-go-sub-post__item" key={index}>
              <img src={item.icon} alt="" />
              <p>{item.description}</p>
            </div>
          ))}
        </div>

        <h4>Potrzebujesz nowej pary butów?</h4>
        <p>
          Dowiedz się, jak kupując obuwie 4F uzyskać rabat <br />
          na usługę czyszczenia butów w woshwosh.
        </p>
        <Button
          text="zyskaj rabat"
          theme="current bordered-hover"
          as="link-out"
          href="https://4f.com.pl/landing/4f-change-woshgo"
        />
      </div>
    </StyledWoshGoSub>
  )
}

export default WoshGoEveryday
