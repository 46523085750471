import styled from "styled-components"

const StyledIconsHeader = styled.section`
  padding-top: 65px;
  .icons-header {
    &__title {
      text-align: center;
    }
    &__grid {
      margin-top: 85px;
      display: flex;
      justify-content: space-between;
      .icon-box {
        flex-basis: 192px;
        max-width: 192px;
        text-align: center;
        .icon-holder {
          margin-bottom: 24px;
          font-size: 0;
        }
        .text-holder {
          .title {
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 4px;
          }
          .desc {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding-top: 85px;
  }
  @media screen and (max-width: 1000px) {
    .icons-header {
      &__grid {
        margin-top: 25px;
        flex-flow: wrap;
        .icon-box {
          flex-basis: 50%;
          max-width: 50%;
          margin-top: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding-top: 50px;
    .icons-header {
      &__grid {
        margin-top: 0;
        .icon-box {
          flex-basis: 100%;
          max-width: 100%;
          margin-top: 50px;
        }
      }
    }
  }
`

export default StyledIconsHeader
