import styled from "styled-components"

const StyledMediaPage = styled.div`
  max-width: 860px;
  margin: 0 auto;
  padding-bottom: 220px;
  .media-box {
    max-width: 525px;

    &:not(:last-child) {
      margin-bottom: 140px;
    }

    &__title {
      position: relative;
      padding-bottom: 22px;
      margin-bottom: 40px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1px;
        width: 57.5px;
        height: 4px;
        background-color: #00fb6e;
      }
    }
    h3 {
      margin-bottom: 35px;
    }
    p {
      margin-bottom: 25px;
    }
    a {
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      transition: 0.2s;
      &:hover {
        color: #00fb6e;
      }
    }
    .pack-link {
      text-decoration: underline;
      margin-top: 22px;
    }
  }
  @media screen and (max-width: 1100px) {
    padding-bottom: 120px;
    .media-box {
      margin-bottom: 100px;
      &__title {
        margin-bottom: 30px;
      }
      h3 {
        margin-bottom: 30px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
    padding-bottom: 22px;
    .media-box {
      max-width: 525px;
      margin-bottom: 62px;
      &__title {
        margin-bottom: 34px;
      }
      h3 {
        margin: 60px 0 35px;
      }
      p {
        margin-bottom: 25px;
      }
    }
  }
`

export default StyledMediaPage
