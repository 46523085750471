import React from "react"

import StyledHero from "./styles"

const Hero = () => {
  return (
    <StyledHero>
      <div className="hero__wrapper">
        <div className="main-container">
          <h4 className="hero__label">#live_fair</h4>
          <h2 className="hero__header">RENT. ENJOY. RETURN.</h2>
        </div>
      </div>
    </StyledHero>
  )
}

export default Hero
