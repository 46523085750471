import StyledUpcycledJacketsDogThirdEdition from "./styles"

import React from "react"
import { Link, navigate } from "gatsby"
import { useLocale } from "context/locale"

const UpcycledJacketsDogThirdEdition = () => {
  const { t, lang } = useLocale()

  return (
    <StyledUpcycledJacketsDogThirdEdition
      text={lang === "en" ? "See location" : "Zobacz lokalizację"}
    >
      <div className="upcycled-jackets-post-second-edition">
        <p>
          Ubranka, które stworzyliśmy w trosce o ciepło i komfort Waszych
          pupili, zostały uszyte w pełni z recyklingu. Powstały w naszej
          krakowskiej szwalni, a do ich produkcji wykorzystaliśmy tylko tkaniny,
          suwaki i metki, które pochodzą z ubrań z drugiego obiegu zebranych w
          ramach projektu 4F Change. Dzięki szerokiej gamie rozmiarów oraz
          praktycznym detalom, Twojemu podopiecznemu będzie nie tylko ciepło,
          ale i wygodnie.
        </p>
        <p>
          <strong>
            <Link to="/aktualnosci/upcykling-ubran-co-to-takiego-i-dlaczego-warto/">
              więcej o idei upcyclingu
            </Link>
          </strong>
        </p>

        <div className="upcycled-jackets-post-second-edition__table">
          <img
            src={
              require("images/blog/upcycled-jackets-dogs-second-edition-table.svg")
                .default
            }
            alt=""
          />
        </div>

        <div className="upcycled-jackets-post-second-edition__dogs">
          <div className="upcycled-jackets-post-second-edition__dogs-col">
            <div
              className="upcycled-jackets-post-second-edition__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jackets-dogs-second-edition-dogs-image-1.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <p>
                  {t(
                    "Upcyclingowe kurteczki stworzyliśmy w trosce o ciepło i komfort Waszych pupili, tak by zabawy na świeżym powietrzu mogły trwać długo, niezależnie od pogody."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="upcycled-jackets-post-second-edition__dogs-col">
            <div
              className="upcycled-jackets-post-second-edition__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jackets-dogs-second-edition-dogs-image-2.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <p>
                  {t(
                    "W wybranych kurteczkach i bluzach wycięliśmy otwór na smycz, by zapewnić komfort użytkowania zarówno właścicielom czworonogów, jak i samym pieskom."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="upcycled-jackets-post-second-edition__dogs-col">
            <div
              className="upcycled-jackets-post-second-edition__dogs-item"
              style={{
                backgroundImage: `url('${
                  require("images/blog/upcycled-jackets-dogs-second-edition-dogs-image-3.jpg")
                    .default
                }')`,
              }}
            >
              <div>
                <p>
                  {t(
                    "Ubranka dla psów uszyliśmy z materiałów z drugiego obiegu, zebranych w ramach projektu Wear_Fair. Wybierając produkty upcyclingowe, Ty i Twój pupil dołączacie do grona Changemakerów!"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="upcycled-jackets-post-second-edition__locations">
          <h4>Ubranka dla psów są dostępne w Warszawa CH Arkadia</h4>
          <div>
            <button
              onClick={() => navigate(`${t("join_link")}?shop=ch-arkadia`)}
            >
              <strong>{t("Warszawa")}</strong> <span>{t("CH Arkadia")}</span>
            </button>
          </div>
        </div>
      </div>
    </StyledUpcycledJacketsDogThirdEdition>
  )
}

export default UpcycledJacketsDogThirdEdition
