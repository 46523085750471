import StyledViamoda from "./styles"

import React, { useRef, useState } from "react"
import Slider from "react-slick"

import IcoNext from "icons/slick-next-arrow.svg"

const Viamoda = () => {
  const ref = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentSlide2, setCurrentSlide2] = useState(0)
  const [currentSlide3, setCurrentSlide3] = useState(0)

  const settings = {
    dots: false,
    infinite: true,
    swipe: true,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    ref: ref,
    afterChange: function (index) {
      setCurrentSlide(index)
    },
  }

  const settings2 = {
    dots: false,
    infinite: true,
    swipe: true,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    ref: ref2,
    afterChange: function (index) {
      setCurrentSlide2(index)
    },
  }

  const settings3 = {
    dots: false,
    infinite: true,
    swipe: true,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    ref: ref3,
    afterChange: function (index) {
      setCurrentSlide3(index)
    },
  }

  const upcycleSlider = [
    require("images/blog/viamoda-upcycle-slide-1.jpg").default,
    require("images/blog/viamoda-upcycle-slide-2.jpg").default,
    require("images/blog/viamoda-upcycle-slide-3.jpg").default,
    require("images/blog/viamoda-upcycle-slide-4.jpg").default,
    require("images/blog/viamoda-upcycle-slide-5.jpg").default,
    require("images/blog/viamoda-upcycle-slide-6.jpg").default,
    require("images/blog/viamoda-upcycle-slide-7.jpg").default,
  ]

  const recycleSlider = [
    // require("images/blog/viamoda-recycle-slide-1.jpg").default,
    require("images/blog/viamoda-recycle-slide-2.jpg").default,
    require("images/blog/viamoda-recycle-slide-3.jpg").default,
    require("images/blog/viamoda-recycle-slide-4.jpg").default,
    // require("images/blog/viamoda-recycle-slide-5.jpg").default,
    require("images/blog/viamoda-recycle-slide-6.jpg").default,
    require("images/blog/viamoda-recycle-slide-7.jpg").default,
  ]

  const repairSlider = [
    require("images/blog/viamoda-repair-slide-1.jpg").default,
    require("images/blog/viamoda-repair-slide-2.jpg").default,
    require("images/blog/viamoda-repair-slide-3.jpg").default,
    require("images/blog/viamoda-repair-slide-4.jpg").default,
    require("images/blog/viamoda-repair-slide-5.jpg").default,
  ]

  return (
    <StyledViamoda>
      <div className="viamoda-content">
        <p>
          Circular Design 4F x Viamoda to projekt, który został realizowany w
          ramach strategii 4F Change we współpracy ze wyższą szkołą mody i
          projektowania Viamoda i trwał przez cały rok akademicki 2022/2023. W
          ramach projektu studenci ze specjalności Projektowanie ubioru i
          zarządzanie technologią otrzymali od zespołu 4F Change ubrania zebrane
          w programie Wear_Fair jako materiał wyjściowy do stworzenia nowych
          produktów. Powstały trzy zespoły projektowe, które zajmowały się całym
          spektrum działań kreatywnych opartych na wykorzystaniu znoszonej i
          uszkodzonej odzieży. Hasłem przewodnim dla wszystkich grup było{" "}
          <strong>CHANGE FOR THE FUTURE</strong>.
        </p>

        {/*  */}
        <div className="viamoda-content__divider-smaller" />
        <h2>1. GRUPA UPCYCLE</h2>
        <div className="viamoda-content__description">
          <p>
            <strong>Zespół UPCYCLE</strong> koncentrował się na formie wybranych
            ubiorów, tworząc nowe projekty za pomocą technik dekonstrukcji.
            Ubrania marki 4F zebrane w ramach projektu Wear_Fair stały się
            punktem wyjściowym do tworzenia nowych, unikalnych i
            pełnowartościowych produktów. Upcycling pozwala przedłużyć życie
            odzieży, aby jeszcze zmaksymalizować ten efekt studenci zdecydowali
            się także projektować ubrania, które można poddać transformacji i
            zmienić ich wygląd i funkcję. Potencjalny użytkownik zyskuje dzięki
            temu o wiele więcej możliwości stylizacji poszczególnych
            asortymentów.
          </p>
        </div>
        <div className="viamoda-content__image-wrapper">
          <div
            className="viamoda-content__image"
            style={{
              backgroundImage: `url('${
                require("images/blog/viamoda-upcycle-image.jpg").default
              }')`,
            }}
          />
        </div>

        <Slider ref={ref} {...settings}>
          {upcycleSlider?.map((item, index) => (
            <div key={index}>
              <img src={item} alt="" />
            </div>
          ))}
        </Slider>
        <div className="viamoda-content__slider-nav">
          <button onClick={() => ref?.current?.slickPrev()}>
            <IcoNext />
          </button>
          <div>
            {currentSlide + 1}
            <span />7
          </div>
          <button onClick={() => ref?.current?.slickNext()}>
            <IcoNext />
          </button>
        </div>

        <p>
          <strong>Inspiracja</strong>
          <br />
          Kolekcja jest inspirowana używanymi ubraniami, które zostały
          przekazane w ramach projektu i ich stylistyką. Ideą było oddanie
          początkowego stylu ubrań, dostosowując je jednocześnie do
          teraźniejszych trendów.
        </p>
        <p>
          <strong>Materiał</strong>
          <br />
          90% kolekcji została wykonana z używanych kurtek i spodni narciarskich
          4F.
        </p>
        <p>
          <strong>Biało-granatowa kurtka narciarska</strong>: powstała z niej
          krótka kurteczka z kapturem zamieniająca się w spódnicę oraz top
          wiązany na szyi za pomocą długiego sznurka.{" "}
        </p>
        <p>
          <strong>Biała kurtka narciarska</strong>: powstała z niej krótka biała
          kurteczka z czarnymi rękawami pochodzącymi z czarnej kurtki, a także
          spódnica zamieniająca się w torebkę.{" "}
        </p>
        <p>
          <strong>Czarna kurtka narciarska</strong>: została z niej zrobiona
          krótka kurteczka oraz spódnica zamieniająca się w plecak; rękawy
          zostały użyte do białej kurtki.
        </p>
        <p>
          <strong>Leginsy</strong>: z leginsów powstały spodenki rowerowe oraz
          rękawiczki.
        </p>
        <p>
          <strong>Czarna kurtka puchowa</strong>: została użyta do zrobienia
          krótkich spodenek oraz doczepianych nogawek, które mogą się zamienić w
          długie doczepiane rękawy.
        </p>

        <div className="viamoda-content__row-image">
          <img
            src={
              require("images/blog/viamoda-upcycle-content-image.jpg").default
            }
            alt=""
          />
          <p>
            <strong>Opis kolekcji</strong>
            <br />
            Kolekcja Upcycle powstała w całości z ubrań 4F pochodzących z
            drugiego obiegu, zebranych w ramach programu Wear_Fair. Nowe ubrania
            zostały zaprojektowane w duchu aktualnych trendów i – co ważne –
            wszystkie mają więcej niż jedną funkcję użytkowania. Z
            niewykorzystanych resztek uszyto dodatkowo torebkę na ramię,
            zmniejszając w ten sposób ilość odpadów. Zaprojektowane ubrania są
            oryginalne i unikatowe, co wyróżnia je na tle dostępnych na rynku
            masowych produktów. Mają zdolność do transformacji, a do ich
            zrobienia wykorzystano najbardziej ekologiczne materiały, jakie są,
            czyli te, które już istnieją.
          </p>
          <p>
            <strong>Grupa docelowa</strong>
            <br />
            Kolekcja skierowana jest do młodych osób, w przedziale wiekowym
            18-35, lubiących modę i rozumiejących wpływ krótkiego życia
            produktów na środowisko.
          </p>
        </div>

        <div className="viamoda-content__designers">
          <h4>Projektanci:</h4>
          <div>
            <span>Ilona Tsvietkova</span>
            <span>Julia Moruha</span>
            <span>Diana Nesterenko</span>
            <span>Hanna Poliak</span>
            <span>Katya Andrushenkava</span>
            <span>Arseni Tsar</span>
          </div>
        </div>

        {/*  */}
        <div className="viamoda-content__divider" />
        <h2>2. GRUPA RECYCLE</h2>
        <div className="viamoda-content__description">
          <p>
            <strong>Zespół RECYCLE</strong> zajmował się pracą z materiałem i
            działaniami powierzchniowymi. Odzież z drugiego obiegu marki 4F
            została pocięta i wykorzystana do stworzenia nowych materiałów za
            pomocą tkania i dziania. Powstały patchworki, faktury i aplikacje,
            które stały się punktem wyjścia do zaprojektowania ubiorów.
          </p>
        </div>
        <div className="viamoda-content__image-wrapper">
          <div
            className="viamoda-content__image"
            style={{
              backgroundImage: `url('${
                require("images/blog/viamoda-recycle-image.jpg").default
              }')`,
            }}
          />
        </div>

        <Slider ref={ref2} {...settings2}>
          {recycleSlider?.map((item, index) => (
            <div key={index}>
              <img src={item} alt="" />
            </div>
          ))}
        </Slider>
        <div className="viamoda-content__slider-nav">
          <button onClick={() => ref2?.current?.slickPrev()}>
            <IcoNext />
          </button>
          <div>
            {currentSlide2 + 1}
            <span />5
          </div>
          <button onClick={() => ref2?.current?.slickNext()}>
            <IcoNext />
          </button>
        </div>

        <p>
          <strong>Inspiracja</strong>
          <br />
          Kolekcja została zainspirowana Wielką Rafą Koralową. Obejmuje ona
          ponad 3000 pojedynczych raf o różnych formach – jedne przypominają
          kształtem nerki i mają laguny inne – wstęgi, a jeszcze inne to płaskie
          platformy bez lagun. Eksperci przewidują, że rafy koralowe mogą
          zniknąć do 2100 roku. Pomimo ciągłych badań i regularnie podejmowanych
          działań, globalne ocieplenie i coraz wyższy poziom mórz sprawiają, że
          koralowce powoli giną. Dodatkowo odkryto, że koralowce są szczególnie
          podatne na zmiany spowodowane emisją gazów cieplarnianych, za które
          odpowiedzialni są ludzie. Wielka Rafa Koralowa symbolizuje więc to, co
          możemy bezpowrotnie utracić, jeśli nie postaramy się, żeby branża
          odzieżowa była bardziej zrównoważona.
        </p>

        <p>
          <strong>Materiał</strong>
          <br />
          Materiały, które powstały z wykorzystaniem ubrań z drugiego obiegu
          cechuje duża oryginalność, a odzież z nich uszyta jest niepowtarzalna.
          Dla osób, które cenią sobie indywidualność w swoich stylizacjach,
          takie ubrania stworzone z kolorowych, wzorzystych materiałów mogą być
          o wiele bardziej atrakcyjne niż te zrobione ze zwykłych, gładkich,
          fabrycznych. Autorzy kolekcji starali trafić w gust klienta o
          niestandardowych wymaganiach.{" "}
        </p>

        <div className="viamoda-content__row-image">
          <img
            src={
              require("images/blog/viamoda-recycle-content-image.jpg").default
            }
            alt=""
          />
          <p>
            <strong>Opis kolekcji</strong>
            <br />
            Kolekcja Recycle powstała z uszkodzonych ubrań, które zostały
            przetworzone na nową, oryginalną odzież. Została zainspirowana Rafą
            Koralową, która jest narażona na wyginięcie z powodu globalnego
            ocieplenia, rosnącego poziomu mórz oraz zanieczyszczania wody.
            Działania grupy miały na celu szerzenie świadomości na ten temat i
            życie w zgodzie z hasłem „Together we play for the planet. Let's
            play it fair.” Kolekcja Recycle to kolekcja puchówek, których
            najważniejszym elementem są umieszczone na fragmentach okryć
            faktury, które powstały z poddanych recyklingowi kurtek 4F. Gama
            kolorystyczna jest oparta na zestawieniu kolorów zaczerpniętych z
            rafy. Turkus wody morskiej i róż zdrowego koralowca łącz się w
            kolekcji z bielą, szarością i czernią symbolizującą choroby rafy –
            koralowiec chory traci kolor i staje się stopniowo cały biały, lub
            pojawiają się na nim czarne plamy.
          </p>
          <p>
            <strong>Grupa docelowa</strong>
            <br />
            Kolekcja skierowana jest do młodych ludzi, podążających za modą i
            trendami, lubiących odważne i awangardowe stylizacje, jednocześnie
            ceniących zrównoważone podejście do mody.
          </p>
        </div>

        <div className="viamoda-content__designers">
          <h4>Projektanci:</h4>
          <div>
            <span>Małgorzata Dłużewska</span>
            <span>Wiktoria Frankowska</span>
            <span>Aleksandra Grabska</span>
            <span>Eliza Kobza</span>
            <span>Weronika Kulesza</span>
            <span>Weronika Rybak</span>
            <span>Modelka- Weronika Rybak</span>
            <span>Model- Kuba K</span>
          </div>
        </div>

        {/*  */}
        <div className="viamoda-content__divider" />
        <h2>3. GRUPA REPAIR</h2>
        <div className="viamoda-content__description">
          <p>
            <strong>Zespół REPAIR</strong> stanął przed niełatwym zadaniem,
            polegającym na wymyśleniu kreatywnych sposobów naprawy ubrań.
            Projektanci starali się przekuć ślady użytkowania ubrań, takie jak
            przetarcia, przebarwienia i rozprucia w ich atut, jednocześnie
            umożliwiając klientom jak najdłuższe użytkowanie.
          </p>
        </div>
        <div className="viamoda-content__image-wrapper">
          <div
            className="viamoda-content__image"
            style={{
              backgroundImage: `url('${
                require("images/blog/viamoda-repair-image.jpg").default
              }')`,
            }}
          />
        </div>

        <Slider ref={ref3} {...settings3}>
          {repairSlider?.map((item, index) => (
            <div key={index}>
              <img src={item} alt="" />
            </div>
          ))}
        </Slider>
        <div className="viamoda-content__slider-nav">
          <button onClick={() => ref3?.current?.slickPrev()}>
            <IcoNext />
          </button>
          <div>
            {currentSlide3 + 1}
            <span />7
          </div>
          <button onClick={() => ref3?.current?.slickNext()}>
            <IcoNext />
          </button>
        </div>

        <p>
          <strong>Inspiracja</strong>
          <br />
          Kolekcja została zainspirowana jednym z czterech żywiołów – ziemią –
          jako odniesieniem do ekologicznego podejścia w modzie. Kolekcja
          inspiruje się nie tylko kolorystyką ziemi, ale także występującymi w
          naturze motywami, takimi jak np. odbite liście na części ubrań.
          Wszystkie sylwetki tworzą spójną całość nawiązującą do żywiołu ziemi,
          dzięki odpowiedniemu doborowi materiałów i ciekawemu sposobowi naprawy
          uszkodzonych elementów.
        </p>

        <p>
          <strong>Materiał</strong>
          <br />
          Określenie „Matka Ziemia” jest często używane w celu promowania
          zrównoważonego rozwoju. Oznacza to przede wszystkim, że Ziemia jest
          źródłem wszystkich żywych stworzeń oraz roślin, dlatego jesteśmy
          zobowiązani ją pielęgnować i o nią dbać. Przykładem wpływu branży
          modowej na planetę jest produkcja niektórych włókien i
          wykorzystywanych w tym procesie chemikaliów, w szczególności
          intensywne stosowanie chemikaliów w uprawach bawełny, które powodują
          liczne choroby i degradację gleby. W kolekcji Repaired, łącząc różne
          techniki napraw (zarówno te widoczne, jak i niewidoczne) oraz
          inspirując się właśnie Matką Ziemią i jej naturalnym pięknem, twórcy
          chcieli pokazać jak w prosty, ale kreatywny sposób można odratować
          zużyte ubrania, które teoretycznie nie nadawały się już do noszenia.
        </p>

        <div className="viamoda-content__row-image viamoda-content__row-image--reverse">
          <img
            src={
              require("images/blog/viamoda-repair-content-image.jpg").default
            }
            alt=""
          />
          <p>
            Kolekcja zawiera 3 sylwetki – dwie damskie i jedną męską. W każdej z
            sylwetek zastosowano przynajmniej jedną z wielu technik naprawiania
            np. odbarwianie bądź cerowanie. Każda z nich chowała jakąś skazę,
            którą dzięki kreatywnym rozwiązaniom przekuliśmy w zaletę. Cały
            projekt utrzymany jest kolorystyce ziemi – to głównie odcienie
            brązu, beżu i zieleni.
          </p>
          <p>
            <strong>Grupa docelowa</strong>
            <br />
            Mężczyźni i kobiety w wieku 18-35 lat. Osoby prowadzące aktywny tryb
            życia, uprawiające sport, interesujące się modą, zwłaszcza modą
            bardziej zrównoważoną, podejmujące świadome decyzje konsumenckie.
          </p>
        </div>

        <div className="viamoda-content__designers viamoda-content__designers--reverse">
          <h4>Projektanci:</h4>
          <div>
            <span>Małgorzata Dłużewska</span>
            <span>Wiktoria Frankowska</span>
            <span>Aleksandra Grabska</span>
            <span>Eliza Kobza</span>
            <span>Weronika Kulesza</span>
            <span>Weronika Rybak</span>
            <span>Modelka- Weronika Rybak</span>
            <span>Model- Kuba K</span>
          </div>
        </div>

        {/*  */}
        <div className="viamoda-content__copy">
          <h5>Opiekę nad projektem ze strony uczelni VIAMODA sprawowały:</h5>
          <h4>Dominika Łukawska-Milczarska</h4>
          <span>
            PROREKTOR VIAMODA SZKOŁY WYSZEJ W WARSZAWIE
            <br /> Kierownik projektu, opiekun grup projektowych
          </span>
          <h4>Anna Stępień-Fazlejew</h4>
          <span>
            Nadzór nad realizacją kolekcji w laboratorium technologicznym
            uczelni
          </span>
          <h4>Kinga Miller</h4>
          <span>
            REKTOR VIAMODA SZKOŁY WYŻSZEJ W WARSZAWIE
            <br /> Opiekun grupy marketingowej
          </span>
        </div>
        <div className="viamoda-content__join">
          <p>
            Zapraszamy do salonu 4F w CH Westfield Arkadia w Warszawie, gdzie w
            dniach 17-24 lipca będzie można zobaczyć na żywo projekty studentów.
          </p>
          <h4>Dołącz do nas!</h4>
        </div>
      </div>
    </StyledViamoda>
  )
}

export default Viamoda
