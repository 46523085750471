import StyledFixClothes from "./styles"

import React from "react"
import Button from "components/Button"
import { withPrefix } from "gatsby"
import { useLocale } from "context/locale"

const FixClothes = () => {
  const { t } = useLocale()

  return (
    <StyledFixClothes>
      <div className="fix-clothes-post">
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Najbardziej ekologiczne ubrania to te, które już masz, więc dbaj o nie i noś jak najdłużej. Jeżeli potrzebujesz kupić coś nowego, wybieraj odzież zrobioną z materiałów o wysokiej jakości, która nie traci fasonu, nie rozciąga się i dużo łatwiej ją naprawić. Naprawiając ubrania, dbasz o planetę – oszczędzasz wodę, konieczną do ich produkcji, zmniejszasz ilość zanieczyszczeń, które dostają się do środowiska i wytwarzasz mniej odpadów (więcej o tym <a href='/aktualnosci/dlaczego-warto-naprawiac-ubrania/'>tutaj</a>)."
            ),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Dlatego zamiast wyrzucać, naprawiaj! Prostych napraw możesz dokonać samodzielnie, z bardziej skomplikowanymi udaj się do krawcowej lub <a href='/dolacz?shop=true'>przyjdź do nas</a>."
            ),
          }}
        />
        <p>
          {t(
            "Od 5 czerwca 2023 r. usługa naprawy jest dostępna w każdym salonie 4F* i obejmuje wszystkie rodzaje ubrań marki 4F."
          )}
        </p>
        <p>
          {t(
            "Uszkodzone ubrania przynieś do jednego z naszych sklepów i podejdź z nimi do kasy. Sprzedawca oceni, czy naprawa jest możliwa do zrealizowania i przyjmie zlecenie, informując Cię o cenie i terminie odbioru."
          )}
        </p>

        <h3>{t("Cennik")}</h3>
        <div className="fix-clothes-post__table">
          <div className="fix-clothes-post__table-header">
            <div className="col">{t("Typ naprawy")}</div>
            <div className="col">{t("Cena")} (PLN)</div>
            <div className="col">{t("Uwagi")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">{t("Rozprucie, rozdarcie, dziura")} </div>
            <div className="col">49,99 – 59,99</div>
            <div className="col">1 {t("uszkodzenie")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">
              {t("Wymiana zamka głównego")} (1 {t("zamek zwykły")})
            </div>
            <div className="col">79,99</div>
            <div className="col">{t("zamek wliczony w koszt usługi")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">
              {t("Wymiana zamka głównego")} (1 {t("zamek wodoodporny")})
            </div>
            <div className="col">94,99</div>
            <div className="col">{t("zamek wliczony w koszt usługi")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">
              {t("Wymiana zamka kieszeni")} (1 {t("zamek zwykły")})
            </div>
            <div className="col">49,99 – 59,99</div>
            <div className="col">{t("zamek wliczony w koszt usługi")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">
              {t("Wymiana suwaka")} ({t("slidera")}){" "}
            </div>
            <div className="col">19,99</div>
            <div className="col">1 {t("suwak")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">{t("Naprawa/wymiana gumy")}</div>
            <div className="col">39,99</div>
            <div className="col">1 {t("sztuka")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">
              {t("Wymiana zatrzasku")} ({t("napa")}){" "}
            </div>
            <div className="col">34,99</div>
            <div className="col">{t("komplet")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">{t("Wymiana rzepów")}</div>
            <div className="col">39,99</div>
            <div className="col">{t("komplet")}</div>
          </div>
          <div className="fix-clothes-post__table-row">
            <div className="col">{t("Wymiana pullera/stopera")}</div>
            <div className="col">39,99</div>
            <div className="col">{t("komplet")}</div>
          </div>
        </div>

        <div className="fix-clothes-post__rules">
          <img
            src={require("images/blog/fix-clothes-post-icon.svg").default}
            alt=""
          />
          <Button
            className="white-box__button"
            text={t("Terms and conditions")}
            as="link-out"
            href={withPrefix("/Regulamin_Napraw_Krawieckich.pdf")}
          />
          <small>* {t("z wyłączeniem stoisk sezonowych")}</small>
        </div>
      </div>
    </StyledFixClothes>
  )
}

export default FixClothes
