import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import StyledMediaPage from "styles/MediaStyles"

const MediaPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      acf: wpPage(databaseId: { eq: 137 }) {
        title
        acfMedia {
          mediaContent {
            mediaContentHeader
            mediaContentSubheader
            mediaContentDescription
            mediaContentLinkText
            mediaContentLinkTarget
          }
        }
      }
    }
  `)

  return (
    <Layout
      location={location}
      seo={{
        title: `${data?.acf?.title} - 4F Change`,
        description: "Informacje prasowe, zdjęcia, relacje video.",
      }}
    >
      <PageHeader title={data?.acf?.title} />
      <div className="main-container">
        <StyledMediaPage>
          {data?.acf?.acfMedia?.mediaContent.map((item, index) => (
            <div className="media-box" key={index}>
              <h2 className="media-box__title">{item?.mediaContentHeader}</h2>
              {item?.mediaContentSubheader && (
                <h3>{item?.mediaContentSubheader}</h3>
              )}
              <p>{item?.mediaContentDescription}</p>
              {item?.mediaContentLinkText && (
                <a
                  href={item?.mediaContentLinkTarget}
                  className="bolder"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item?.mediaContentLinkText}
                </a>
              )}
            </div>
          ))}
        </StyledMediaPage>
      </div>
    </Layout>
  )
}

export default MediaPage
