import styled from "styled-components"

const StyledSteps = styled.section`
  padding: 100px 0;

  @media (max-width: 1199px) {
    padding: 75px 0;
  }

  .steps {
    &__header {
      text-align: center;
      margin: 0 0 85px 0;

      @media (max-width: 1199px) {
        margin: 0 0 50px 0;
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1199px) {
        flex-direction: column;
      }
    }

    &__col {
      &:nth-child(1) {
        flex: 0 0 40%;
        max-width: 40%;

        @media (max-width: 1199px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &:nth-child(2) {
        flex: 0 0 30%;
        max-width: 30%;

        @media (max-width: 1199px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &:nth-child(3) {
        flex: 0 0 30%;
        max-width: 30%;

        @media (max-width: 1199px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &:not(:last-child) {
        padding: 0 30px 0 0;

        @media (max-width: 1199px) {
          padding: 0;
          margin: 0 0 20px 0;
        }
      }
    }

    &__item {
      display: flex;

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 81px;
        min-width: 81px;
        height: 81px;
        border-radius: 50%;
        border: 4px solid #000;
        margin: 0 30px 0 0;

        @media (max-width: 1199px) {
          width: 61px;
          min-width: 61px;
          height: 61px;
        }
      }
    }
  }
`

export default StyledSteps
