import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const ServiceTerms = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Terms of use of 4F CHANGE - 4F Change",
      description:
        "These regulations define the rules of using the website available at https://www.4Fchange.com.",
    }}
  >
    <PageHeader title="TERMS OF USE OF 4F CHANGE" />

    <StyledDescriptionPage>
      <div className="description-page">
        <h4>DEFINITIONS</h4>
        <p>
          <strong>1. Administrator</strong> - OTCF S.A. with its registered
          office in Kraków (ul. Saska 25C, 30-720 Kraków), registered by the
          District Court for Kraków-Śródmieście in Kraków, 12th Business
          Division of the National Court Register under KRS [National Court
          Register] No.: 0000555276, NIP [VAT No.]: PL9451978451, REGON
          [National Official Business Register No.]: 356630870, share capital of
          PLN 7.384.500 (paid in full), BDO [waste database] No.: 000005025,
          e-mail address: info@4fchange.com.
        </p>
        <p>
          <strong>2. Website</strong> – 4F CHANGE, a website available at
          https://4fchange.com/ run by the Administrator, acting as an
          information platform on the environmental strategy of OTCF S.A. based
          in Wieliczka, through which the Administrator gives access to the
          Website functionalities and provides the Users with services, and the
          Users learn about the Administrator's services. The Administrator uses
          the Website to give access to appropriate system, ICT, and
          technological tools needed to provide the services.
        </p>
        <p>
          <strong>3. Pages of the Website</strong> - web pages available at
          https://4fchange.com/.
        </p>
        <p>
          <strong>4. Terms of Use</strong> - these Terms of Use governing the
          use of the Website, in particular entering into and performance of
          agreements for the provision of services by electronic means through
          the Website and the complaint handling procedure.
        </p>
        <p>
          <strong>5. User</strong> - a natural person, a legal person or an
          organisational unit without legal personality making use of the
          functionalities of the Website.
        </p>
        <p>
          <strong>6. Consumer</strong> - User acting as a natural person and
          making, with the Administrator, a legal transaction that is not
          directly connected with its business or professional activity.
        </p>
        <p>
          <strong>7. Services</strong> – services available on the Website
          provided electronically that may be the subject of an agreement
          between the User and the Administrator.
        </p>
        <p>
          <strong>8. GDPR</strong> - Regulation (EU) 2016/679 of the European
          Parliament and of the Council of 27 April 2016 on the protection of
          natural persons with regard to the processing of personal data and on
          the free movement of such data, and repealing Directive 95/46/EC.
        </p>
        <p>
          <strong>9. Newsletter</strong> - a service provided by the
          Administrator through which the Administrator sends commercial
          information, in particular on the range of products and services, by
          e-mail to the e-mail address provided by the User.
        </p>

        <h4>I. Preliminary provisions</h4>
        <p>
          1. The Administrator shall apply technical and organisational measures
          appropriate to the degree of risk, including measures aimed at
          preventing unauthorised persons from acquiring and modifying the
          personal data transmitted via the Internet to ensure security of
          messages and data transmitted on the Website. The Administrator shall
          ensure the security of data transmission on the Website by using the
          SSL (Secure Socket Layer) protocol.
        </p>
        <p>
          2. The Administrator shall make the functionalities of the Website
          available using the Internet.
        </p>
        <p>
          3. The User shall use the Website in a manner that does not disturb
          its functioning, complying with the law, the provisions of the Terms
          of Use, and good practices, and respecting the rights and personal
          goods of other entities. The User is not allowed to provide unlawful
          content.
        </p>
        <h4>II. Means of communication</h4>
        <p>
          1. Enquiries addressed to the Administrator can be submitted directly
          through the pages of the Website. To submit the enquiry, the User
          shall use the contact form available on the page of the Website (by
          entering the required data in the fields of the enquiry form, to
          enable the Administrator to respond to the enquiry, and ticking the
          required fields), or send the enquiry to the following e-mail address:
          info@4fchange.com.
        </p>
        <p>
          2. To respond to the enquiry, the Administrator may contact the User
          by e-mail or by phone (if a contact phone number has been provided).
        </p>
        <h4>III. “Order a courier” form</h4>
        <p>
          1. The User who intends to make a donation via courier service through
          the “Ubrania do oddania” [Clothes to donate] Fundraising Platform
          (www.ubraniadooddania.pl) may use an electronic form “Order a courier”
          provided by the Administrator on the pages of the Website. The form
          enables the User to provide New Solutions Group Sp. z o.o., based in
          Warszawa, ul. Prosta 70, with the required User's data (name, surname,
          e-mail address, address, phone number). The form also enables the User
          to make statements of intent directly to New Solutions Group Sp. z
          o.o., with respect to, inter alia, creating an account on
          www.ubraniadooddania.pl, and accept the terms of use of the portal or
          other documents specified by New Solutions Group Sp. z o.o. in the
          form.
        </p>
        <p>
          2. The Administrator shall not be liable for the actions of New
          Solutions Group Sp. z o.o., in particular for further contact with the
          User, processing of its personal data provided in the form, and the
          functioning of the www.ubraniadooddania.pl. The Administrator shall
          only make the electronic form available on the Website (as a
          functionality of the Website) and shall not collect and store personal
          data entered by the User in the form. Personal data entered by the
          User in the form shall be obtained directly by New Solutions Group Sp.
          z o.o. as a separate data controller within the meaning of GDPR.
        </p>

        <h4>IV. Newsletter</h4>
        <p>
          1. As part of a newsletter service (hereinafter referred to as the
          „Newsletter”), the Administrator shall send an e-mail to the email
          address provided by the User. Newsletter shall be provided free of
          charge for an indefinite period.
        </p>
        <p>
          2. Newsletter shall contain information on the 4F Change strategy and
          Wear_Fair programme, the 4F brand's range of products and services,
          new sustainably produced collections, current special offers, the
          opening of new Wear_Fair zones in shops, and other news about the 4F
          brand and its environmental strategy and products, including reviews,
          press materials, and links to 4F befriended sites.
        </p>
        <p>3. Each Newsletter shall contain:</p>
        <ul>
          <li>
            information about the Administrator as the sender of the Newsletter,
          </li>
          <li>
            information in the „Subject” field specifying the subject of the
            Newsletter,
          </li>
          <li>
            information on how to withdraw from the Newsletter or change the
            User's e-mail address used to send the Newsletter.
          </li>
        </ul>
        <p>
          4. To use the Newsletter, the User shall have a computer or other
          multimedia device with access to the Internet and an active e-mail
          account.
        </p>
        <p>
          5. To subscribe to the Newsletter, the User shall perform the
          following registration steps in the “Join the change, subscribe to the
          newsletter, and be up to date with our actions” field on
          https://4fchange.com:
        </p>
        <ul>
          <li>
            provide the User's e-mail address in the form placed on the page,
          </li>
          <li>
            agree to receive Newsletter messages and tick the check-box to
            confirm that the User has read and accepts the Terms of Use,
          </li>
          <li>press (click on) the “Join” button,</li>
          <li>
            click on the link confirming registration included in the e-mail
            with the following subject line: “Confirmation of the subscription
            to the newsletter.”
          </li>
        </ul>
        <p>
          6. Pressing (clicking on) the registration confirmation link shall
          mean that the User's e-mail address is subscribed to the e-mailing
          list. Newsletters shall be sent to the User's e-mail address.
        </p>
        <p>
          7. To receive Newsletters, the User is required to provide its e-mail
          address in the registration form.
        </p>
        <p>
          8. The User may, at any time, without giving reasons and without
          bearing any costs, change the previously indicated e-mail address used
          to send the Newsletter. To change the e-mail address, the User shall
          withdraw from the Newsletter by pressing (clicking on) the
          “Unsubscribe Newsletter” link located in the footer of each
          Newsletter, re-order the Newsletter by performing registration steps
          in the way indicated in item 6 above, and indicate a new e-mail
          address in the “NEWSLETTER” field in the form placed on
          https://4fchange.com.
        </p>
        <p>
          9. The User may, at any time, without giving reasons and without
          bearing any costs, withdraw from the Newsletter. To withdraw from the
          Newsletter, the User shall:
        </p>
        <ul>
          <li>
            click on the “Unsubscribe Newsletter” link located in the footer of
            each Newsletter;
          </li>
          <li>
            uncheck the following consent box in the User Panel: “I agree to
            receive commercial correspondence (NEWSLETTER) to the provided
            e-mail address according to the terms of use of the online shop. I
            have the right to withdraw my consent to data processing at any
            time, which will not affect the lawfulness of the processing
            performed before the withdrawal of consent.” and press (clicking on)
            the “Save changes” button;
          </li>
          <li>
            send the statement of intent regarding the withdrawal from the
            Newsletter to OTCF via e-mail to the following e-mail address:
            info@4fchange.com.
          </li>
        </ul>
        <p>
          10. When using the Newsletter, the User shall not be allowed to
          provide or transmit illegal content.
        </p>
        <h4>V. Other forms and aggregate provisions regarding forms</h4>
        <p>
          1. The Administrator can make other forms available to the User via
          the pages of the Website to enable the User to recommend the Website
          or services offered in the Website to other Internet users and take
          part in competitions and special offers provided by the Administrator.
        </p>
        <p>
          2. By using the form, the User enters into the agreement for the
          provision of services by electronic means. To terminate the agreement
          for the provision of services by electronic means, the User shall use
          the relevant form. The Administrator may introduce, communicate, and
          submit for acceptance additional rules of using the forms before the
          forms are actually used.
        </p>

        <h4>VI. Complaint handling procedure</h4>
        <p>
          1. Each User shall have the right to lodge a complaint in matters
          related to the functioning of the Website or the Services performed by
          the Administrator.
        </p>
        <p>
          2. Complaints can be submitted in particular by sending an e-mail to
          info@4fchange.com, which is the form recommended by the Administrator.
          The User is not obliged to use or follow this form for the validity of
          the complaint.
        </p>
        <p>
          3. The Administrator shall process and respond to each complaint
          (communicate the manner of processing) immediately, no later than
          within 14 days from the date of the complaint. The way the User is to
          be informed of the manner of processing shall be indicated in the
          complaint report.
        </p>
        <p>
          4. In the event of any deficiencies in the complaint, the
          Administrator shall request the User to complete it based on the
          User's address details indicated in the complaint.
        </p>

        <h4>
          VII. Technical requirements necessary for operation with the ICT
          system used by the Administrator
        </h4>
        <p>
          1. To use the Website, and in particular to learn about the
          functionalities and services available on the Website, the User shall
          have a terminal device with access to the Internet and an installed
          web browser: Internet Explorer, Mozilla Firefox, Opera, Google Chrome,
          or Apple Safari.
        </p>
        <p>
          2. To use the Services, the User shall have an active e-mail account.
        </p>

        <h4>VIII. Personal data protection</h4>
        <p>
          1. OTCF S.A is the Controller (Administrator) of personal data of
          Users using the Website functionalities (within the meaning of Article
          4(7) of GDPR), except for the “Order a courier” form functionality
          (Section III of the Terms of Use).
        </p>
        <p>
          2. The Administrator has appointed a Data Protection Supervisor (DPS)
          who can be contacted in matters concerning the protection of personal
          data and the exercise of rights related thereto. To that end, the User
          may contact the Data Protection Supervisor by means of electronic mail
          at iod@otcf.pl or by means of traditional mail at OTCF S.A., ul.
          Grottgera 30, 32-020 Wieliczka (annotated with “Data Protection
          Supervisor (DPS)”).
        </p>
        <p>
          3. User's personal data, depending on the use of the Website,
          including the services offered through the Website, may be processed
          for the following purposes and on the following legal bases:
        </p>
        <ul>
          <li>
            to enable the use of the functionalities of the forms referred to in
            Section V of the Terms of Use under the agreement for the provision
            of services by electronic means entered into with the User (legal
            basis for data processing: Article 6(1)(b) of GDPR) in accordance
            with these Terms of Use and based on specific rules for using
            particular forms, if applicable in accordance with the decision of
            the Administrator (such specific rules may determine additional
            purposes and legal grounds for the processing of personal data);
          </li>
          <li>
            to process complaints related to the agreement for the provision of
            services by electronic means, if the User has entered into the
            agreement with the Administrator in accordance with the rules set
            out in these Terms of Use (Article 6(1)(b) of GDPR);
          </li>
          <li>
            to accept and process notifications and enquiries other than
            complaints and matters related to the agreements, as directed to the
            Administrator (e.g. through contact details indicated on the pages
            of the Website or a contact form), which constitutes the
            Administrator's legitimate interest (legal basis for data
            processing: Article 6(1)(f) of GDPR);
          </li>
          <li>
            to process complaints and assert claims, defend against claims, and
            implement out-of-court solutions for handling complaints and
            asserting claims, which constitutes the Administrator's legitimate
            interest (legal basis for data processing: Article 6(1)(f) of GDPR);
          </li>
          <li>
            to monitor the Users' use of services provided on the Website for
            compliance with the provisions of the Terms of Use, and to develop
            the Website functionality, and improve the functioning of the
            services provided through the Website, which constitutes the
            Administrator's legitimate interest (legal basis of data processing:
            Article 6(1)(f) of GDPR);
          </li>
          <li>
            for direct marketing purposes, including making marketing analyses
            and adapting content to the nature or preferences of Users (e.g. as
            part of the Newsletter), which constitutes the Administrator's
            legitimate interest (legal basis for data processing: Article
            6(1)(f) of GDPR);
          </li>
          <li>
            to carry out statistical analyses, which constitutes the
            Administrator's legitimate interest (legal basis for data
            processing: Article 6(1)(f) of GDPR);
          </li>
          <li>
            to store data for archiving purposes and to demonstrate the correct
            fulfilment of legal obligations imposed on the Administrator, which
            constitutes its legitimate interest (legal basis for data
            processing: Article 6(1)(f) of GDPR);
          </li>
          <li>
            sending commercial information by electronic means, in the form of
            the Newsletter, if the User has expressed a separate consent to
            receiving commercial information by electronic means,
          </li>
          <li>
            conducting User satisfaction surveys – if the User has expressed a
            separate consent to participate in such a survey (legal grounds for
            data processing: Article 6(1)(a) of the GDPR);
          </li>
          <li>
            to record data in the form of cookies and collect data from the
            pages of the Website, based on the Cookie Policy applicable on the
            pages of the Website and in accordance with User preferences.
          </li>
        </ul>
        <p>
          4. Users' personal data may be disclosed to the following categories
          of recipients:
        </p>
        <ul>
          <li>
            subcontractors providing technical support to the Administrator in
            running, maintaining, and developing the Website, including hosting
            service providers, software suppliers for managing the Website, the
            Website's software technical maintenance service providers,
            suppliers providing software for sending commercial correspondence
            by electronic means, and suppliers providing software for
            communication with Users, including communication executed by means
            of dedicated algorithms, who have entered into legally required
            personal data processing entrustment agreements with the
            Administrator;
          </li>
          <li>
            /entities that support the Administrator in its marketing
            activities, such as marketing agencies and entities running online
            portals, including social media portals;
          </li>
          <li>
            entities that support the Administrator in the execution of User
            satisfaction survey programmes, in particular through surveys
            conducted electronically or by phone; entities that support the
            Administrator in the execution of applicable laws as well as rights
            and obligations resulting from these Terms of Use in connection with
            the provision of services through the Website, including law firms
            and debt collection companies.
          </li>
        </ul>
        <p>
          5. In the case of using the “Order a courier” form, the User will make
          its personal data available directly to New Solutions Group Sp. z o.o.
          (as a separate administrator), as specified in the regulations and
          based on other provisions made available by New Solutions Group Sp. z
          o.o. and accepted by the User when filling in the form. The Website
          Administrator does not collect and store the User's personal data.
        </p>
        <p>
          6. Users' personal data may be transferred by the Administrator
          outside the European Economic Area (EEA) as part of the
          Administrator's use of subcontractors (following the categories of
          recipients referred to in item 4 above). In such a case, the
          Administrator shall ensure the legally required personal data
          protection measures, i.e. as the case may be: (i) transfer of data to
          a subcontractor located in a third country for which an adequacy
          decision has been issued as required by Article 45 of GDPR, (ii)
          transfer of data carried out on the basis of a data transfer agreement
          entered into with the subcontractor based on the Standard Contractual
          Clauses adopted by decision of the European Commission, (iii) transfer
          of data carried out under the binding corporate rules applied by the
          subcontractor as referred to in Article 47 of GDPR. For more
          information on the Administrator's security measures relating to the
          transfer of data outside the EEA, please contact the Data Protection
          Supervisor appointed by the Administrator.
        </p>
        <p>
          7. The personal data shall be stored by the Administrator for the
          duration of the provision of the services of the Website (to Users),
          for the duration of the agreements for the provision of services by
          electronic means, and
        </p>
        <ul>
          <li>
            until the potential claims under the agreements specified
            hereinabove are barred,
          </li>
          <li>
            for the time necessary to assert or defend specific claims made by
            the Administrator (if brought by the User in connection with the
            agreements referred to above),
          </li>
          <li>
            for the duration of the obligations arising from the law, including
            in particular tax and accounting regulations, e.g. record retention
            obligations as referred to in Article 74 of the Accounting Act of 29
            September 1994,
          </li>
          <li>
            for the period necessary for the Administrator to demonstrate the
            proper fulfilment of legal obligations before public administration
            bodies, including the personal data protection supervisory
            authority,
          </li>
          <li>
            for archiving purposes when it concerns the history of the
            correspondence and replies to the questions asked (not directly
            related to the concluded agreements) – for a period which shall be
            no longer than 3 years from obtaining the data
          </li>
          <li>
            for direct marketing purposes, for the duration of the agreement for
            the provision of electronic services (Users) or until the processing
            of data for this purpose is objected,
          </li>
          <li>
            until the data processing consent is withdrawn or the data become
            obsolete (as determined by the Administrator), if the data
            processing is based on the consent given by a specific individual.
          </li>
        </ul>
        <p>
          8. The Administrator shall ensure that each User can exercise all
          their rights under GDPR, i.e. the right to request access to their
          personal data, the right to rectify or erase data, or request
          restriction of data processing, the right to data portability, and the
          right to object to data processing, on the terms and in the cases
          provided for in GDPR.
        </p>
        <p>
          9. If the personal data are processed by the Administrator to pursue
          legitimate interests (as referred to above), each User shall have the
          right to object to the data processing on grounds related to its
          specific situation.
        </p>
        <p>
          10. Data processed to pursue a legitimate interest, i.e. the
          Administrator's direct marketing, shall be processed only until an
          objection is raised against this form of processing. The User shall
          have the right to object to the processing of the personal data
          thereof for direct marketing purposes, including profiling, at any
          time. The objection can be made using the following e-mail address:
          iod@otcf.pl.
        </p>
        <p>
          11. If the personal data are processed by the Administrator with the
          consent of the User, each individual shall have the right to withdraw,
          at any time, their consent to the processing of their data, which
          shall not affect the lawfulness of data processing that took place
          before the withdrawal of consent. The consent to the processing of
          personal data may be revoked by way of a statement submitted to the
          following e-mail address: iod@otcf.pl or in the manner specified in
          the terms of use of the service that is provided on the basis of the
          consent given.
        </p>
        <p>12. Provision of personal data with respect to:</p>
        <ul>
          <li>
            Users making complaints in connection with the agreement for the
            provision of services by electronic means entered into with the
            Administrator under these Terms of Use – to make a complaint and
            enable its processing by the Administrator, it is necessary to
            provide the e-mail address that the User provided in the relevant
            form or while registering for the Newsletter (if the complaint
            concerns the Newsletter). Failure to provide such data may prevent
            the Administrator from processing the complaint,
          </li>
          <li>in other cases, provision of data shall be voluntary.</li>
        </ul>
        <p>
          13. The Administrator shall not conduct User data processing
          operations in an automated manner resulting in decisions having legal
          effects thereon or similarly and significantly affecting the situation
          thereof. Possible automated data processing, including profiling,
          shall be used solely to analyse and forecast individual preferences of
          Users using the Website.
        </p>
        <p>
          14. Any individual whose personal data is processed by the
          Administrator shall have the right to lodge a complaint against the
          processing of their personal data to the supervisory authority, i.e.
          the Data Protection Supervisor and, from the moment the new Personal
          Data Protection Act enters into force, its legal successor, i.e. the
          President of the Personal Data Protection Office whose seat is located
          at ul. Stawki 2, 00-193 Warszawa.
        </p>

        <h4>
          IX. Out-of-court procedures for handling complaints and pursuing
          claims
        </h4>
        <p>
          1. The Consumer may use the out-of-court procedures for handling
          complaints and pursuing claims. The rules of access to these
          procedures are available in the seats or on the websites of entities
          entitled to out-of-court dispute resolution, including in particular
          consumer advocate or Provincial Inspectorates of Trade Inspection
          listed on the website of the Office of Competition and Consumer
          Protection at http://www.uokik.gov.pl/.
        </p>
        <p>
          2. An online platform for dispute resolution between consumers and
          traders at Union level (ODR platform) is available at
          http://ec.europa.eu/consumers/odr. The ODR platform is an interactive
          and multilingual website with a one-stop-shop for consumers and
          traders seeking to resolve out-of-court a dispute concerning
          contractual obligations arising from an online sales or service
          contract.
        </p>

        <h4>X. Final provisions</h4>
        <p>
          1. The Terms of Use are available free of charge at
          https://4fchange.com/ and can be obtained, reproduced, and recorded by
          means of the User's ICT system.
        </p>
        <p>
          In the event that an agreement for the provision of services by
          electronic means through the Website is entered into with the
          Administrator, the Terms of Use are also sent – in a form making it
          possible to obtain, reproduce, and record them by the User – to the
          e-mail address given by the User.
        </p>
        <p>
          2. The provisions of the Terms of Use are not intended to limit or
          exclude any of the Users' rights under the law. The Administrator
          shall respect all rights of the Users provided for in the applicable
          law, and in particular the provisions of the Civil Code of 23 April
          1964 and the Consumer Rights Act of 30 April 2014.
        </p>
        <p>
          3. The Administrator reserves the right to make changes to the Terms
          of Use for important reasons, including:
        </p>
        <ul>
          <li>changes in the law;</li>
          <li>changes due to organisational reasons, including:</li>
          <ul>
            <li>
              changes in the scope of the Administrator's activity, i.e. changes
              in its profile, changes in the scope of services or facilities
              (including those related to entering into and termination of
              contracts),
            </li>
            <li>
              changes in address details, name, or legal form of the
              Administrator,
            </li>
            <li>
              other technical changes related to the functioning of the Website.
            </li>
          </ul>
        </ul>
        <p>
          4. Each User shall be informed of changes to the Terms of Use by
          publication on the pages of the Website.
        </p>
        <p>
          5. The User who uses the Newsletter shall be informed about the change
          of the Terms of Use by way of a notification sent to its e-mail
          address.
        </p>
        <p>
          6. Changes to the Terms of Use shall come into force and apply to the
          User 7 calendar days after the User has been informed about the
          changes in an appropriate manner.
        </p>
        <p>
          7. The Terms of Use shall be effective from 16 January 2023. This
          version of the Terms of Use was published on 16 January 2023.
        </p>
      </div>
    </StyledDescriptionPage>
  </Layout>
)

export default ServiceTerms
