import styled from "styled-components"

const StyledAboutUsPage = styled.div`
  .about-us {
    &__image {
      position: relative;
      margin-bottom: 93px;
      text-align: center;
      .text-label {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 11px 30px;

        @media (max-width: 991px) {
          top: -25px;
        }

        span {
          font-size: 34px;
          line-height: 43px;

          @media (max-width: 991px) {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }
    &__description {
      margin-bottom: 88px;
      .description-header {
        text-align: center;
        margin: 0 auto 73px;
      }
      .description-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
        .box {
          flex-basis: calc(50% - 50px);
          max-width: calc(50% - 50px);
          p {
            margin-bottom: 25px;
            a {
              color: #000;
              text-decoration: underline;
              transition: 0.2s;
              &:hover {
                color: #00fb6e;
              }
            }
          }
        }
      }
      .description-link {
        text-align: right;
      }
    }
    &__details {
      margin-bottom: 145px;
      .details-header {
        max-width: 768px;
        margin: 0 auto 72px;
        text-align: center;
        .text-label {
          padding: 8px 35px;
        }
        .title {
          margin-top: 32px;
        }
      }
      .details-grid {
        display: flex;
        justify-content: space-between;
        .grid-box {
          padding: 22px 22px 35px;
          flex-basis: calc(33.333% - 56px);
          max-width: calc(33.333% - 56px);
          border: 3px solid #000;
          &__header {
            display: flex;
            align-items: center;
            min-height: 68px;
            margin-bottom: 13px;
            .text-holder {
              flex: 1;
              .title {
                font-size: 24px;
                line-height: 32px;
              }
            }
            .icon-holder {
              flex-basis: 50px;
              max-width: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              svg,
              img {
                max-height: 45px;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1350px) {
    .about-us {
      &__details {
        .details-grid {
          .grid-box {
            flex-basis: calc(33.333% - 15px);
            max-width: calc(33.333% - 15px);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1350px) {
    .about-us {
      &__details {
        margin-bottom: 50px;
        .details-grid {
          flex-flow: wrap;
          justify-content: center;
          .grid-box {
            flex-basis: 100%;
            max-width: 600px;
            margin-bottom: 50px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .about-us {
      &__description {
        margin-bottom: 50px;
        .description-header {
          margin: 0 auto 50px;
          position: relative;
        }
        .description-wrapper {
          flex-flow: wrap;
          .box {
            flex-basis: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .about-us {
      &__image {
        margin-bottom: 50px;
        .text-label {
          span {
            font-size: 24px;
            line-height: 31px;
          }
        }
      }
      &__description {
        margin-bottom: 95px;
        .description-header {
          text-align: left;
          margin: 0 auto 30px;
          .title {
            font-size: 24px;
            line-height: 31px;
          }
        }
        .description-wrapper {
          margin-bottom: 30px;
          .box {
            p {
              margin-bottom: 23px;
            }
          }
        }
        .description-link {
          text-align: center;
          a {
            min-width: 100%;
          }
        }
      }
      &__details {
        margin-bottom: 25px;
        .details-header {
          margin: 0 auto 50px;
          text-align: center;
          .title {
            margin-top: 35px;
            font-size: 34px;
            line-height: 43px;
          }
        }
        .details-grid {
          .grid-box {
            flex-basis: 303px;
            max-width: 303px;
          }
        }
      }
    }
  }
`

export default StyledAboutUsPage
