import styled from "styled-components"

const StyledHero = styled.section`
  .hero {
    &__wrapper {
      min-height: 551px;
      background-image: ${`url(${
        require("images/rental/rental-hero-bg.jpg").default
      })`};
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
      padding: 0 0 70px 0;
      overflow: hidden;
    }

    &__label {
      display: inline-flex;
      padding: 1px 17px;
      color: #00fb6e;
      background-color: #000;
      margin: 0 0 10px 0;
      position: relative;
      left: -17px;
    }

    &__header {
      max-width: 230px;

      @media (max-width: 1440px) {
        font-size: 48px;
      }
    }
  }
`

export default StyledHero
