/* eslint-disable react/jsx-no-target-blank */

import StyledDontBuyAdoptSecondEdition from "./styles"

import React from "react"

import Button from "components/Button"
import { useLocale } from "context/locale"

const DontBuyAdoptSecondEdition = () => {
  const { t } = useLocale()

  return (
    <StyledDontBuyAdoptSecondEdition>
      <div className="dont-buy-adopt">
        <p>
          Pasja do projektowania i dbania o czworonogi skłoniła nas do
          stworzenia dla nich wyjątkowych ubranek z upcyklingu. Jednakże nie
          każdemu psiakowi będzie dane cieszyć się własnym domem i ciepłym
          odzieniem podczas zimowych spacerów, dlatego naszym celem jest
          znalezienie nowych, kochających właścicieli dla podopiecznych{" "}
          <strong>
            <a href="https://schroniskopsiepole.pl" target="_blank">
              {t("Schronisko Psie Pole")}
            </a>
          </strong>
          .
        </p>

        <div className="dont-buy-adopt__grid">
          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-second-edition-puchasio.jpg")
                      .default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <h3>Puchasio</h3>
                <p>
                  To prawdziwa iskierka- pełna energii, emocji i miłości do
                  człowieka. Uwielbia wspólnie spędzać czas, jest bardzo
                  kontaktowy i cieszy się z każdego wyjścia na spacer. Uwielbia
                  głaskanie, smakołyki i wszystkie formy uprzejmości w jego
                  stronę. Puchasio ma około 3 lat- jest zatem młodym psiakiem,
                  który ogromnie tęskni za domem i własnym kątem gdzie będzie
                  zaopiekowany i kochany. Ze względu na rozpierającą go
                  (zwłaszcza na początku spaceru) energię chodzenie na smyczy
                  będzie wymagało dodatkowego szkolenia, jednak wierzymy, że
                  przy odpowiedniej dozie ruchu, aktywności oraz cierpliwości
                  nauczy się grzecznie chodzić przy nodze. W stosunku do innych
                  piesków Puchasio zachowuje się różnie- niektóre bezwzględnie
                  toleruje, za innymi nie przepada. W przypadku chęci adopcji do
                  domu gdzie mieszka już psi rezydent polecamy wcześniejsze
                  zapoznanie ich ze sobą.
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/puchasio/"
                      target="_blank"
                    >
                      {t("więcej o")} Puchasiu
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-second-edition-redek.jpg")
                      .default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <h3>Redek</h3>
                <p>
                  <strong>Szufcio</strong> Niewielki młody piesek w wieku około
                  3 lat. W schronisku dał się poznać jako spokojny i bardzo
                  sympatyczny zwierzak- łagodnie podchodzi do każdego (nawet
                  nowo poznanego) człowieka, przepada za spacerami oraz wspólnym
                  spędzaniem czasu. Trafił do schroniska po wypadku
                  komunikacyjnym najpewniej z udziałem samochodu. Był bardzo
                  poobijany i wystraszony- pod opieką weterynaryjną doszedł do
                  siebie i obecnie spędza już czas z innymi psiakami na wybiegu.
                  Redek nie jest konfliktowy w stosunku do innych piesków, koty
                  zdaje się ignorować. Ładnie chodzi na smyczy i zachowuje
                  czystość w swoim kojcu.
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/redek/"
                      target="_blank"
                    >
                      {t("więcej o")} Redku
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-second-edition-sweety.jpg")
                      .default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <h3>Sweety</h3>
                <p>
                  Drobniutka suczka w wieku około 5 miesięcy. Jest bardzo
                  delikatnym, ale jednocześnie sympatycznym i wesołym
                  zwierzęciem. Z lekką dozą nieśmiałości podchodzi do każdej
                  nowopoznanej osoby, jednak prędko ośmiela się i radośnie
                  podskakuje, chcąc zwrócić na siebie uwagę. Bez problemu daje
                  się nosić na rękach i również ochoczo pokazuje brzuszek do
                  głaskania. Powoli opanowuje chodzenie na smyczy- jest jednak
                  młodziutka, zatem przewidujemy, że prędko podszkoli się w tej
                  umiejętności. Rzeczywistość schroniskowa jest dla Sweety
                  bardzo ciężka- czuje się przytłoczona warunkami, a także
                  stęskniona za czułością i uwagą ze strony człowieka.
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/sweety/"
                      target="_blank"
                    >
                      {t("więcej o")} Sweety
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-second-edition-rusalka.jpg")
                      .default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <h3>Rusałka</h3>
                <p>
                  Jest bardzo przyjaźnie nastawioną do ludzi 2-letnią sunią. W
                  schronisku dała się poznać jako zwierzak którego wszędzie
                  pełno- z ogromnym entuzjazmem wita wszystkich każdego ranka w
                  schronisku merdając ogonkiem i zachęcając do podejścia do jej
                  kojca. Jest pieskiem niedużym, waży niespełna 9kg. Uwielbia
                  długie spacery podczas których z zainteresowaniem obwąchuje
                  wszystko dookoła. Dogaduje się z innymi pieskami zatem nie
                  będzie problemem gdy zamieszka w domu gdzie mieszka już psiak
                  rezydent.
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/rusalka/"
                      target="_blank"
                    >
                      {t("więcej o")} Rusałce
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="dont-buy-adopt__ready">
          <h3>
            Czy jesteście gotowi, aby przyjąć jednego z tych wyjątkowych
            podopiecznych do swojego domu?
          </h3>
          <img
            className="img-fluid"
            src={
              require("images/blog/dont-buy-adopt-second-edition-icon.svg")
                .default
            }
            alt=""
          />
        </div>

        <div
          className="dont-buy-adopt__baner"
          style={{
            backgroundImage: `url('${
              require("images/blog/dont-buy-adopt-second-edition-baner-bg.jpg")
                .default
            }')`,
          }}
        >
          <p>
            Czy wiesz, że podpieczni Schroniska Psie Pole, wzięli udział w
            modowej sesji naszego projektu
          </p>
          <h3>
            UPCYCLED JACKETS.
            <br /> DOG APPROVED
          </h3>
          <p>
            Chcesz dowiedzieć się o nim więcej?
            <br /> Zapraszamy.
          </p>
          <Button
            text={t("Learn more")}
            as="link"
            href={t("upcycled_jacket_dog_approved_link")}
          />
        </div>
      </div>
    </StyledDontBuyAdoptSecondEdition>
  )
}

export default DontBuyAdoptSecondEdition
