import StyledWoshGoSub from "./styles"

import React from "react"

import Button from "components/Button"

const WoshGoNubuk = () => {
  const data = [
    {
      icon: require("images/blog/wosh-go-nubuk-ico-1.svg").default,
      description:
        "Część nubukową czyścimy „na sucho” za pomocą specjalnej, gumowej szczotki przeznaczonej do czyszczenia skór szorstkich. Szczotkujemy nubuk w kierunku „pod włos”, aby usunąć brud, który zebrał się między strukturą włosia.",
    },
    {
      icon: require("images/blog/wosh-go-nubuk-ico-2.svg").default,
      description:
        "Po wyszczotkowaniu nakładamy na buty specjalistyczny środek dedykowany skórom szorstkim. Jeśli po czyszczeniu pozostaną plamy, należy zastosować specjalny blok do miejscowego czyszczenia tego rodzaju skór. Przywróci on oryginalne wykończenie oraz fakturę skóry. ",
    },
    {
      icon: require("images/blog/wosh-go-nubuk-ico-3.svg").default,
      description:
        "Jeżeli korzystamy z pianek i cleanerów do skóry szorstkiej, nakładamy i usuwamy je za pomocą suchej bądź lekko wilgotnej ściereczki z mikrofibry. ",
    },
    {
      icon: require("images/blog/wosh-go-nubuk-ico-4.svg").default,
      description:
        "Wyczyszczone obuwie odstawiamy do całkowitego wyschnięcia, a następnie czeszemy buta specjalną szczoteczką.",
    },
    {
      icon: require("images/blog/wosh-go-nubuk-ico-5.svg").default,
      description:
        "Finalnie możemy przystąpić do zabezpieczenia butów. W tym celu polecamy ekologiczny hydrofobowy impregnat w sprayu, rozpylany z odległości ok. 20 cm. Pamiętaj, że lepiej kilkukrotnie wrócić do tego samego miejsca, niż długotrwale nanosić preparat w jednym punkcie, gdyż może to spowodować plamy i zacieki. Impregnat musi się wchłonąć i utrwalić, dlatego buty należy pozostawić na 15-30 minut do pełnego wyschnięcia. Aby zabezpieczyć obuwie przed wilgocią i solą, należy impregnować je co 3 tygodnie. ",
    },
  ]

  return (
    <StyledWoshGoSub>
      <div className="wosh-go-sub-post">
        <div className="wosh-go-sub-post__video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Tx1ito_r8Cw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div className="wosh-go-sub-post__header">
          <img src={require("images/blog/wosh-go-header.svg").default} alt="" />
        </div>

        <div className="wosh-go-sub-post__items">
          {data.map((item, index) => (
            <div className="wosh-go-sub-post__item" key={index}>
              <img src={item.icon} alt="" />
              <p>{item.description}</p>
            </div>
          ))}
        </div>

        <h4>Potrzebujesz nowej pary butów?</h4>
        <p>
          Dowiedz się, jak kupując obuwie 4F uzyskać rabat <br />
          na usługę czyszczenia butów w woshwosh.
        </p>
        <Button
          text="zyskaj rabat"
          theme="current bordered-hover"
          as="link-out"
          href="https://4f.com.pl/landing/4f-change-woshgo"
        />
      </div>
    </StyledWoshGoSub>
  )
}

export default WoshGoNubuk
