import StyledWoshGo from "./styles"

import React, { useState } from "react"
import { Link } from "gatsby"

import Button from "components/Button"
import IcoLink from "icons/link-v2.svg"

const WoshGo = () => {
  const [tab, setTab] = useState(null)

  const data = [
    {
      title: "Jak czyścić obuwie z nubuku?",
      description:
        "Nubuk to obok zamszu i skóry licowej najczęściej wykorzystywane tworzywo do produkcji butów, zarówno tych sportowych, jak i outdoorowych. To naturalna skóra wyprawiona w charakterystyczny sposób, z króciutkim meszkiem, który powoduje, że but jest miły w dotyku, ale jego utrzymanie w czystości jest bardziej wymagające niż skóry licowej. Jak więc dbać o buty z nubuku?",
      image: require("images/blog/wosh-go-post-image-1.jpg").default,
      link: "/aktualnosci/jak-czyscic-obuwie-z-nubuku/",
    },
    {
      title: "Jak dbać o buty w codziennym użytkowaniu?",
      description:
        "Jeżeli chcemy, żeby buty służyły nam jak najdłużej, musimy pamiętać, by pielęgnować je na co dzień. Czyszczenie, impregnacja, pastowanie sprawią, że obuwie nie tylko nie będzie się niszczyło, ale również będzie dobrze wyglądało. Jak w takim razie dbać o buty? ",
      image: require("images/blog/wosh-go-post-image-2.jpg").default,
      link: "/aktualnosci/jak-dbac-o-buty-w-codziennym-uzytkowaniu/",
    },
    {
      title: "Jak dbać o buty sportowe?",
      description:
        "Buty sportowe to te, które często wykorzystujemy najbardziej, a dbamy o nie najmniej, a przecież odpowiednia pielęgnacja może wydłużyć ich życie nawet o parę sezonów. Regularne czyszczenie i dbanie o obuwie, zwłaszcza po treningu w deszczu, błocie i piachu, wpływa na żywotność materiałów i komfort użytkowania.",
      image: require("images/blog/wosh-go-post-image-3.jpg").default,
      link: "/aktualnosci/jak-dbac-o-buty-sportowe/",
    },
  ]

  return (
    <StyledWoshGo>
      <div className="wosh-go-post">
        <p>
          Najbardziej ekologiczna odzież to ta, którą masz już w swojej szafie.
          Dotyczy to również butów, dlatego dbaj o nie tak, by mogły służyć Ci
          przez lata. Pamiętaj, by je czyścić, suszyć i pastować, a w razie
          konieczności naprawiać, zamiast wyrzucać.
        </p>

        <div className="post__excerpt">
          Masz buty, które przeszły naprawdę wiele i sam nie potrafisz im pomóc?
          Zabierz je do{" "}
          <a href="https://woshwosh.com/" target="_blank" rel="noreferrer">
            woshwosh
          </a>{" "}
          - gdzie w zależności od potrzeb zostaną wyczyszczone, odnowione lub
          naprawione. Planeta Ci za to podziękuje, a buty odwdzięczą jeszcze
          niejednym spacerem!
        </div>

        <div className="wosh-go-post__video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/8APsUuQz7PY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <h4>Zobacz, jak samodzielnie dbać o buty.</h4>

        <div className="wosh-go-post__items">
          {data.map((item, index) => (
            <div
              className={`wosh-go-post__item${tab === index ? " current" : ""}`}
              key={index}
            >
              <button onClick={() => setTab(index)}>
                <h5>
                  {index + 1}. {item.title}
                </h5>
              </button>
              {tab === index && (
                <div>
                  <img src={item.image} alt="" />
                  <div>
                    <p>{item.description}</p>
                    <Link to={item.link}>
                      czytaj całość <IcoLink />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <h4>Potrzebujesz nowej pary butów?</h4>
        <p>
          Dowiedz się, jak kupując obuwie 4F uzyskać rabat <br />
          na usługę czyszczenia butów w woshwosh.
        </p>
        <Button
          text="zyskaj rabat"
          theme="current bordered-hover"
          as="link-out"
          href="https://4f.com.pl/landing/4f-change-woshgo"
        />
      </div>
    </StyledWoshGo>
  )
}

export default WoshGo
