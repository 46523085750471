import React from "react"
import { Formik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import * as Yup from "yup"
import { useLocale } from "context/locale"
import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Textarea from "components/Textarea"
import Button from "components/Button"

const ContactForm = () => {
  const { t } = useLocale()
  const formSubmit = async ({ name, email, message, legal }) => {
    try {
      let formData = new FormData()

      formData.set("fullName", name)
      formData.set("email", email)
      formData.set("message", message)
      formData.set("legal", legal)
      await axios({
        method: "post",
        url: "https://4f-change.zenx.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      navigate(t("thanks_link"))
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
        legal: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Invalid email"))
          .required(t("E-mail is required")),
        name: Yup.string().required(t("Name & surname is required")),
        message: Yup.string().required(t("Message is required")),
        legal: Yup.bool().oneOf([true], t("Required field")),
      })}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        formSubmit(values)
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form className="box-form" noValidate onSubmit={handleSubmit}>
          <div className="form-row">
            <Input type="text" label={t("Name & Surname")} name="name" />
            <Input type="email" label={t("E-mail")} name="email" />
          </div>
          <Textarea label={t("Message")} name="message" />
          <Checkbox
            name="legal"
            label={t(
              "I accept the <a href='/en/service-rules/' target='_blank'>website regulations</a> and consent to the processing of personal data in accordance with GDPR."
            )}
          />
          <div className="form-submit">
            <Button
              text={t("Send")}
              type="submit"
              variant="next"
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ContactForm
