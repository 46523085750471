import styled from "styled-components"

const StyledTextarea = styled.div`
  position: relative;
  padding-top: 23px;
  .inp-label {
    top: 0px;
    letter-spacing: 1.6px;
    position: absolute;
    font-size: ${({ focused }) => (focused ? "12px" : "16px")};
    line-height: ${({ focused }) => (focused ? "16px" : "21px")};
    text-transform: uppercase;
    transition: 0.2s;
    color: ${({ theme }) => (theme === "white" ? "#fff" : `#000`)};
  }
  .inp-error {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1.4px;
    font-weight: 500;
    color: #fc0303;
  }
  textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid
      ${({ theme }) => (theme === "white" ? "#fff" : `#000`)};
    background-color: transparent;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    outline: none;
    color: ${({ theme }) => (theme === "white" ? "#fff" : `#000`)};
    min-height: 145px;
    resize: none;
  }
`

export default StyledTextarea
