import React from "react"
import OrderProvider from "context/order"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Instruction from "page_components/order/Instruction"
import Form from "page_components/order/Form"

const OrderCourier = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Zamów kuriera - 4F Change",
      description:
        "Spakuj swoje ubrania w karton i zamów bezpłatnego kuriera, który je od Ciebie odbierze. Dołącz do zmiany i razem z nami zadbaj o środowisko",
    }}
  >
    <PageHeader title="Zamów kuriera" />
    <OrderProvider>
      <Instruction />
      <Form />
    </OrderProvider>
  </Layout>
)

export default OrderCourier
