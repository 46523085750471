import styled from "styled-components"

const StyledDescriptionPage = styled.section`
  padding: 0 15px 150px 15px;
  max-width: 895px;
  margin: 0 auto;

  @media (max-width: 575px) {
    padding: 0 15px 75px 15px;
  }

  .description-page {
    &__item {
      &:not(:first-child) {
        margin: 60px 0 0 0;
      }
    }
  }

  h4 {
    margin: 60px 0 35px 0;

    &:first-child {
      margin: 0 0 35px 0;
    }
  }

  p {
    padding: 0 0 0 25px;

    @media (max-width: 575px) {
      padding: 0;
    }

    &:not(:last-child) {
      margin: 0 0 25px 0;
    }
  }

  ul,
  ol {
    list-style-type: auto;
    padding: 0 0 0 45px;

    ul,
    ol {
      padding: 0 0 0 20px;
    }

    @media (max-width: 575px) {
      padding: 0 0 0 20px;
    }

    &:not(:last-child) {
      margin: 0 0 25px 0;
    }
  }

  ul {
    list-style-type: disc;
  }
  a {
    text-decoration: underline;
    transition: 0.2s;
    &:hover {
      color: #00fb6e;
    }
  }
`

export default StyledDescriptionPage
