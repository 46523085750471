import React from "react"
import { useLocale } from "context/locale"

import Button from "components/Button"

import StyledFilters from "./styles"

const Filters = ({ categories, query, setQuery, slug, type }) => {
  const { t } = useLocale()
  return (
    <StyledFilters>
      <div className="main-container">
        <div className="filters__categories">
          <Button
            className={!!!type ? "current" : null}
            href={t("news_link")}
            text={t("All")}
            as="link"
            state={{ prevent_scroll: true }}
          />
          {categories?.nodes
            .filter(item => item?.posts?.nodes?.length > 0)
            .map((item, index) => (
              <Button
                className={item.slug === slug ? "current" : null}
                href={`${t("news_link")}${item?.slug}/`}
                state={{ prevent_scroll: true }}
                key={index}
                text={item?.name}
                as="link"
              />
            ))}
        </div>
        <form className="filters__search">
          <input
            className="filters__search-input"
            type="text"
            name="search"
            placeholder={`${t("search")} ...`}
            value={query}
            onChange={({ target: { value } }) => setQuery(value)}
          />
        </form>
      </div>
    </StyledFilters>
  )
}

export default Filters
