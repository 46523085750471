import styled from "styled-components"

const StyledContactPage = styled.div`
  padding: 10px 0 260px;
  .contact {
    &__image {
      text-align: center;
      margin-bottom: 80px;
    }
    &__form {
      display: flex;
      padding-bottom: 108px;
      .box-text {
        flex-basis: 28%;
      }
      .box-form {
        flex: 1;
        padding-left: 140px;
        .form-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 35px;
          & > div {
            flex-basis: calc(50% - 15px);
            max-width: calc(50% - 15px);
          }
        }
        .form-submit {
          padding-top: 40px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    &__details {
      display: flex;
      .contact-box {
        flex-basis: 200px;
        max-width: 200px;
        &.lg {
          flex-basis: 240px;
          max-width: 240px;
        }
        &:not(:last-child) {
          margin-right: 142px;
        }

        strong {
          display: flex;
          margin: 0 0 20px 0;
        }

        p {
          &:last-child {
            margin: 0 0 20px 0;
          }
        }

        a {
          transition: 0.2s;
          display: flex;
          font-weight: 700;

          &:hover {
            color: #00fb6e;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 10px 0 150px;
    .contact {
      &__image {
        margin-bottom: 50px;
      }
      &__form {
        flex-flow: wrap;
        .box-text {
          flex-basis: 100%;
          max-width: 100%;
          margin-bottom: 50px;
        }
        .box-form {
          flex-basis: 100%;
          max-width: 100%;
          padding-left: 0;
        }
      }
      &__details {
        flex-flow: wrap;
        .contact-box {
          flex-basis: 50%;
          max-width: 50%;
          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 5px 0 52px;
    .contact {
      &__image {
        text-align: center;
        margin-bottom: 37px;
      }
      &__form {
        display: flex;
        padding-bottom: 47px;
        .box-text {
          margin-bottom: 55px;
        }
        .box-form {
          .form-row {
            flex-flow: wrap;
            margin-bottom: 0;
            & > div {
              flex-basis: 100%;
              max-width: 100%;
              margin-bottom: 45px;
            }
          }
          textarea {
            min-height: 175px;
          }
          .form-submit {
            padding-top: 40px;
            text-align: center;
            button {
              min-width: 100%;
            }
          }
        }
      }
      &__details {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .contact-box {
          flex-basis: 100%;
          max-width: 100%;
          text-align: center;
          &:not(:last-child) {
            margin-bottom: 50px;
          }
          .title {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`

export default StyledContactPage
