import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Hero from "page_components/blog/Hero"
import Filters from "page_components/blog/Filters"
import Grid from "page_components/blog/Grid"

const Blog = ({ data, location }) => {
  const [query, setQuery] = useState("")

  const blogData = {
    query,
    setQuery,
  }

  return (
    <Layout
      location={location}
      seo={{
        title: "News – 4F Change",
        description:
          "Stay up to date. Follow our activities in the implementation of the 4F Change strategy and take care of the environment together with us.",
      }}
    >
      <Hero post={data?.allPost?.nodes[0]} />
      <Filters categories={data?.allCategory} {...blogData} />
      <Grid posts={data?.allPost?.nodes} {...blogData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allPost: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: EN } } }
    ) {
      nodes {
        id
        slug
        date
        title
        excerpt
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allCategory: allWpCategory(
      filter: { name: { ne: "Uncategorized" }, language: { code: { eq: EN } } }
    ) {
      nodes {
        slug
        name
        posts {
          nodes {
            id
          }
        }
      }
    }
  }
`

export default Blog
