import styled from "styled-components"

const StyledDontPickHoles = styled.div`
  .dont-pick-holes-content {
    img {
      max-width: 100%;
      height: auto;
    }

    p {
      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &__image {
      position: relative;
      margin: 60px 0 160px 0;

      img {
        position: relative;
        left: -110px;

        @media (max-width: 1365px) {
          left: 0;
        }
      }

      @media (max-width: 1365px) {
        margin: 25px 0 45px 0;
      }
    }

    &__text-wrapper {
      border: 5px solid #00fb6e;
      background-color: #fff;
      padding: 30px 40px;
      max-width: 444px;
      position: absolute;
      top: 60px;
      right: -110px;

      @media (max-width: 1365px) {
        position: relative;
        top: 0;
        right: 0;
        max-width: 100%;
        margin: 45px 0 0 0;
        padding: 25px;
      }
    }

    .slick-slider {
      width: calc(100% + 500px);
      left: -250px;
      position: relative;

      .slick-slide {
        padding: 0 15px;
      }
    }

    &__slider-nav {
      display: flex;
      align-content: center;
      justify-content: center;
      margin: 15px 0 50px 0;

      div {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 30px;

        span {
          display: flex;
          width: 24px;
          height: 1px;
          background-color: #000;
          margin: 0 10px;
        }
      }

      button {
        width: 45px;
        height: 45px;
        border: 1px solid #000;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 150ms linear;

        svg {
          position: relative;
          right: -1px;

          path {
            transition: all 150ms linear;
          }
        }

        &:first-child {
          svg {
            transform: rotate(180deg);
            right: 1px;
          }
        }

        &:hover {
          border-color: #00fb6e;

          svg {
            path {
              stroke: #00fb6e;
            }
          }
        }
      }
    }
  }
`

export default StyledDontPickHoles
