import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import ChangeMakers from "components/ChangeMakers"
import PointsList from "page_components/join/PointsList"
import Grid from "page_components/join/Grid"
import PointsProvider from "context/points"

import StyledJoinUsPage from "styles/JoinUsStyles"

const JoinUsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      acf: wpPage(databaseId: { eq: 481 }) {
        databaseId
        title
        acfJoin {
          joinContent {
            joinContentBackground {
              localFile {
                publicURL
              }
            }
            joinContentBackgroundMobile {
              localFile {
                publicURL
              }
            }
            joinContentIcon {
              localFile {
                publicURL
              }
            }
            joinContentHeader
            joinContentDescription
            joinContentDescriptionIcon {
              localFile {
                publicURL
              }
            }
            joinContentLinkPath
          }
          mapPoints {
            mapPointName
            mapPointCity
            mapPointStreet
            mapPointOpeningDays
            mapPointOpeningHours
            mapPointOptions
            mapPointLatLng {
              latitude
              longitude
            }
          }
        }
      }
    }
  `)

  const points_data_cms = data?.acf?.acfJoin?.mapPoints?.map(item => ({
    name: item.mapPointName,
    city: item.mapPointCity,
    street: item.mapPointStreet,
    opening_days: item.mapPointOpeningDays,
    opening_hours: item.mapPointOpeningHours,
    options: item.mapPointOptions,
    lat: item.mapPointLatLng.latitude,
    lng: item.mapPointLatLng.longitude,
  }))
  return (
    <Layout
      location={location}
      seo={{
        title: "Join the change! - 4F Change",
        description:
          "Donate unwanted or used clothing. Rent instead of buy or buy environmentally friendly clothing.",
      }}
    >
      <PageHeader title={data?.acf?.title} />
      <StyledJoinUsPage>
        <div className="main-container">
          <PointsProvider location={location} points_data={points_data_cms}>
            <Grid />
            <PointsList />
          </PointsProvider>
        </div>
        <ChangeMakers />
      </StyledJoinUsPage>
    </Layout>
  )
}

export default JoinUsPage
