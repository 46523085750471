import styled from "styled-components"

const StyledFixClothes = styled.div`
  .fix-clothes-post {
    p {
      a {
        font-weight: 700;
        text-decoration: none !important;
        position: relative;

        &:after {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #00fb6e;
        }
      }
    }

    h3 {
      text-align: center;
    }

    &__table {
      margin: 0 0 65px 0;
      border-bottom: 2px solid #00fb6e;

      @media (max-width: 575px) {
        margin: 0 0 50px 0;
      }

      &-header {
        background-color: #00fb6e;
        font-weight: 700;
        padding: 0 10px;
        display: flex;

        @media (max-width: 1500px) {
          font-size: 14px;
          padding: 0;
        }

        .col {
          flex: 0 0 auto;
          padding: 15px 10px;

          @media (max-width: 575px) {
            padding: 10px 5px;
          }

          &:nth-child(1) {
            width: 50%;

            @media (max-width: 575px) {
              width: 40%;
            }
          }

          &:nth-child(2) {
            width: 20%;
            text-align: right;

            @media (max-width: 575px) {
              width: 30%;
            }
          }

          &:nth-child(3) {
            width: 30%;
          }
        }
      }

      &-row {
        padding: 0 10px;
        display: flex;
        font-size: 14px;

        @media (max-width: 1500px) {
          font-size: 12px;
          padding: 0;
        }

        &:nth-child(odd) {
          background-color: #e9e9e9;
        }

        .col {
          flex: 0 0 auto;
          padding: 15px 10px;

          @media (max-width: 575px) {
            padding: 10px 5px;
          }

          &:nth-child(1) {
            width: 50%;

            @media (max-width: 575px) {
              width: 40%;
            }
          }

          &:nth-child(2) {
            font-weight: 700;
            width: 20%;
            text-align: right;

            @media (max-width: 575px) {
              width: 30%;
            }
          }

          &:nth-child(3) {
            width: 30%;
          }
        }
      }
    }

    &__rules {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }

      img {
        margin: 0 0 30px 0;
      }

      small {
        display: block;
        margin: 15px 0 0 0;
      }
    }
  }
`

export default StyledFixClothes
