import StyledDontPickHoles from "./styles"

import React, { useRef, useState } from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

import IcoNext from "icons/slick-next-arrow.svg"

const Viamoda = ({ slug }) => {
  const ref = useRef(null)
  const { t } = useLocale()

  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: false,
    infinite: false,
    swipe: true,
    arrows: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    ref: ref,
    afterChange: function (index) {
      setCurrentSlide(index)
    },
  }

  const slides = [
    require("images/blog/dont-pick-holes-slide-image-1.jpg").default,
    require("images/blog/dont-pick-holes-slide-image-2.jpg").default,
    require("images/blog/dont-pick-holes-slide-image-3.jpg").default,
    require("images/blog/dont-pick-holes-slide-image-4.jpg").default,
    require("images/blog/dont-pick-holes-slide-image-5.jpg").default,
    require("images/blog/dont-pick-holes-slide-image-6.jpg").default,
    require("images/blog/dont-pick-holes-slide-image-7.jpg").default,
  ]

  return (
    <StyledDontPickHoles>
      <div className="dont-pick-holes-content">
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Już od dłuższego czasu w strefach Wear_Fair dostępne są ubrania, które dostały drugie życie. Oznaczyliśmy je metkami <strong>RENEWED</strong>, <strong>REVIVED</strong>,<strong>REPAIRED</strong> oraz <strong>UPCYCLED</strong>. Zdajemy sobie sprawę, że niektórzy nasi klienci mogą mieć wątpliwości, czy jest sens kupować ubrania, które z jakiegoś powodu były wadliwe, zniszczone, miały dziury albo rozdarcia. Naszymi działaniami „Nie szukaj dziury w całym” chcemy pokazać, że takie ubrania są nie tylko pełnowartościowe, ale czasem naprawa dodaje im wręcz atrakcyjności. Reperując ubrania, możemy sprawić, że nabiorą one wyjątkowego charakteru, który odda nasz własny, niepowtarzalny styl."
            ),
          }}
        />

        <div className="dont-pick-holes-content__image">
          <img
            src={
              require("images/blog/dont-pick-holes-content-image.jpg").default
            }
            alt=""
          />

          <div className="dont-pick-holes-content__text-wrapper">
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "Po raz pierwszy ubrania z kategorii <strong>REPAIRED</strong> mają teraz <strong>WIDOCZNE</strong> naprawy, np. łatę albo nawet cały rękaw w innym kolorze. Co ważne, wszystkie materiały wykorzystane do reperacji również pochodzą z drugiego obiegu. Odzież ta została opatrzona dodatkową, wszytą na stałe metką z przesłaniem akcji: „<strong>NIE SZUKAJ DZIURY W CAŁYM!</strong> Już ją znaleźliśmy i naprawiliśmy. To ubranie było zniszczone, ale zostało naprawione i odświeżone, by mogło cieszyć się drugim życiem. Do jego naprawy wykorzystaliśmy elementy z innych zepsutych ubrań. Możesz je nosić z dumą, bo nie ma drugiego takiego! Witamy w gronie Changemakerów!”."
                ),
              }}
            />
          </div>
        </div>

        <Slider ref={ref} {...settings}>
          {slides?.map((item, index) => (
            <div key={index}>
              <img src={item} alt="" />
            </div>
          ))}
        </Slider>
        <div className="dont-pick-holes-content__slider-nav">
          <button onClick={() => ref?.current?.slickPrev()}>
            <IcoNext />
          </button>
          <div>
            {currentSlide + 1}
            <span />7
          </div>
          <button onClick={() => ref?.current?.slickNext()}>
            <IcoNext />
          </button>
        </div>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Działania tradycyjnie już przeprowadzimy wraz z firmą Ubrania do Oddania. UDO zajmie się odświeżeniem, wyczyszczeniem i naprawą odzieży, którą będzie można kupić w strefach Wear_Fair w dwóch sklepach stacjonarnych 4F: w Arkadii (Warszawa) i w Bonarce (Kraków)."
            ),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Cały projekt wesprą Ola Kubiak – wytrawna poszukiwaczka lumpeksowych perełek (znajdziecie ją na IG jako @ola.kubiak) oraz Zuzanna Krzątała, podcasterka i sustainability manager w Vogue Polska (IG: @zubizubizu). W salonach pojawi się 250 sztuk ubrań, więc od 17 października szukajcie wśród odzieży z handtagami REPAIRED tej z dodatkową metką <strong>NIE SZUKAJ DZIURY W CAŁYM</strong>."
            ),
          }}
        />
        {slug === "nie-szukaj-dziury-w-calym" && (
          <>
            <p>
              Jeśli masz w domu uszkodzone ubrania 4F pamiętaj, że możesz je
              oddać w naszych salonach w strefach Wear_Fair, wysłać bezpłatnie
              kurierem, wrzucić do ubraniomatu lub do pudełek przy kasie.
              Pamiętaj także, że możesz zlecić usługę naprawy, która od 5
              czerwca 2023 jest dostępna w każdym sklepie 4F. Sprzedawca oceni,
              czy naprawa jest możliwa do zrealizowania i przyjmie zlecenie,
              informując Cię o cenie i terminie odbioru.
            </p>
            <p>
              Szczegóły znajdziecie na stronie:{" "}
              <Link to="/aktualnosci/naprawiaj-ubrania/">
                Naprawiaj, zamiast wyrzucać! - 4F Change
              </Link>
            </p>
            <p>
              A jeżeli chcecie poczytać więcej o tym, dlaczego warto naprawiać
              ubrania, zapraszamy do naszego wpisu, który znajdziecie{" "}
              <a href="/aktualnosci/dlaczego-warto-naprawiac-ubrania/">tutaj</a>
              .
            </p>
          </>
        )}
      </div>
    </StyledDontPickHoles>
  )
}

export default Viamoda
