import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useLocale } from "context/locale"

import StyledGrid from "./styles"

const grid_items_count = 12

const Grid = ({ posts, query }) => {
  const { t } = useLocale()

  const [listCount, setListCount] = useState(6)

  const filtered_posts = posts.filter(
    ({ title }) => title.includes(query) || title.toLowerCase().includes(query)
  )
  const grid_posts = filtered_posts.slice(0, grid_items_count)
  const list_posts = filtered_posts.slice(grid_items_count)

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setListCount(1)
    } else {
      setListCount(6)
    }
  }

  useEffect(() => {
    handleResize()
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  return (
    <StyledGrid>
      <div className="main-container">
        {posts.length > 0 ? (
          <>
            {grid_posts?.length > 0 && (
              <div className="grid__row">
                {grid_posts.map((item, index) => (
                  <div className="grid__col" key={index}>
                    <Link
                      to={`${t("news_link")}${item.slug}/`}
                      className="grid__item"
                    >
                      <div
                        className="item-bg"
                        style={{
                          backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
                        }}
                      />
                      <div className="item-content">
                        <time className="item-date" dateTime={item?.date}>
                          {item?.date
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join(".")}
                        </time>
                        <div className="item-desc">
                          <span className="item-label">
                            {item?.categories?.nodes[0]?.name}
                          </span>
                          <h4 className="item-title">{item?.title}</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}
            {list_posts?.length > 0 && (
              <div className="posts-list">
                {list_posts.slice(0, listCount).map(item => (
                  <Link
                    to={`${t("news_link")}${item.slug}/`}
                    className="posts-list__item"
                    key={item.id}
                  >
                    <div
                      className="item-bg"
                      style={{
                        backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
                      }}
                    />
                    <div className="item-content">
                      <time className="item-date" dateTime={item?.date}>
                        {item?.date
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join(".")}
                      </time>
                      <div className="item-desc">
                        <span className="item-label">
                          {item?.categories?.nodes[0]?.name}
                        </span>
                        <h4 className="item-title">{item?.title}</h4>
                      </div>
                    </div>
                  </Link>
                ))}
                {listCount < list_posts?.length && (
                  <div className="posts-list__more">
                    <button onClick={() => setListCount(listCount + 6)}>
                      wczytaj więcej
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="empty-box">
            <p>{t("We did not find what you are looking for")} :(</p>
          </div>
        )}
      </div>
    </StyledGrid>
  )
}

export default Grid
