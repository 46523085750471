import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const ServiceTerms = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Regulamin serwisu - 4F Change",
      description:
        "Niniejszy regulamin określa zasady korzystania z serwisu internetowego znajdującego się pod adresem https://www.4Fchange.com.",
    }}
  >
    <PageHeader title="Regulamin serwisu 4f Change" />

    <StyledDescriptionPage>
      <div className="description-page">
        <h4>DEFINICJE</h4>
        <p>
          <strong>1. Administrator</strong> - OTCF S.A. z siedzibą w Krakowie
          (ul. Saska 25C, 30-720 Kraków), zarejestrowana przez Sąd Rejonowy dla
          Krakowa - Śródmieścia w Krakowie, Wydział XI Gospodarczy Krajowego
          Rejestru Sądowego po numerem KRS: 0000555276, NIP: PL9451978451,
          REGON: 356630870, wysokość kapitału zakładowego 7.384.500 zł (opłacony
          w całości), numer rejestrowy BDO: 000005025, adres poczty
          elektronicznej info@4fchange.com
        </p>
        <p>
          <strong>2. Serwis</strong> – serwis 4F CHANGE dostępny pod adresem
          https://4fchange.com/ prowadzony przez Administratora będący platformą
          informacyjną na temat strategii środowiskowej OTCF S.A. z siedzibą w
          Krakowie, za pośrednictwem której Administrator: udostępnia
          funkcjonalności Serwisu oraz świadczy na rzecz Użytkowników usługi,
          jak również za pośrednictwem której Użytkownicy mają możliwość
          zapoznania się z usługami Administratora. Administrator za
          pośrednictwem Serwisu udostępnia odpowiednie narzędzia systemowe,
          teleinformatyczne, technologiczne w celu świadczenia ww. usług.
        </p>
        <p>
          <strong>3. Strona Internetowa Serwisu</strong> - strona internetowa
          dostępna pod adresem https://4fchange.com/
        </p>
        <p>
          <strong>4. Regulamin</strong> - niniejszy Regulamin, określający
          zasady korzystania z Serwisu, w szczególności zasady zawierania za
          pośrednictwem Serwisu umów o świadczenie usług drogą elektroniczną,
          zasady wykonywania tych umów oraz zasady postępowania reklamacyjnego.
        </p>
        <p>
          <strong>5. Użytkownik</strong> - korzystająca z funkcjonalności
          Serwisu osoba fizyczna, osoba prawna lub jednostka organizacyjna
          nieposiadająca osobowości prawnej.
        </p>
        <p>
          <strong>6. Konsument</strong> - Użytkownik będący osobą fizyczną
          dokonującą ze Administratorem czynności prawnej niezwiązanej
          bezpośrednio z jej działalnością gospodarczą lub zawodową.
        </p>
        <p>
          <strong>7. Usługi</strong> – dostępne w serwisie Usługi świadczone
          drogą elektroniczną mogące być przedmiotem umowy pomiędzy
          Użytkownikiem a Administratorem.
        </p>
        <p>
          <strong>8. RODO </strong>- Rozporządzenie Parlamentu Europejskiego i
          Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób
          fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        </p>
        <p>
          <strong>9. Newsletter</strong> - udostępniana przez Administratora
          usługa polegająca na przesyłaniu informacji handlowej w szczególności
          dotyczącej oferty produktów i świadczonych usług w formie wiadomości
          (maili) za pośrednictwem poczty elektronicznej na podany przez
          Użytkownika adres poczty elektronicznej.
        </p>

        <h4>I. Postanowienia wstępne</h4>
        <p>
          1. Administrator stosuje środki techniczne i organizacyjne odpowiednie
          do stopnia zagrożenia, w tym środki służące zapobieganiu pozyskania i
          modyfikacji przez osoby nieuprawnione danych osobowych przesyłanych
          przez Internet – w celu zapewnienia bezpieczeństwa komunikatów i
          danych przekazywanych w Serwisie. Administrator zapewnia
          bezpieczeństwo transmisji danych przekazywanych w ramach Serwisu
          poprzez stosowanie protokołu SSL (SecureSocketLayer).
        </p>
        <p>
          2. Administrator udostępnia funkcjonalności Serwisu z wykorzystaniem
          sieci Internet.
        </p>
        <p>
          3. Użytkownik obowiązany jest do korzystania z Serwisu w sposób
          niezakłócający jego funkcjonowania, zgodny z prawem, postanowieniami
          Regulaminu, dobrymi obyczajami oraz z poszanowaniem praw i dóbr
          osobistych innych podmiotów. Zakazane jest dostarczanie przez
          Użytkownika treści o charakterze bezprawnym.
        </p>

        <h4>II. Sposoby porozumiewania się</h4>
        <p>
          1. Bezpośrednio przez stronę internetową Serwisu możliwe jest
          składanie przez Użytkowników zapytań adresowanych do Administratora. W
          tym celu należy na stronie internetowej Serwisu wybrać formularz
          kontaktowy i wpisać wymagane dane umożliwiające Administratorowi
          udzielenie odpowiedzi w określonych polach formularza zapytania, oraz
          zaznaczyć wymagane pola lub przesłać zapytanie na adres e-mail:
          info@4fchange.com.
        </p>
        <p>
          2. Administrator w celu udzielenia odpowiedzi w przedmiocie złożonego
          zapytania może kontaktować się z Użytkownikiem za pomocą poczty
          elektronicznej lub telefonicznie - przy czym telefonicznie jedynie w
          razie podania numeru telefonu kontaktowego przez Użytkownika.
        </p>

        <h4>III. Zamów kuriera</h4>
        <p>
          1. Administrator udostępnia Użytkownikowi za pośrednictwem Strony
          Internetowej Serwisu formularz elektroniczny „Zamów Kuriera”, który
          pozwalana na podanie przez Użytkownika spółce New Solutions Group Sp.
          z o.o. – z siedzibą w Warszawie, ul. Prosta 70, wymaganych danych
          Użytkownika (imienia, nazwiska, adresu e-mail, adresu, numeru
          telefonu), który zamierza przekazać za pośrednictwem przesyłki
          kurierskiej darowiznę za pośrednictwem Platformy fundraisingowej
          „Ubrania do oddania” (www.ubraniadooddania.pl). Przedmiotowy formularz
          pozwala również na złożenie przez Użytkownika oświadczeń woli
          bezpośrednio wobec New Solutions Group Sp. z o.o., między innymi w
          przedmiocie założenia konta w portalu www.ubraniadooddania.pl, a także
          akceptacji Regulaminu korzystania z portalu lub innych dokumentów
          udostępnionych w formularzu przez New Solutions Group Sp. z o.o.
        </p>
        <p>
          2. Administrator nie ponosi odpowiedzialności za działania New
          Solutions Group Sp. z o.o., w tym w szczególności w zakresie dalszego
          kontaktu z Użytkownikiem, przetwarzania jego danych osobowych
          udostępnionych za pośrednictwem formularza, a także funkcjonowania
          portalu www.ubraniadooddania.pl. Administrator w ramach Serwisu
          udostępnia wyłącznie formularz elektroniczny (jako funkcjonalność
          Serwisu), przy czym nie zbiera i nie przechowuje danych osobowych
          wprowadzanych przez Użytkownika do formularza (dane te są pozyskiwane
          bezpośrednio przez New Solutions Group Sp. z o.o. jako odrębnego
          administratora danych w rozumieniu przepisów RODO).
        </p>

        <h4>IV. Newsletter</h4>
        <p>
          1. W ramach usługi Newsletter, za pośrednictwem poczty elektronicznej,
          na podany przez Użytkownika adres poczty elektronicznej (adres
          e-mail), wysyłana jest przez Administratora informacja w formie
          wiadomości e-mail, zwana dalej „Newsletterem”. Usługa Newsletter
          świadczona jest nieodpłatnie przez czas nieoznaczony.
        </p>
        <p>
          2. Newsletter zawiera informacje o strategii 4F Change i programie
          Wear_Fair, a także o ofercie produktów i usług marki 4F, nowych
          kolekcjach wyprodukowanych w sposób zrównoważony, aktualnych
          promocjach, informacje o otwarciu nowych stref Wear_Fair w salonach
          oraz inne wiadomości dotyczące marki 4F i strategii środowiskowej oraz
          oferowanych przez nią produktów, w tym opinie, materiały prasowe,
          linki do stron zaprzyjaźnionych z marką 4F.
        </p>
        <p>3. Każdy Newsletter zawiera:</p>
        <ul>
          <li>informację o Administratorze jako nadawcy Newslettera,</li>
          <li>wypełnione pole „Temat” określające treść Newslettera,</li>
          <li>
            informację o sposobie rezygnacji z usługi Newsletter lub zmianie
            adresu poczty elektronicznej (adresu e-mail) Użytkownika, na który
            wysyłany jest Newsletter.
          </li>
        </ul>
        <p>
          4. Korzystanie z usługi Newsletter jest uzależnione od posiadania
          przez Użytkownika komputera lub innego urządzenia multimedialnego z
          dostępem do sieci Internet oraz od posiadania przez Użytkownika
          aktywnego konta poczty elektronicznej (e-mail).
        </p>
        <p>
          5. Zamówienie usługi Newsletter następuje poprzez dokonanie przez
          Użytkownika następujących czynności rejestracyjnych na stronie
          internetowej https://4fchange.com w polu „Dołącz do zmiany Zapisz się
          na newsletter i bądź na bieżąco z naszymi akcjami”:
        </p>
        <ul>
          <li>
            podanie adresu poczty elektronicznej (adresu e-mail) Użytkownika w
            formularzu zamieszczonym na stronie internetowej,
          </li>
          <li>
            wyrażenie zgody na otrzymywanie wiadomości typu Newsletter i
            zaznaczenie check-box'a oznaczającego potwierdzenie zapoznania się z
            Regulaminem Serwisu i akceptację jego treści,
          </li>
          <li>naciśnięcie (kliknięcie) na przycisk „Dołącz”,</li>
          <li>
            kliknięcie linku potwierdzającego rejestrację zamieszczonego w
            przesłanej wiadomości elektronicznej o tytule „Potwierdzenie
            subskrypcji newslettera ze strony”.
          </li>
        </ul>
        <p>
          6. Naciśnięcie (kliknięcie) na link potwierdzający rejestrację
          powoduje dodanie adresu poczty elektronicznej Użytkownika (adresu
          e-mail) do listy e-mailingowej. Adres poczty elektronicznej
          Użytkownika (adres e-mail) będzie wykorzystywany w celu wysyłania
          Użytkownikowi Newsletterów.
        </p>
        <p>
          7. Podanie w formularzu, podczas czynności rejestracyjnych, adresu
          poczty elektronicznej (adresu e-mail) Użytkownika jest niezbędne do
          świadczenia usługi Newsletter.
        </p>
        <p>
          8. Użytkownik może w każdej chwili, bez podania przyczyny i bez
          ponoszenia kosztów, zmienić wskazany uprzednio adres poczty
          elektronicznej (adres e-mail), na który wysyłany jest Newsletter,
          rezygnując z dotychczas świadczonej usługi Newsletter poprzez
          naciśnięcie (kliknięcie) na link „Anulowanie subskrypcji Newslettera”
          umieszczony w stopce każdego Newslettera, a następnie ponowne
          zamówienie usługi Newsletter poprzez dokonanie przez Użytkownika
          czynności rejestracyjnych w sposób szczegółowo wskazany w ust. 6
          powyżej, wskazując nowy adres poczty elektronicznej (adres e-mail)
          Użytkownika w formularzu zamieszczonym na stronie internetowej
          https://4fchange.com w polu „NEWSLETTER”.
        </p>
        <p>
          9. Użytkownik może w każdej chwili, bez podania przyczyny i bez
          ponoszenia kosztów zrezygnować z usługi Newsletter w szczególności
          poprzez:
        </p>
        <ul>
          <li>
            kliknięcie na link „Anulowanie subskrypcji Newslettera” umieszczony
            w stopce każdego Newslettera,
          </li>
          <li>
            odznaczenie zgody „Wyrażam zgodę na otrzymywanie na podany przeze
            mnie adres e-mail korespondencji handlowej w postaci NEWSLETTERA na
            zasadach określonych w Regulaminie sklepu internetowego. Przysługuje
            mi prawo cofnięcia zgody na przetwarzanie danych w każdym czasie, co
            nie będzie jednak miało wpływu na zgodność przetwarzania z prawem,
            realizowanego przed cofnięciem zgody” w Panelu Użytkownika, a
            następnie naciśnięcie (kliknięcie) na przycisk „Zapisz zmiany”,
          </li>
          <li>
            przesłanie OTCF za pośrednictwem poczty elektronicznej (adres e-
            mail): info@4fchange.com oświadczenia woli o rezygnacji z usługi
            Newsletter.
          </li>
        </ul>
        <p>
          10. W ramach korzystania z usługi Newsletter Użytkownik zobowiązany
          jest do niedostarczania i nieprzekazywania treści o charakterze
          bezprawnym.
        </p>
        <h4>
          V. Inne Formularze oraz postanowienia łączne dotyczące formularzy
        </h4>
        <p>
          1. Administrator może udostępnić Użytkownikowi za pośrednictwem Strony
          Internetowej Serwisu również inne formularze, które mogą w
          szczególności służyć do polecania przez Użytkownika innym użytkownikom
          Internetu usług oferowanych w Serwisie lub Serwisu, a także do wzięcia
          przez Użytkowników udziału w konkursach oraz promocjach organizowanych
          przez Administratora.
        </p>
        <p>
          2. Zawarcie umowy o świadczenie usługi drogą elektroniczną w ramach
          korzystania z konkretnego formularza następuje z chwilą skorzystania
          przez Użytkownika z tego formularza. Rozwiązanie umowy o świadczenie
          usługi drogą elektroniczną następuje każdorazowo w sposób automatyczny
          po skorzystaniu przez Użytkownika z konkretnego formularza.
          Administrator może wprowadzić dodatkowe regulaminy określające zasady
          korzystania z konkretnych formularzy, które będą przekazywane do
          wiadomości i akceptacji Użytkownikom przed faktycznym skorzystaniem z
          danego formularza.
        </p>

        <h4>VI. Procedura rozpatrywania reklamacji.</h4>
        <p>
          1. Każdemu Użytkownikowi przysługuje prawo do złożenia reklamacji w
          sprawach związanych z funkcjonowaniem Serwisu lub wykonywaniem Usług
          przez Administratora.
        </p>
        <p>
          2. Reklamacje można składać w szczególności poprzez wysłanie
          wiadomości e-mail na adres: info@4fchange.com. Jest to sposób
          składania reklamacji zalecany przez Administratora. Użytkownik nie
          musi jednak z niego korzystać, ani się nim kierować, a brak
          skorzystania nie wpływa na skuteczność reklamacji.
        </p>
        <p>
          3. Administrator rozpatrzy każdą reklamację i ustosunkuje się do niej
          udzielając odpowiedzi o sposobie jej rozpatrzenia niezwłocznie, nie
          później niż w terminie 14 dni od daty złożenia reklamacji. O sposobie
          rozpatrzenia reklamacji Użytkownik zostanie poinformowany w sposób
          wskazany w zgłoszeniu reklamacyjnym.
        </p>
        <p>
          4. W przypadku wystąpienia ewentualnych braków w złożonej reklamacji,
          Administrator zwróci się do Użytkownika o ich uzupełnienie zgodnie z
          danymi adresowymi Użytkownika wskazanymi w tej reklamacji.
        </p>

        <h4>
          VII. Wymagania techniczne niezbędne do współpracy z systemem
          teleinformatycznym, którym posługuje się Administrator
        </h4>
        <p>
          1. Do korzystania z Serwisu, w tym zapoznania się z funkcjonalnościami
          i usługami dostępnymi w Serwisie, niezbędne jest urządzenie końcowe z
          dostępem do Internetu i zainstalowaną przeglądarką internetową typu:
          Internet Explorer, Mozilla Firefox, Opera, Google Chrome czy też Apple
          Safari.
        </p>
        <p>
          2. Do korzystania z Usług konieczne jest posiadanie aktywnego konta
          poczty e-mail.
        </p>

        <h4>VIII. Ochrona danych osobowych</h4>
        <p>
          1. Administratorem danych osobowych (w rozumieniu art. 4 pkt 7 RODO)
          Użytkowników korzystających z funkcjonalności Serwisu jest OTCF S.A.,
          za wyjątkiem funkcjonalności formularza „Zamów kuriera” (Rozdział III
          Regulaminu).
        </p>
        <p>
          2. Administrator wyznaczył Inspektora Ochrony Danych (IOD), z którym
          można skontaktować się w sprawach dotyczących ochrony danych osobowych
          oraz realizacją praw z tym związanych. W tym celu możliwy jest kontakt
          drogą elektroniczną na adres iod@otcf.pl, a także pocztą tradycyjną na
          adres OTCF S.A., ul. Saska 25C, 30-720 Kraków (z dopiskiem „Inspektor
          Ochrony Danych (IOD)”).
        </p>
        <p>
          3. Dane osobowe Użytkowników w zależności od sposobu korzystania z
          Serwisu, w tym oferowanych za jego pośrednictwem usług, mogą być
          przetwarzane w następujących celach oraz na poniższych podstawach
          prawnych:
        </p>
        <ul>
          <li>
            umożliwienia korzystania z funkcjonalności formularzy, o których
            mowa w Rozdziale V Regulaminu w ramach zawartej z Użytkownikiem
            umowy o świadczenie usług drogą elektroniczną (podstawa prawna
            przetwarzania danych: art. 6 ust. 1 lit. b RODO) zgodnie z
            niniejszym Regulaminem, a także na podstawie szczególnych
            regulaminów określających zasady korzystania z konkretnych
            formularzy, o ile będą one obowiązywały w konkretnych przypadkach
            zgodnie z decyzją podjętą przez Administratora (w takim wypadku
            regulaminy te mogą określać dodatkowe cele i podstawy prawne
            przetwarzania danych osobowych),
          </li>
          <li>
            rozpatrywania reklamacji związanych z zawartą umową o świadczenie
            usług drogą elektroniczną – w sytuacji gdy Użytkownik taką umowę
            zawarł z Administratorem na zasadach określonych w niniejszym
            Regulaminie (art. 6 ust. 1 lit. b RODO),
          </li>
          <li>
            przyjmowania i obsługi innych niż reklamacje oraz sprawy związane z
            realizowanymi umowami, zgłoszeń i zapytań kierowanych do
            Administratora (np. za pośrednictwem danych kontaktowych wskazanych
            na Stronie Internetowej Serwisu lub za pośrednictwem formularza
            kontaktowego), co stanowi prawnie uzasadniony interes Administratora
            (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
          </li>
          <li>
            rozpatrywania i dochodzenia roszczeń, obrony przed roszczeniami, a
            także w celach realizacji pozasądowych sposobów rozpatrywania
            reklamacji i dochodzenia roszczeń, co stanowi prawnie uzasadniony
            interes Administratora (podstawa prawna przetwarzania danych: art. 6
            ust. 1 lit. f RODO),
          </li>
          <li>
            monitorowania sposobu korzystania z usług świadczonych w ramach
            Serwisu przez Użytkowników, pod kątem przestrzegania postanowień
            Regulaminu, a także rozwijania funkcjonalności Serwisu, poprawy
            działania świadczonych usług za jego pośrednictwem, co stanowi
            prawnie uzasadniony interes Administratora (podstawa prawna
            przetwarzania danych: art. 6 ust. 1 lit. f RODO),
          </li>
          <li>
            w celach marketingu bezpośredniego, w tym dokonywania analiz
            marketingowych oraz dostosowywania treści do charakteru lub
            preferencji Użytkowników (np. w ramach usługi Newslettera), co
            stanowi prawnie uzasadniony interes Administratora (podstawa prawna
            przetwarzania danych: art. 6 ust. 1 lit. f RODO),
          </li>
          <li>
            prowadzenia analiz statystycznych, co stanowi prawnie uzasadniony
            interes Administratora (podstawa prawna przetwarzania danych: art. 6
            ust. 1 lit. f RODO),
          </li>
          <li>
            przechowywania danych dla celów archiwizacyjnych oraz na potrzeby
            wykazywania prawidłowości spełnienia obowiązków prawnych
            spoczywających na Administratorze, co stanowi jego prawnie
            uzasadniony interes (podstawa prawna przetwarzania danych: art. 6
            ust. 1 lit. f RODO),
          </li>
          <li>
            wysyłania informacji handlowych drogą elektroniczną w postaci
            Newslettera – jeśli konkretna osoba wyraziła odrębną zgodę na
            otrzymywanie informacji handlowych drogą elektroniczną,
          </li>
          <li>
            prowadzenia badań satysfakcji Użytkowników - jeśli konkretna osoba
            wyraziła odrębną zgodę na udział w takim badaniu (podstawa prawna
            przetwarzania danych: art. 6 ust. 1 lit. a RODO);
          </li>
          <li>
            zapisywania danych w postaci plików cookies, gromadzenia danych ze
            strony internetowej Serwisu – na zasadach określonych w Polityce
            Plików Cookies obowiązującej na stronie internetowej Serwisu oraz
            zgodnie z ustawieniami preferencji Użytkownika.
          </li>
        </ul>
        <p>
          4. Dane osobowe Użytkowników mogą być ujawniane następującym
          kategoriom odbiorców:
        </p>
        <ul>
          <li>
            podwykonawcom zapewniającym wsparcie techniczne Administratora w
            prowadzeniu i utrzymaniu, a także rozwoju Serwisu takim jak:
            podmioty świadczące usługi hostingu, dostawcy oprogramowania do
            zarządzania Serwisem, podmioty świadczące obsługę techniczną
            oprogramowania Serwisu, dostawcy zapewniający oprogramowanie do
            wysyłania korespondencji handlowej drogą elektroniczną, dostawcy
            zapewniający oprogramowanie służące do komunikacji z Użytkownikami,
            w tym komunikację realizowaną za pomocą dedykowanych algorytmów, z
            którymi Administrator zawarł wymagane prawnie umowy powierzenia
            przetwarzania danych osobowych;
          </li>
          <li>
            podmiotom wspierającym Administratora w prowadzeniu działań
            marketingowych, takim jak agencje marketingowe, podmioty prowadzące
            portale internetowe, w tym portale typu social media;
          </li>
          <li>
            podmiotom wspierającym Administratora w realizacji programów badania
            satysfakcji Użytkowników, w szczególności za pośrednictwem ankiet
            przeprowadzanych drogą elektroniczną lub telefoniczną; podmiotom
            wspierającym realizację przez Administratora obowiązujących
            przepisów prawa oraz uprawnień i obowiązków wynikających z
            niniejszego Regulaminu, w związku ze świadczeniem usług za
            pośrednictwem Serwisu, takim jak: kancelarie prawne oraz firmy
            windykacyjne.
          </li>
        </ul>
        <p>
          5. Administrator zwraca uwagę, że w przypadku skorzystania z
          formularza „Zamów kuriera”, Użytkownik udostępni swoje dane osobowe
          bezpośrednio podmiotowi New Solutions Group Sp. z o.o. (jako odrębnemu
          administratorowi danych), na zasadach określonych w Regulaminach oraz
          innych postanowieniach udostępnionych przez New Solutions Group Sp. z
          o.o. i akceptowanych przez Użytkownika podczas wypełniania formularza.
          Administrator Serwisu nie zbiera i nie przechowuje tych danych.
        </p>
        <p>
          6. Dane osobowe Użytkowników mogą być przekazywane przez
          Administratora poza teren Europejskiego Obszaru Gospodarczego (EOG) w
          ramach korzystania przez Administratora z usług podwykonawców (spośród
          kategorii odbiorców wskazanych w punkcie 4 wyżej). W takim wypadku
          Administrator gwarantuje wymagane prawnie środki ochrony danych
          osobowych, którymi będą (w zależności od przypadku): i) przekazanie
          danych do podwykonawcy zlokalizowanego w państwie trzecim, w stosunku
          do którego została wydana decyzja stwierdzająca odpowiedni stopień
          ochrony, zgodnie z wymaganiami art. 45 RODO, ii) przekazanie danych
          realizowane na podstawie zawartej z podwykonawcą umowy o transfer
          danych opartej na Standardowych Klauzulach Umownych przyjętych w
          drodze decyzji przez Komisję Europejską, iii) przekazanie danych
          realizowane w ramach stosowanych przez podwykonawcę wiążących reguł
          korporacyjnych, o których mowa w art. 47 RODO. Więcej informacji
          dotyczących stosowanych przez Administratora środków bezpieczeństwa
          związanych z przekazywaniem danych poza obszar EOG można uzyskać
          kontaktując się z Inspektorem Ochrony Danych wyznaczonym przez
          Administratora.
        </p>
        <p>
          7. Pozyskane dane osobowe będą przechowywane przez Administratora
          przez okres świadczenia usług Serwisu (Użytkownikom) przez czas
          obowiązywania umów o świadczenie usług drogą elektroniczną, a ponadto:
        </p>
        <ul>
          <li>
            do czasu przedawnienia potencjalnych roszczeń wynikających z umów
            wskazanych powyżej,
          </li>
          <li>
            przez czas niezbędny do dochodzenia konkretnych roszczeń, z którymi
            wystąpił Administrator lub ich odparcia (jeżeli z roszczeniami
            wystąpił Użytkownik w związku z zawartymi umowami, o których mowa
            wyżej,
          </li>
          <li>
            przez czas wykonywania obowiązków wynikających z przepisów prawa, w
            tym w szczególności wzakresie regulacji podatkowych i rachunkowych,
            np. obowiązków związanych z przechowywaniem dokumentacji zgodnie z
            wymaganiami art. 74 ustawy z dnia 29 września 1994 r. o
            rachunkowości,
          </li>
          <li>
            przez okres niezbędny do udokumentowania przez Administratora przed
            organami administracji publicznej, w tym organem nadzoru w zakresie
            ochrony danych osobowych, prawidłowości spełnienia obowiązków
            prawnych na nim spoczywających,
          </li>
          <li>
            w celach archiwizacyjnych, gdy dotyczy to historii prowadzonej
            korespondencji i udzielanych odpowiedzi na zgłaszane zapytania
            (niezwiązane bezpośrednio z zawartymi umowami) – przez okres nie
            dłuższy niż 3 lata od pozyskania danych
          </li>
          <li>
            w celach marketingu bezpośredniego – przez czas obowiązywania umowy
            o świadczenie usług drogą elektroniczną (Użytkownicy) lub do czasu
            zgłoszenia sprzeciwu na przetwarzanie danych w tym celu,
          </li>
          <li>
            do czasu wycofania zgody na przetwarzanie danych lub ich
            dezaktualizacji (stwierdzonej przez Administratora) – jeżeli
            przetwarzanie danych odbywa się za zgodą udzieloną przez konkretną
            osobę.
          </li>
        </ul>
        <p>
          8. Administrator zapewnia każdemu Użytkownikowi prawo skorzystania ze
          wszystkich przysługujących im uprawnień przewidzianych na gruncie
          RODO, tj. prawa żądania dostępu do swoich danych osobowych, prawa ich
          sprostowania, usunięcia lub żądania ograniczenia przetwarzania, prawa
          do przenoszenia danych, a także prawa wniesienia sprzeciwu na ich
          przetwarzanie, na zasadach i w przypadkach przewidzianych w przepisach
          RODO.
        </p>
        <p>
          9. W przypadku przetwarzania danych osobowych przez Administratora w
          celu realizacji prawnie uzasadnionych interesów (o których była mowa
          powyżej), każdemu Użytkownikowi przysługuje prawo do wniesienia
          sprzeciwu na przetwarzanie danych z przyczyn związanych z jego
          szczególną sytuacją.
        </p>
        <p>
          10. Dane przetwarzane w celu realizacji prawnie uzasadnionego interesu
          polegającego na prowadzeniu marketingu bezpośredniego Administratora
          będą przetwarzane wyłącznie do czasu zgłoszenia sprzeciwu na tego typu
          formę przetwarzania. Użytkownikowi przysługuje prawo złożenia
          sprzeciwu na przetwarzanie jego danych osobowych na potrzeby
          marketingu bezpośredniego, w tym poprzez profilowanie, w każdym
          czasie. Sprzeciw można złożyć kontaktując się pod adresem e-mail:
          iod@otcf.pl
        </p>
        <p>
          11. W przypadku przetwarzania przez Administratora danych osobowych za
          zgodą wyrażoną przez Użytkownika, każdej osobie przysługuje prawo
          wycofania w dowolnym momencie zgody na przetwarzanie swoich danych bez
          wpływu na zgodność z prawem przetwarzania tych danych, które miało
          miejsce przed cofnięciem zgody. Oświadczenie w przedmiocie odwołania
          zgody na przetwarzania danych osobowych można w szczególności złożyć
          pod adresem e-mail: iod@otcf.pl lub w sposób określony w Regulaminie
          korzystania z usługi, która świadczona jest w oparciu o udzieloną
          zgodę na przetwarzanie danych.
        </p>
        <p>12. Podanie danych osobowych w odniesieniu do:</p>
        <ul>
          <li>
            Użytkowników, którzy składają reklamacje w związku z zawartą ze
            Administratorem umową o świadczenie usług drogą elektroniczną na
            zasadach określonych w niniejszym Regulaminie – w celu złożenia
            reklamacji i umożliwienia jej rozpatrzenia przez Administratora
            niezbędne jest podanie adresu e-mail który Użytkownik podał w
            odpowiednim formularzu lub który podał podczas rejestracji w
            Newsletterze (gdy reklamacja dotyczy tej usługi). Niepodanie tych
            danych może uniemożliwić Administratorowi rozpatrzenie złożonej
            reklamacji,
          </li>
          <li>pozostałych przypadków - jest dobrowolne.</li>
        </ul>
        <p>
          14. Administrator nie będzie prowadził operacji przetwarzania danych
          Użytkowników w sposób zautomatyzowany, które jednocześnie będą
          prowadziły do podejmowania wobec nich decyzji wywołujących skutki
          prawne lub w podobny sposób istotnie wpływających na ich sytuację.
          Ewentualne przetwarzanie danych w sposób zautomatyzowany, w tym
          poprzez profilowanie, będzie służyło wyłącznie analizom i prognozom
          indywidualnych preferencji Użytkowników korzystających z Serwisu.
        </p>
        <p>
          14. Każdej osobie, której dane osobowe są przetwarzane przez
          Administratora przysługuje prawo wniesienia skargi w związku z
          przetwarzaniem jej danych osobowych do organu nadzorczego, którym jest
          Generalny Inspektor Ochrony Danych Osobowych, a od momentu wejścia w
          życie nowej ustawy o ochronie danych osobowych jego następca prawny –
          Prezes Urzędu Ochrony Danych Osobowych, mający siedzibę przy ul.
          Stawki 2, 00-193 Warszawa.
        </p>

        <h4>
          IX. Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia
          roszczeń.
        </h4>
        <p>
          1. Konsument może skorzystać z pozasądowych sposobów rozpatrywania
          reklamacji i dochodzenia roszczeń. Zasady dostępu do tych procedur
          dostępne są w siedzibach lub na stronach internetowych podmiotów
          uprawnionych do pozasądowego rozpatrywania sporów, takich jak w
          szczególności: rzecznicy praw konsumenta lub Wojewódzkie Inspektoraty
          Inspekcji Handlowej, których lista jest dostępna na stronie
          internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem
          http://www.uokik.gov.pl/
        </p>
        <p>
          2. Pod adresem http://ec.europa.eu/consumers/odr dostępna jest
          platforma internetowego systemu rozstrzygania sporów pomiędzy
          konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR).
          Platforma ODR stanowi interaktywną i wielojęzyczną stronę internetową
          z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców
          dążących do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań
          umownych wynikających z internetowej umowy sprzedaży lub umowy o
          świadczenie usług.
        </p>

        <h4>X. Postanowienia końcowe</h4>
        <p>
          1. Regulamin dostępny jest nieodpłatnie na stronie internetowej
          Serwisu https://4fchange.com/ w postaci umożliwiającej jego
          pozyskanie, odtwarzanie i utrwalanie za pomocą systemu
          teleinformatycznego Użytkownika.
        </p>
        <p>
          Regulamin jest także przesyłany - w postaci umożliwiającej jego
          pozyskanie, odtwarzanie i utrwalanie przez Użytkownika - na adres
          poczty elektronicznej podany przez Użytkownika w przypadku zawarcia z
          Administratorem umowy o świadczenie usług drogą elektroniczną za
          pośrednictwem Serwisu.
        </p>
        <p>
          2. Postanowienia Regulaminu nie mają na celu ograniczyć lub wyłączyć
          jakichkolwiek praw Użytkowników wynikających z przepisów prawa.
          Administrator honoruje wszelkie prawa Użytkowników przewidziane w
          przepisach obowiązującego prawa, a w szczególności przewidziane w
          przepisach ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny oraz
          ustawy z dnia 30 kwietnia 2014 r. o prawach konsumenta.
        </p>
        <p>
          3. Administrator zastrzega sobie prawo do dokonywania zmian Regulaminu
          z ważnych przyczyn obejmujących:
        </p>
        <ul>
          <li>zmiany w prawie;</li>
          <li>przyczyny organizacyjne, w szczególności:</li>
          <ul>
            <li>
              zmiany w zakresie prowadzonej przez Administratora działalności w
              postaci zmian jej profilu, zmian zakresu świadczonych lub
              udogodnień (w tym związanych z zawieraniem i rozwiązywaniem umów),
            </li>
            <li>
              zmiany w zakresie: danych adresowych, nazwy lub formy prawnej
              Administratora,
            </li>
            <li>inne zmiany techniczne związane z funkcjonowaniem Serwisu.</li>
          </ul>
        </ul>
        <p>
          4. Każdy Użytkownik zostanie poinformowany o zmianie Regulaminu
          poprzez opublikowanie go na Stronie Internetowej Serwisu.
        </p>
        <p>
          5. Użytkownik, który korzysta z usługi Newsletter zostanie
          powiadomiony o zmianie Regulaminu przez przesłanie na jego adres
          poczty elektronicznej informacji o zmianie.
        </p>
        <p>
          6. Zmiany Regulaminu wejdą w życie w odniesieniu do danego Użytkownika
          po upływie 7 dni kalendarzowych od momentu poinformowania go o zmianie
          Regulaminu we właściwy sposób.
        </p>
        <p>
          7. Regulamin obowiązuje od dnia 9 stycznia 2023 r., przy czym
          niniejsza wersja Regulaminu została opublikowana w dniu 26.01.2023 r.
        </p>
      </div>
    </StyledDescriptionPage>
  </Layout>
)

export default ServiceTerms
