import styled from "styled-components"

const StyledFilters = styled.section`
  .filters {
    &__categories {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 65px 0 0 0;

      @media (max-width: 1199px) {
        margin: 50px 0 0 0;
      }

      a {
        min-width: auto;
        margin: 0 25px 25px;

        @media (max-width: 1199px) {
          margin: 0 10px 10px;
          padding: 7px 10px;
        }
      }
    }

    &__search {
      margin: 35px 0 0 0;
      position: relative;

      &:after {
        content: "";
        width: 22px;
        height: 22px;
        position: absolute;
        top: 50%;
        transform: translate(-0%, -50%);
        right: 0;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMy42NjQiIGhlaWdodD0iMjMuNDk5IiB2aWV3Qm94PSIwIDAgMjMuNjY0IDIzLjQ5OSI+CiAgPGcgaWQ9Ikljb25fZmVhdGhlci1zZWFyY2giIGRhdGEtbmFtZT0iSWNvbiBmZWF0aGVyLXNlYXJjaCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+CiAgICA8cGF0aCBpZD0iUGF0aF8zODgiIGRhdGEtbmFtZT0iUGF0aCAzODgiIGQ9Ik0yMC4zMTQsMTIuNDA3QTcuOTA3LDcuOTA3LDAsMSwxLDEyLjQwNyw0LjUsNy45MDcsNy45MDcsMCwwLDEsMjAuMzE0LDEyLjQwN1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjUgLTQuNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzM4OSIgZGF0YS1uYW1lPSJQYXRoIDM4OSIgZD0iTTMyLjczNCwzMi41NjlsLTcuNzU5LTcuNTk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuNDg0IC0xMS40ODQpIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 50px;
        border: solid #000;
        border-width: 0 0 1px 0;
        font-weight: 700;
      }
    }
  }
`

export default StyledFilters
