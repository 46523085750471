import React, { useRef } from "react"
import Slider from "react-slick"
import { withPrefix } from "gatsby"
import { useLocale } from "context/locale"

import Button from "components/Button"

import ArrowRightIcon from "icons/arrow_right.svg"
import ChangeMaker from "./components/ChangeMaker"
import StyledChangeMakers from "./styles"

const ChangeMakers = ({ changemakersData }) => {
  const { t } = useLocale()
  const changemakers_ref = useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    swipe: true,
    arrows: false,
    autoplay: false,
    variableWidth: true,
    speed: 1000,
    ref: changemakers_ref,
    responsive: [
      {
        breakpoint: 800,
      },
      {
        breakpoint: 10000,
        settings: "unslick",
      },
    ],
  }

  return (
    <StyledChangeMakers>
      <button
        type="button"
        aria-label="next"
        className="btn-next"
        onClick={() => changemakers_ref?.current?.slickNext()}
      >
        <ArrowRightIcon />
      </button>

      <div className="changemakers-header">
        <h2 className="h1 title">CHANGEMAKERS</h2>
      </div>

      <div className="changemakers-cta">
        <Button
          text={t("Download the manual “How to become a Changemaker”")}
          as="link-out"
          href={withPrefix(t("/4Fchange_poradnik_2024_ENG.pdf"))}
          variant="download-dark"
        />
      </div>

      <div className="main-container">
        <Slider {...settings} className="changemakers-grid">
          {changemakersData.map((item, index) => (
            <ChangeMaker key={index} item={item} />
          ))}
        </Slider>
      </div>
    </StyledChangeMakers>
  )
}

export default ChangeMakers
