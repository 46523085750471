import styled from "styled-components"

const StyledLabel = styled.div`
  display: inline-block;
  background: #00fb6e;
  padding: 2px 11px;
  span {
    font-size: 24px;
    line-height: 31px;
    white-space: nowrap;
    color: #000;
  }
  @media screen and (min-width: 1400px) {
    ${({ lg }) =>
      lg &&
      `
  padding: 4px 20px;
  span {
    font-size: 33px;
    line-height: 42px;
  }
    `}
  }
  @media screen and (max-width: 600px) {
    span {
      font-size: 16px;
      line-height: 21px;
    }
  }
`

export default StyledLabel
