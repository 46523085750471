import React, { useEffect } from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const CookiePolicy = ({location}) => {
  useEffect(() => {
    const scriptNode = document.createElement("script")
    scriptNode.src =
      "https://consent.cookiebot.com/8529fdcb-64e1-464c-b7bd-12f61a28f803/cd.js"
    scriptNode.type = "text/javascript"
    scriptNode.async = true
    document.querySelector(".description-page").appendChild(scriptNode)
  }, [])
  return (
    <Layout
    location={location}
      seo={{
        title: "Lista cookies podmiotów trzecich - 4F Change",
      }}
    >
      <PageHeader title="Lista cookies podmiotów trzecich" />
      <StyledDescriptionPage>
        <div className="description-page" />
      </StyledDescriptionPage>
    </Layout>
  )
}

export default CookiePolicy
