import React from "react"
import { useLocale } from "context/locale"
import Button from "components/Button"

const NotFoundContent = () => {
  const { t } = useLocale()
  return (
    <div className="main-container">
      <h1 className="title">404</h1>
      <h2 className="subtitle">{t("This place should not exist...")}</h2>
      <p>{t("Find out what you can do to change this")}</p>
      <Button text={t("Homepage")} as="link" href={t("index_link")} />
    </div>
  )
}

export default NotFoundContent
