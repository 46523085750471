export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  if (location?.state?.prevent_scroll) {
    return false
  }
  window.scrollTo(...(currentPosition || [0, 0]))
  return false
}

export const onRouteUpdate = ({ prevLocation, location }) => {
  if (
    !!prevLocation &&
    (location.pathname === "/lista-cookies-podmiotow-trzecich/" ||
      location.pathname === "/wypozyczalnia/")
  ) {
    window.location.reload()
  }
}