import styled from "styled-components"

const StyledUpcycledJacketsDogSecondEdition = styled.div`
  .upcycled-jackets-post-second-edition {
    p {
      strong {
        color: #00fb6e;
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 45px -15px;

      @media (max-width: 1199px) {
        margin: 30px -15px;
      }

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
      }

      img {
        height: auto;
        max-width: 100%;

        @media (max-width: 575px) {
          margin: 0 auto 15px;
        }
      }
    }

    &__col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;

      @media (max-width: 575px) {
        flex-: 0 0 100%;
        max-width: 100%;
      }

      &-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        h4 {
          margin: 0 0 5px 0 !important;
          text-transform: uppercase;
          position: relative;
        }

        span {
          text-transform: uppercase;
          display: flex;
          margin: 0 0 20px 0;
        }

        p {
          margin: 0 0 10px 0;
        }

        .link {
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }

    &__table {
      padding: 45px 30px;
      background-color: #000;
      text-align: center;
      margin: 60px 0 0 0;
    }

    &__dogs {
      margin: 60px auto 0;
      position: relative;
      width: calc(150% + 30px);
      left: calc(-25% - 15px);
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1199px) {
        width: 100%;
        left: 0;
        flex-direction: column;
        margin: 30px auto;
      }

      &-col {
        flex: 0 0 33.3333333%;
        max-width: 33.3333333%;
        padding: 0 15px;

        @media (max-width: 1199px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &-item {
        min-height: 400px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;

        @media (max-width: 1199px) {
          max-width: 300px;
          margin: 10px auto;
        }

        > div {
          background-color: #fff;
          border: 5px solid #00fb6e;
          padding: 20px 20px;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 200ms linear;
          transform: translate(0%, 100%);

          p {
            line-height: 22px;
            margin: 5px 0 0 0;

            @media (max-width: 1919px) {
              font-size: 14px;
              line-height: 20px;
            }

            @media (max-width: 1440px) {
              font-size: 12px;
              line-height: 18px;
            }

            @media (max-width: 1199px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        &:hover {
          > div {
            transform: translate(0%, 0%);
          }
        }
      }
    }

    &__locations {
      background-color: #e9e9e9;
      padding: 50px 15px;
      text-align: center;
      margin: 60px 0 0 0;

      @media (max-width: 991px) {
        padding: 30px 15px;
      }

      div {
        width: 100%;
        max-width: 333px;
        margin: 0 auto;
      }

      h4 {
        max-width: 374px;
        margin: 0 auto 30px !important;
      }

      button,
      a {
        font-size: 16px;
        border: 1px solid #000;
        color: #000;
        min-height: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 15px 20px;
        width: 100%;
        max-width: 333px;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        transition: all 150ms linear;

        &:after {
          content: "${props => props.text}";
          font-weight: 700;
          background-color: #00fb6e;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 150ms linear;
          transform: translate(0%, 100%);
        }

        &:not(:last-child) {
          margin: 0 0 15px 0;
        }

        strong {
          font-size: 21px;
          display: inline-flex;
          margin: 0 5px 0 0;
        }

        span {
          position: relative;
          top: -1px;
        }

        &:hover {
          color: #00fb6e;

          &:after {
            background-color: #000;
            transform: translate(0%, 0%);
          }
        }
      }
    }

    /*  */

    &__adopt {
      margin: 60px 0;

      @media (max-width: 1199px) {
        margin: 30px 0;
      }

      &-row {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 991px) {
          flex-direction: column;
          align-items: center;
        }
      }

      &-col {
        flex: 0 0 50%;
        max-width: 50%;

        @media (max-width: 991px) {
          flex-: 0 0 100%;
          max-width: 303px;
        }

        img {
          width: 100%;
        }

        > div {
          height: calc(100% - 6px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          background-color: #00fb6e;
          padding: 20px 30px;
          font-weight: 700;

          @media (max-width: 991px) {
            height: 100%;
          }
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
`

export default StyledUpcycledJacketsDogSecondEdition
