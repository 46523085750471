import styled from "styled-components"

const StyledDontBuyAdopt = styled.div`
  .dont-buy-adopt {
    &__grid {
      margin: 60px 0 0 0;

      @media (max-width: 1199px) {
        margin: 30px 0 0 0;
      }

      h3 {
        text-align: center;
      }
    }

    &__item {
      margin: 30px auto 0;
      position: relative;
      width: calc(130% + 30px);
      left: calc(-15% - 15px);

      @media (max-width: 1199px) {
        width: 100%;
        left: 0;
      }

      &-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      &-col {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;

        @media (max-width: 768px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      img {
        height: auto;
        max-width: 100%;

        @media (max-width: 768px) {
          margin: 0 auto 15px;
        }
      }
    }

    &__description {
      margin: 90px 0;

      @media (max-width: 1199px) {
        margin: 30px 0;
      }
    }

    &__baner {
      min-height: 511px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding: 45px;

      @media (max-width: 575px) {
        padding: 20px;
      }
    }
  }
`

export default StyledDontBuyAdopt
