import React from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Button from "components/Button"
import ThanksIcon from "icons/thanks.svg"

import StyledContactThankYouPage from "styles/ContactThankYouStyles"

const ContactThankYouPage = ({ location }) => (
  <Layout seo={{ title: "Dziekujemy" }} location={location}>
    <PageHeader title="Dziękujemy!" />
    <StyledContactThankYouPage>
      <div className="main-container">
        <div className="main-wrapper">
          <div className="icon-holder">
            <ThanksIcon />
          </div>
          <h4>Dziękujemy za wysłanie wiadomości</h4>
          <p>Skontaktujemy się z Tobą najpóźniej w ciągu 24 godzin.</p>
          <Button text="Strona główna" as="link" href="/" />
        </div>
      </div>
    </StyledContactThankYouPage>
  </Layout>
)

export default ContactThankYouPage
