import React from "react"
import { Link } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const RentalTerms = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Regulamin wypożyczalni - 4F Change",
      description:
        "Niniejszy regulamin określa zasady korzystania z wypożyczalni znajdującej się pod adresem https://www.4Fchange.com/wypozyczalnia.",
    }}
  >
    <PageHeader title="Regulamin wypożyczalni odzieży zimowej 4f" />

    <StyledDescriptionPage>
      <div className="description-page">
        <h4>DEFINICJE</h4>
        <p>
          <strong>1. Usługodawca</strong> – spółka OTCF S.A. z siedzibą w
          Wieliczce (ul. Saska 25C, 30-720 Kraków), zarejestrowana w rejestrze
          przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
          Rejonowy dla Krakowa - Śródmieścia w Krakowie, Wydział XII Gospodarczy
          Krajowego Rejestru Sądowego po numerem KRS: 0000555276, NIP:
          PL9451978451, REGON: 356630870, wysokość kapitału zakładowego
          7.384.500 zł (opłacony w całości), numer rejestrowy BDO: 000005025,
          numer telefonu do Biura Obsługi Klienta Wypożyczalni: +48 724 750 892
          (opłaty wg stawek operatora), adres poczty
          elektronicznej: wypozyczalnia@4Fchange.com
        </p>
        <p>
          <strong>2. Wypożyczalnia</strong> - wypożyczalnia internetowa odzieży
          zimowej marki „4F”, dostępna pod adresem{" "}
          <Link to="/wypozyczalnia/">https://4fchange.com/wypozyczalnia/</Link>{" "}
          prowadzona przez Usługodawcę, będąca platformą, za pośrednictwem
          której Usługodawca: udostępnia funkcjonalności Wypożyczalni, świadczy
          na rzecz Użytkowników usługi oraz umożliwia zawieranie Umów najmu
          Towarów na odległość, jak również za pośrednictwem której Użytkownicy
          mają możliwość zapoznania się z Towarami będącymi w asortymencie
          Wypożyczalni. Usługodawca za pośrednictwem Wypożyczalni udostępnia
          odpowiednie narzędzia systemowe, teleinformatyczne, technologiczne w
          celu świadczenia ww. usług.
        </p>
        <p>
          <strong>3. Strona internetowa Wypożyczalni</strong> - strona
          internetowa dostępna pod adresem{" "}
          <Link to="/wypozyczalnia/">https://4fchange.com/wypozyczalnia/</Link>.
        </p>
        <p>
          <strong>4. Regulamin</strong> - niniejszy Regulamin, określający
          zasady korzystania z Wypożyczalni, w szczególności zasady zawierania
          za pośrednictwem Wypożyczalni Umów najmu Towarów znajdujących się w
          asortymencie Wypożyczalni, zasady wykonywania tych Umów oraz zasady
          postępowania reklamacyjnego.
        </p>
        <p>
          <strong>5. Użytkownik</strong> - korzystająca z funkcjonalności
          Wypożyczalni osoba fizyczna, osoba prawna lub jednostka organizacyjna
          nieposiadająca osobowości prawnej.
        </p>
        <p>
          <strong>6. Klient</strong> - Użytkownik, który zawarł Umowę najmu
          Towarów z Usługodawcą (również Konsument).
        </p>
        <p>
          <strong>7. Konsument</strong> - Użytkownik będący osobą fizyczną
          dokonującą z Usługodawcą czynności prawnej niezwiązanej bezpośrednio z
          jej działalnością gospodarczą lub zawodową, w szczególności
          zawierającą Umowę najmu Towarów za pośrednictwem Wypożyczalni.
        </p>
        <p>
          <strong>8. Rejestracja</strong> – czynność Użytkownika dokonywana na
          Stronie internetowej Wypożyczalni, w celu zawarcia Umowy najmu Towaru
          polegająca na: a) podaniu danych Użytkownika oraz b) wyrażeniu
          niezbędnych zgód, a to zgodnie ze szczegółowym opisem wskazanym w pkt
          V Regulaminu. Rejestracja umożliwia logowanie na Stronie internetowej
          Wypożyczalni na etapie składania zamówienia.
        </p>
        <p>
          <strong>9. Towary</strong> - znajdujące się w asortymencie
          Wypożyczalni rzeczy ruchome mogące być przedmiotem Umowy najmu
          pomiędzy Klientem a Usługodawcą.
        </p>
        <p>
          <strong>10. Umowa najmu</strong> - umowa, której przedmiotem jest
          oddanie przez Usługodawcę na rzecz Klienta Towarów do używania przez
          czas oznaczony w zamian za czynsz, opłacony przez Klienta, zawarta
          pomiędzy Klientem a Usługodawcą za pośrednictwem Strony Internetowej
          Wypożyczalni, na zasadach określonych w niniejszym Regulaminie.
        </p>
        <p>
          <strong>11. Ostateczna kwota do zapłaty</strong> - kwota, jaką
          zobowiązany jest uiścić Klient w związku z zawarciem Umowy najmu
          Towarów, szczegółowo zdefiniowana w pkt III niniejszego Regulaminu.
        </p>
        <p>
          <strong>12. Rezerwacja</strong> - okres, w którym prawo do korzystania
          z Towaru na podstawie Umowy najmu jest zastrzeżone na rzecz danego
          Klienta. Towar objęty Rezerwacją jest wyłączony z możliwości objęcia
          go Umową najmu zawieraną przez innego Klienta w czasie pokrywającym
          się z Rezerwacją.
        </p>
        <p>
          <strong>13. RODO</strong> - Rozporządzenie Parlamentu Europejskiego i
          Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób
          fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        </p>
        <h4>I. Postanowienia wstępne</h4>
        <p>
          1. Usługodawca stosuje środki techniczne i organizacyjne odpowiednie
          do stopnia zagrożenia, w tym środki służące zapobieganiu pozyskania i
          modyfikacji przez osoby nieuprawnione danych osobowych przesyłanych
          przez Internet – w celu zapewnienia bezpieczeństwa komunikatów i
          danych przekazywanych w Wypożyczalni. Usługodawca zapewnia
          bezpieczeństwo transmisji danych przekazywanych w Wypożyczalni poprzez
          stosowanie protokołu SSL (SecureSocketLayer).
        </p>
        <p>
          2. Usługodawca oferuje usługę najmu Towarów za pośrednictwem
          Wypożyczalni z wykorzystaniem sieci Internet.
        </p>
        <p>
          3. Najpóźniej w chwili wyrażenia przez Użytkownika woli związania się
          Umową najmu (poprzez złożenie zamówienia), Usługodawca dostarczy mu za
          pośrednictwem Strony internetowej Wypożyczalni informacje o głównych
          cechach Towarów oraz warunkach zawarcia Umowy najmu Towarów.
          Informacje o oferowanych przez Usługodawcę Towarach, szczegółowy ich
          opis ze wskazaniem ich głównych cech zamieszczone są pod każdym z
          Towarów uwidocznionych na Stronie internetowej Wypożyczalni.
        </p>
        <p>
          4. Użytkownik obowiązany jest do korzystania z Wypożyczalni w sposób
          niezakłócający jej funkcjonowania, zgodny z prawem, postanowieniami
          Regulaminu, dobrymi obyczajami oraz z poszanowaniem praw i dóbr
          osobistych innych podmiotów. Zakazane jest dostarczanie przez
          Użytkownika treści o charakterze bezprawnym.
        </p>
        <h4>II. Sposoby porozumiewania się</h4>
        <p>
          1. Usługodawca pod numerem telefonu do Biura Obsługi Klienta
          Wypożyczalni (numer telefonu do Biura Obsługi Klienta Wypożyczalni:
          +48 724 750 892), udziela informacji w sprawach zapytań dotyczących
          Wypożyczalni, w tym Umów najmu. Opłata za jedno połączenie zgodnie z
          taryfą operatora.
        </p>
        <p>
          2. Na adres email Biura Obsługi Klienta – wypozyczalnia@4fchange.com
          oraz bezpośrednio przez stronę internetową 4fchange.pl możliwe jest
          składanie przez Użytkowników zapytań adresowanych do Usługodawcy. W
          celu skorzystania z formularza kontaktowego, na stronie internetowej,
          o której mowa w zdaniu poprzedzającym, należy wybrać zakładkę Kontakt
          i wpisać wymagane dane umożliwiające Usługodawcy udzielenie odpowiedzi
          w określonych polach formularza zapytania, oraz zaznaczyć wymagane
          pola.
        </p>
        <p>
          3. Usługodawca może kontaktować się z Użytkownikiem za pomocą poczty
          elektronicznej lub telefonicznie w sprawie realizacji złożonego
          zamówienia, w tym w razie konieczności doprecyzowania szczegółów
          złożonego zamówienia.
        </p>
        <h4>III. Ostateczna kwota do zapłaty</h4>
        <p>
          1. Ceny najmu Towarów prezentowane na Stronie internetowej
          Wypożyczalni są uzależnione od okresu najmu Towarów, podane są w
          złotych polskich i są cenami brutto tj. zawierają podatki, w tym
          podatek od towarów i usług (VAT). Ceny te zawierają również koszty
          dostarczenia Towarów oraz zwrotu Towarów. Szczegółowy Cennik stanowi
          Załącznik nr 1 do niniejszego Regulaminu.
        </p>
        <p>
          2. Koszty dostarczenia Towarów obejmują opłaty za usługi pocztowe,
          przewozowe lub kurierskie
        </p>
        <p>
          3. Na Ostateczną kwotę do zapłaty z tytułu danego złożonego zamówienia
          składają się: cena najmu Towarów w rozumieniu ust. 1 powyżej oraz
          kwota kaucji, o której mowa w pkt IV Regulaminu.
        </p>
        <p>
          4. O ostatecznej kwocie do zapłaty Klient jest informowany na Stronie
          internetowej Wypożyczalni w trakcie składania zamówienia, w tym także
          bezpośrednio przed i w chwili zatwierdzenia zamówienia i złożenia
          zamówienia. Są to całkowite koszty, które Klient zobowiązany jest
          zapłacić wraz z należnymi podatkami. Ostateczna kwota do zapłaty
          wskazana jest także w wiadomości potwierdzającej przyjęcie zamówienia
          przez Usługodawcę i zawarcie Umowy najmu.
        </p>
        <p>
          5. Ostateczna kwota do zapłaty wskazana w sposób określony w ust. 4
          nie ulegnie zmianie.
        </p>
        <h4>IV. Kaucja</h4>
        <p>
          1. Klient zobowiązany jest uiścić kaucję w wysokości określonej
          Cennikiem, który stanowi Załącznik nr 1 do niniejszego Regulaminu.
          Kaucja zabezpiecza ewentualne roszczenia Usługodawcy względem Klienta
          z tytułu Umowy najmu.
        </p>
        <p>
          2. Kaucja zostanie doliczona do kwoty Ostatecznej kwoty do zapłaty,
          zgodnie z postanowieniami pkt III ppkt 3 Regulaminu powyżej.
        </p>
        <p>
          3. Kaucja jest płatna za pośrednictwem operatorów płatności
          udostępnionych przez Usługodawcę na Stronie internetowej Wypożyczalni.
        </p>
        <p>
          4. Kaucja ma charakter zwrotny. Warunkiem zwrotu kaucji jest zwrot
          przez Klienta Towarów na adres Usługodawcy: ul. Wielicka 250, 30-663
          Kraków w terminie: 1 dnia liczonego od dnia zakończenia okresu najmu
          zgodnym z Rezerwacją. Dla zachowania terminu zwrotu wystarczające jest
          przekazanie Towaru podmiotowi świadczącemu usługi pocztowe, przewozowe
          lub kurierskie w terminie, o którym mowa w zdaniu poprzedzającym.
          Towar powinien zostać zwrócony w stanie niepogorszonym, z
          uwzględnieniem jednak zużycia Towarów, będącego następstwem
          prawidłowego używania Towarów.
        </p>
        <p>
          5. Kaucja zostanie zwrócona Klientowi w terminie do 14 dni od dnia
          zwrotu Towaru na właściwy adres Usługodawcy: ul. Wielicka 250, 30-663
          Kraków za pośrednictwem operatora płatności, przy wykorzystaniu usług
          którego Klient opłacił kaucję, z zastrzeżeniem jednak postanowień pkt
          XII Regulaminu poniżej („Odpowiedzialność Klienta za Towar”).
        </p>
        <h4>V. Rejestracja</h4>
        <p>
          1. Aby skorzystać z usług Wypożyczalni niezbędne jest dokonanie
          Rejestracji przez Użytkownika na Stronie internetowej Wypożyczalni.
          Rejestracja jest nieodpłatna. Rejestracja jest dobrowolna, ale
          konieczna dla zawarcia Umowy najmu Towarów.
        </p>
        <p>
          2. Użytkownik może przeglądać asortyment Wypożyczalni oraz zapoznać
          się z warunkami korzystania z Wypożyczalni, w tym z warunkami Umowy
          najmu Towaru, bez Rejestracji.
        </p>
        <p>3. Aby dokonać Rejestracji, należy:</p>
        <p>
          a) wpisać wymagane dane w określonych polach formularza
          rejestracyjnego, tj. imię, nazwisko, adres email, nr telefonu, adres
          zamieszkania, adres dostawy – jeśli inny niż adres zamieszkania ,
          hasło, a w przypadku, gdy Rejestracji dokonuje osoba reprezentująca
          osobę prawną lub jednostkę organizacyjną nieposiadającą osobowości
          prawnej również firmę osoby prawnej lub nazwę jednostki organizacyjnej
          nieposiadającej osobowości prawnej, adres siedziby, nr NIP;
        </p>
        <p>
          b) zaznaczyć check-box’y dotyczące:
          <ul>
            <li>
              potwierdzenia zapoznania się z Regulaminem Wypożyczalni i
              akceptacji jego treści,
            </li>
            <li>
              akceptacji Regulamin pojedynczej transakcji płatniczej PayU oraz
            </li>
            <li>
              zgody na dokonanie potrącenia dodatkowego czynszu najmu z kwotą
              kaucji zgodnie z postanowieniami pkt XII niniejszego Regulaminu,
            </li>
            <li>
              a w przypadku, gdy rozpoczęcie okresu najmu (zgodnie datami
              Rezerwacji) przypada przed upływem terminu do odstąpienia od tej
              Umowy (14 dni liczone od dnia zawarcia Umowy najmu) również:
              żądania rozpoczęcia okresu najmu (zgodnie datami Rezerwacji) przed
              upływem terminu do odstąpienia od Umowy najmu;
            </li>
          </ul>
        </p>
        <p>
          c) nacisnąć przycisk „Potwierdzam rejestrację, Zamawiam i Płacę”
          znajdujący się pod formularzem
        </p>
        <h4>VI. Inne formularze</h4>
        <p>
          1. Usługodawca może udostępnić Użytkownikowi za pośrednictwem Strony
          internetowej Wypożyczalni również inne formularze niż formularz
          Rejestracji, które mogą w szczególności służyć do uzyskania opinii
          Użytkownika odnośnie Wypożyczalni, polecania przez Użytkownika innym
          użytkownikom Internetu usług oferowanych w Wypożyczalni, a także do
          wzięcia przez Użytkowników udziału w konkursach oraz promocjach
          organizowanych przez Usługodawcę.
        </p>
        <p>
          2. Zawarcie umowy o świadczenie usługi drogą elektroniczną w ramach
          korzystania z konkretnego formularza następuje z chwilą skorzystania
          przez Użytkownika z tego formularza. Rozwiązanie umowy o świadczenie
          usługi drogą elektroniczną następuje każdorazowo w sposób automatyczny
          po skorzystaniu przez Użytkownika z konkretnego formularza.
          Usługodawca może wprowadzić dodatkowe regulaminy określające zasady
          korzystania z konkretnych formularzy, które będą przekazywane do
          wiadomości i akceptacji Użytkownikom przed faktycznym skorzystaniem z
          danego formularza.
        </p>
        <h4>VII. Sposoby złożenia zamówienia</h4>
        <p>
          1. Użytkownik ma możliwość zawarcia Umowy najmu Towarów znajdujących
          się w asortymencie Wypożyczalni poprzez złożenie zamówienia
          bezpośrednio poprzez Stronę Internetową Wypożyczalni (po dokonaniu
          Rejestracji) i przechodząc przez ścieżkę zamówienia.
        </p>
        <p>
          2. W celu złożenia zamówienia bezpośrednio poprzez Stronę internetową
          Wypożyczalni należy: <br />
          a) wybrać we wskazanych polach na stronie oczekiwane daty najmu,{" "}
          <br />
          b) wybrać Towary i kliknąć „Zamów”, <br />
          c) potwierdzić wybór w sekcji „Twoje zamówienie” klikając „Dalej”,{" "}
          <br />
          d) wybrać sposób odbioru i zwrotu, <br />
          e) potwierdzić klikając „Podsumowanie zamówienia”, <br />
          f) wypełnić formularz Rejestracji lub wpisać dane do logowania i
          kliknąć „Zaloguj się” <br />
          g) zaznaczyć wymagane check-box’y, a następnie kliknąć w przycisk
          ”Potwierdzam rejestrację, Zamawiam i Płacę” lub inny przycisk
          zawierający jednoznaczne sformułowanie równoważne słowom: „zamówienie
          z obowiązkiem zapłaty”, przy czym oznacza to złożenie zamówienia z
          obowiązkiem zapłaty, <br />
          h) opłacić Ostateczną kwotę do zapłaty na stronie operatora płatności
          PayU. <br />
        </p>
        <p>
          3. Jedno zamówienie może obejmować kilka Towarów w ramach jednej
          Rezerwacji. W przypadku, gdy Użytkownik chce zawrzeć z Usługodawcą
          Umowy najmu Towarów obejmujące różne okresy najmu, wówczas należy
          złożyć osobne zamówienia, wybierając odpowiednie daty Rezerwacji.
        </p>
        <p>
          4. Zamówienia będą realizowane zgodnie z datami Rezerwacji. Rezerwacja
          nie może obejmować okresu krótszego niż 7 dni.
        </p>
        <p>
          5. W przypadku, gdy z przyczyn niezależnych od Usługodawcy Towar
          objęty Rezerwacją nie będzie dostępny w terminie Rezerwacji (np. w
          przypadku braku zwrotu Towaru po zakończeniu czasu trwania Umowy najmu
          zawartej przez innego Klienta lub zwrotu Towaru w stanie pogorszonym),
          Usługodawca zobowiązany jest poinformować o tym Klienta najpóźniej na
          3 dni przed rozpoczęciem Umowy najmu oraz zaproponować Klientowi Towar
          zastępczy z tej samej kategorii produktowej oraz w rozmiarze objętym
          Rezerwacją. W przypadku braku akceptacji Towaru zastępczego ze strony
          Klienta, Usługodawca zobowiązany jest zwrócić Klientowi Ostateczną
          kwotę do zapłaty uiszczoną przez Klienta w pełnej wysokości. Zwrot tej
          kwoty nastąpi niezwłocznie, w każdym przypadku w terminie nie dłuższym
          niż 14 dni, za pośrednictwem operatora płatności, przy wykorzystaniu
          usług którego opłata ta została uiszczona.
        </p>
        <h4>
          VIII. Zawarcie Umowy najmu Towarów i przekazanie potwierdzenia
          zawarcia Umowy najmu Towarów
        </h4>
        <p>
          1. Informacje opublikowane na Stronie internetowej Wypożyczalni nie
          stanowią oferty Usługodawcy, lecz zaproszenie do składania ofert
          zawarcia Umowy najmu przez Użytkowników. Przez złożenie oferty
          zawarcia Umowy najmu rozumie się złożenie przez Użytkownika zamówienia
          na Stronie internetowej Wypożyczalni.
        </p>
        <p>
          2. Akceptacja oferty Użytkownika przez Usługodawcę następuje poprzez
          wiadomość elektroniczną, wysłaną przez Usługodawcę na podany przez
          Użytkownika w formularzu Rejestracji adres poczty elektronicznej,
          potwierdzającą przyjęcie zamówienie Użytkownika przez Usługodawcę.
        </p>
        <p>
          3. Z chwilą otrzymania przez Użytkownika wiadomości elektronicznej
          wskazanej w ppkt 2 powyżej zostaje zawarta Umowa najmu Towarów
          pomiędzy Użytkownikiem jako Klientem a Usługodawcą.
        </p>
        <p>
          4. Umowa najmu zawierana jest na czas określony, liczony od dnia
          otrzymania przez Użytkownika wiadomości elektronicznej wskazanej w
          ppkt 2 powyżej do ostatniego dnia Rezerwacji, przy czym okres najmu
          jest zgodny z datami Rezerwacji. Czas trwania Umowy najmu oraz okres
          najmu ulega przedłużeniu w przypadku opisanym w pkt XII ppkt 4 lit. b)
          Regulaminu poniżej.
        </p>
        <p>
          5. Utrwalenie, zabezpieczenie, udostępnienie oraz potwierdzenie
          istotnych postanowień Umowy najmu Towarów następuje poprzez przesłanie
          wskazanej powyżej wiadomości elektronicznej wraz z załącznikami w
          postaci niniejszego Regulaminu, pouczenia o prawie odstąpienia od
          umowy oraz wzoru formularza oświadczenia o odstąpieniu od umowy.
        </p>
        <h4>IX. Płatności i czas realizacji zamówienia</h4>
        <p>
          1. Klient zobowiązany jest zapłacić Ostateczną kwotę do zapłaty w
          terminie: 2 godzin od momentu potwierdzenia przez Usługodawcę
          zamówienia Klienta i zawarcia Umowy najmu (tzw. przedpłata). W
          przypadku braku zapłaty Ostatecznej kwoty do zapłaty w terminie, o
          którym mowa w zdaniu poprzedzającym, Usługodawca anuluje zamówienie
          Klienta i poinformuje o tym Klienta za pomocą wiadomości
          elektronicznej wysłanej na adres e-mail Klienta podany w formularzu
          Rejestracji. Usługodawca nie jest zobowiązany do realizacji
          anulowanych zamówień.
        </p>
        <p>
          2. Usługodawca umożliwia realizację płatności za pośrednictwem
          bezpiecznego serwisu do płatności internetowych tj. PayU.
        </p>
        <p>
          3. Towar stanowiący przedmiot Umowy najmu dostarczany jest wyłącznie
          na terytorium Rzeczpospolitej Polskiej za pośrednictwem podmiotów
          świadczących usługi pocztowe lub przewozowe lub kurierskie.
        </p>
        <p>
          4. Usługodawca przystąpi do realizacji złożonego zamówienia na 3 dni
          przed zadeklarowaną przez Użytkownika datą rozpoczęcia Umowy najmu
          zgodną z Rezerwacją, nie wcześniej jednak niż po zaksięgowaniu całej
          Ostatecznej kwoty do zapłaty na rachunku bankowym Usługodawcy.
        </p>
        <p>
          5. Towar objęty przedmiotem Umowy najmu zostanie przekazany podmiotowi
          świadczącemu usługi pocztowe lub przewozowe lub kurierskie nie później
          niż na 2 dni robocze przed dniem rozpoczęcia okresu najmu zgodnie z
          Rezerwacją. Towar zostanie dostarczony na adres wskazany przez
          Użytkownika w trakcie składania zamówienia.
        </p>
        <p>
          6. Szczegółowe informacje na temat sposobów dostarczania Towarów, w
          tym koszty dostarczenia Towarów stanowią Załącznik nr 2 do niniejszego
          Regulaminu.
        </p>
        <p>
          7. Każdemu Klientowi zawierającemu Umowę najmu przekazana zostanie
          faktura VAT na adres e-mail Klienta, podany przez Klienta w formularzu
          Rejestracji. Klient, akceptując niniejszy Regulamin udziela
          Usługodawcy akceptacji – w rozumieniu art. 106n ust. 1 ustawy z dnia
          11 marca 2004 r. o podatku od towarów i usług (VAT) – na stosowanie
          przez Usługodawcę w stosunku do Klienta faktur elektronicznych, w tym
          na przesyłanie (udostępnianie) Klientowi faktur elektronicznych
          wystawionych przez Usługodawcę w formacie pliku PDF za pomocą poczty
          elektronicznej na adres e-mail Klienta podany przez Klienta w trakcie
          uzupełniania formularza Rejestracji.
        </p>
        <p>
          8. Akceptacja, o której mowa w ust. 7 powyżej nie wyłącza prawa
          Usługodawcy do stosowania faktur VAT w wersji papierowej.
        </p>
        <p>
          9. Klient w każdym czasie ma możliwość cofnięcia – poprzez
          poinformowanie o tym Usługodawcę – udzielonej akceptacji, o której
          mowa w ust. 7 powyżej.
        </p>
        <h4>X. Prawo do odstąpienia od umowy</h4>
        <p>
          1. Konsument, który zawarł umowę na odległość, może w terminie 14 dni
          od dnia zawarcia tej umowy, odstąpić od tej umowy bez podawania
          jakiejkolwiek przyczyny i bez ponoszenia kosztów, z wyjątkiem kosztów
          określonych w Pouczeniu o prawie odstąpienia od umowy - zawierającym
          informacje o sposobie i terminie wykonania prawa odstąpienia od umowy
          oraz o kosztach zwrotu rzeczy w przypadku odstąpienia od umowy
          ponoszonych przez Konsumenta - stanowiącym Załącznik nr 2 do
          Regulaminu.
        </p>
        <p>
          2. W przypadku, gdy na wyraźne żądanie Konsumenta rozpoczęcie okresu
          najmu (zgodnie z Rezerwacją) przypada przed upływem terminu do
          odstąpienia od Umowy najmu, a Konsument skorzysta z prawa odstąpienia
          od tej Umowy, wówczas Konsument ma obowiązek zapłaty za świadczenia
          spełnione do chwili odstąpienia od tej Umowy. Kwotę zapłaty oblicza
          się proporcjonalnie do zakresu spełnionego świadczenia, z
          uwzględnieniem Ostatecznej kwoty do zapłaty. Usługodawca zwróci
          Konsumentowi Ostateczną kwotę do zapłaty, pomniejszoną o kwotę, o
          której mowa w zdaniu poprzedzającym, za pośrednictwem operatora
          płatności, przy wykorzystani usług którego Konsument opłacił
          zamówienie.
        </p>
        <p>
          3. Postanowienie ust. 1 i 2 stosuje się również do osoby fizycznej
          zawierającej umowę bezpośrednio związaną z jej działalnością
          gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej
          osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu
          wykonywanej przez nią działalności gospodarczej, udostępnionego na
          podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności
          Gospodarczej.
        </p>
        <p>
          4. Niezależnie od powyższego, każdemu Klientowi zawierającemu Umowę
          najmu za pośrednictwem Strony internetowej Wypożyczalni, Usługodawca
          udziela dodatkowego prawa odstąpienia od Umowy w terminie 1 dnia od
          dnia dostarczenia Towaru do Klienta na zasadach przewidzianych w
          Załączniku nr 3 do Regulaminu.
        </p>
        <h4>XI. Procedura rozpatrywania reklamacji</h4>
        <p>
          1. Na Usługodawcy ciąży obowiązek dostarczenia Klientowi Towarów w
          stanie przydatnym do umówionego użytku wynikającego z przeznaczenia
          danego Towaru i utrzymywanie Towarów w takim stanie przez czas trwania
          Umowy najmu. Towary mogą nosić ślady prawidłowego ich używania,
          niewpływające na ich walory użytkowe, funkcjonale lub estetyczne.
        </p>
        <p>
          2. Reklamacja może zostać złożona przez Użytkownika w dowolny sposób
          dostatecznie ujawniający jego wolę.
        </p>
        <p>
          3. Celem ułatwienia Klientowi złożenia reklamacji Towarów,
          Usługodawca: <br />
          a) rekomenduje, aby zgłoszenie reklamacji Towarów zawierało w
          szczególności następujące dane: imię, nazwisko, adres e-mail, nr konta
          bankowego podany w formularzu Rejestracji, adres (ulica, nr domu/nr
          mieszkania, kod pocztowy, miejscowość (poczta)), nr telefonu, forma
          rekompensaty, nr zamówienia, informacja jaki konkretnie Towar jest
          reklamowany i z jakiego powodu,
          <br />
          b) informuje o możliwości skorzystania przez Klienta z przykładowej
          procedury reklamacyjnej opisanej w pkt XI, ppkt 6 niniejszego
          Regulaminu. <br />Z powyższych zaleceń Usługodawcy Klient nie musi
          korzystać, ani się nim kierować, a brak skorzystania nie wpływa na
          skuteczność reklamacji zgłoszonych z pominięciem zalecanego opisu
          reklamacji.
        </p>
        <p>
          4. W przypadku, gdy Towar:
          <br />
          a) ma wady, które ograniczają jego przydatność do użytku, wynikającego
          z przeznaczenia danego Towaru, wówczas Klient może żądać odpowiedniego
          obniżenia czynszu za czas trwania wad lub dokonać zwrotu Towaru i
          żądać zwrotu Ostatecznej kwoty do zapłaty w pełnej wysokości,
          <br />
          b) ma wady, które wyłączają jego przydatność do użytku, wynikającego z
          przeznaczenia danego Towaru, wówczas Klient może zwrócić Towar i żądać
          zwrotu Ostatecznej kwoty do zapłaty w pełnej wysokości.
        </p>
        <p>
          5. Usługodawca udostępnia poniżej przykładową procedurę reklamacyjną
          Towarów: <br /> a) aby rozpocząć proces reklamacji Usługodawca zaleca,
          aby Klient wysłał wiadomość e-mail na adres :
          wypozyczalnia@4Fchange.com w tytule wpisując „Reklamacja, zamówienie
          nr…:”, zaś w samej treści podał swoje imię i nazwisko, numer
          zamówienia wraz z informacją, jaki konkretny Towar jest reklamowany i
          z jakiego powodu, adres email, adres korespondencyjny, nr telefonu, nr
          konta bankowego (jeśli inny niż podany w formularzu Rejestracji),
          formę rekompensaty,
          <br />
          b) Usługodawca potwierdzi w formie wiadomości e-mail przyjęcia
          zgłoszenia reklamacyjnego, wskazując w treści wiadomości numer
          reklamacji Towaru,
          <br />
          c) Usługodawca zaleca, aby Towar został spakowany wraz z dołączeniem
          dowodu zawarcia umowy najmu (faktura VAT) i odesłany na adres
          Usługodawcy: ul. Wielicka 250, 30-663 Kraków.
          <br />
          d) o sposobie rozpatrzenia reklamacji Usługodawca poinformuje Klienta
          poprzez wiadomość email. W razie pytań odnośnie reklamacji Usługodawca
          zaleca kontakt z Działem Reklamacji: wypozyczalnia@4Fchange.com
        </p>
        <p>
          6. Reklamacje dotyczące funkcjonowania Strony internetowej
          Wypożyczalni lub usług świadczonych przez Usługodawcę można składać w
          szczególności poprzez wysłanie wiadomości e-mail na adres:
          wypozyczalnia@4Fchange.com
        </p>
        <p>
          7. Usługodawca rozpatrzy każdą reklamację i ustosunkuje się do niej
          udzielając odpowiedzi o sposobie jej rozpatrzenia niezwłocznie, nie
          później niż w terminie 14 dni od daty złożenia reklamacji. O sposobie
          rozpatrzenia reklamacji Użytkownik zostanie poinformowany w sposób
          wskazany w zgłoszeniu reklamacyjnym.
        </p>
        <p>
          8. W przypadku wystąpienia ewentualnych braków w złożonej reklamacji,
          Usługodawca zwróci się do Użytkownika o ich uzupełnienie zgodnie z
          danymi adresowymi Użytkownika wskazanymi w tej reklamacji.
        </p>
        <h4>XII. Odpowiedzialność Klienta za Towar</h4>
        <p>
          1. Klient zobowiązany jest korzystać z Towaru w sposób odpowiadający
          właściwościom i przeznaczeniu Towaru, tj. korzystać z Towaru w celu
          uprawiania sportów zimowych takich jak: jazda na nartach i
          snowboardzie, a także zobowiązany jest zwrócić Towar Usługodawcy na
          adres Usługodawcy: ul. Wielicka 250, 30-663 Kraków w terminie 1 dnia
          roboczego liczonego od dnia zakończenia okresu najmu zgodnym z
          Rezerwacją.
        </p>
        <p>
          2. Dla zachowania terminu zwrotu wystarczające jest przekazanie Towaru
          podmiotowi świadczącemu usługi pocztowe, przewozowe lub kurierskie w
          terminie, o którym mowa w zdaniu poprzedzającym. Usługodawca zapewnia
          usługę odbioru Towaru od Klienta przez kuriera w terminie, o którym
          mowa powyżej, o ile Klient wybierze taką opcję przy składaniu
          zamówienia.
        </p>
        <p>
          3. Towar powinien zostać zwrócony w stanie niepogorszonym, z
          uwzględnieniem jednak zużycia Towaru, będącego następstwem
          prawidłowego używania Towaru.
        </p>
        <p>
          4. W przypadku, gdy: <br />
          a) Towar będący przedmiotem Umowy najmu zostanie zwrócony przez
          Klienta po upływie 4 dni liczonych od dnia zakończenia okresu najmu
          (zgodnie z Rezerwacją) – Usługodawca jest uprawniony do naliczenia
          Klientowi dodatkowej kwoty czynszu wg podstawowej stawki czynszu najmu
          (stawka dzienna przy najmie na 7 dni) dla danego Towaru zgodnie z
          Cennikiem, stanowiącym Załącznik nr1 do niniejszego Regulaminu, za
          każdy dzień <br /> b) Towar będący przedmiotem Umowy najmu nie
          zostanie zwrócony przez Klienta w terminie 3 miesięcy od daty
          zakończenia okresu najmu (zgodnie z Rezerwacją) – Usługodawca, oprócz
          naliczenia kwoty czynszu najmu, o której mowa pod lit. a) powyżej jest
          uprawniony dodatkowo do zatrzymania 100% wysokości kaucji oraz
          obciążenia Klienta kwotą stanowiącą równowartość Towaru będącego
          przedmiotem Umowy najmu, pomniejszonej o wysokość kaucji. Wartość
          Towaru wynika z Cennika, stanowiącego Załącznik nr 1 do Regulaminu. W
          wyniku obciążenia Klienta kwotą, o której mowa w zdaniu poprzedzającym
          nie dochodzi do przeniesienia własności Towaru na Klienta;
          <br />
          c) Towar będący przedmiotem Umowy najmu zostanie zwrócony przez
          Klienta w stanie pogorszonym tak dalece, że niemożliwym będzie
          korzystanie z Towaru w sposób odpowiadający właściwościom i
          przeznaczeniu tego – Usługodawca jest uprawniony do zatrzymania 100%
          wysokości kaucji. <br />
        </p>
        <p>
          5. Usługodawca jest uprawniony do potrącania kwot ustalonych na
          podstawie ppkt 4 lit. a) powyżej z kwoty kaucji na podstawie zgody
          Klienta wyrażonej przy pomocy odpowiedniego checkbox’a w toku
          Rejestracji. W wyniku potrącenia obie wierzytelności umarzają się
          nawzajem do wysokości wierzytelności niższej. O dokonaniu potrącenia
          Usługodawca poinformuje Klienta wiadomością elektroniczną na adres
          e-mail Klienta podany w toku Rejestracji.
        </p>
        <h4>
          XIII. Wymagania techniczne niezbędne do współpracy z systemem
          teleinformatycznym, którym posługuje się Usługodawca
        </h4>
        <p>
          1. Do korzystania z Wypożyczalni, w tym przeglądania asortymentu
          Wypożyczalni, niezbędne jest urządzenie końcowe z dostępem do
          Internetu i zainstalowaną przeglądarką internetową typu: Internet
          Explorer, Mozilla Firefox, Opera, Google Chrome.
        </p>
        <p>
          2. Do składania zamówień niezbędne jest ponadto posiadanie aktywnego
          konta poczty e-mail.
        </p>
        <h4>XIV. Ochrona danych osobowych</h4>
        <p></p>
        <p>
          1. Administratorem danych osobowych (w rozumieniu art. 4 pkt 7 RODO)
          Użytkowników korzystających z funkcjonalności Wypożyczalni jest
          Usługodawca, to jest OTCF S.A.
        </p>
        <p>
          2. Usługodawca wyznaczył Inspektora Ochrony Danych (IOD), z którym
          można skontaktować się w sprawach dotyczących ochrony danych osobowych
          oraz realizacją praw z tym związanych. W tym celu możliwy jest kontakt
          drogą elektroniczną na adres iod@otcf.pl, a także pocztą tradycyjną na
          adres OTCF S.A., ul. Saska 25C, 30-720 Kraków (z dopiskiem „Inspektor
          Ochrony Danych (IOD)”).
        </p>
        <p>
          3. Dane osobowe Użytkowników mogą być przetwarzane w następujących
          celach oraz na poniższych podstawach prawnych: <br />
          a) przyjmowania zamówień oraz realizacji Umowy najmu (podstawa prawna
          przetwarzania danych: art. 6 ust. 1 lit. b RODO),
          <br />
          b) bieżącej komunikacji w sprawach związanych ze złożonymi
          zamówieniami, w tym ich potwierdzaniem i informowaniem o statusie
          (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),
          <br />
          c) umożliwienia Rejestracji oraz zapewniania innych funkcjonalności za
          pośrednictwem Wypożyczalni, o ile zostały wskazane w niniejszym
          Regulaminie, w ramach zawartej z Użytkownikiem umowy o świadczenie
          usług drogą elektroniczną (podstawa prawna przetwarzania danych: art.
          6 ust. 1 lit. b RODO), <br />
          d) umożliwienia korzystania z funkcjonalności innych formularzy, o
          których mowa w Rozdziale VI Regulaminu w ramach zawartej z
          Użytkownikiem umowy o świadczenie usług drogą elektroniczną (podstawa
          prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO) zgodnie z
          niniejszym Regulaminem, a także na podstawie szczególnych regulaminów
          określających zasady korzystania z konkretnych formularzy, o ile będą
          one obowiązywały w konkretnych przypadkach zgodnie z decyzją podjętą
          przez Usługodawcę (w takim wypadku regulaminy te mogą określać
          dodatkowe cele i podstawy prawne przetwarzania danych osobowych),
          <br />
          e)rozpatrywania reklamacji związanych z zawartymi Umowami najmu (art.
          6 ust. 1 lit. b RODO), <br />
          f) rozpatrywania reklamacji związanych z zawartą umową o świadczenie
          usług drogą elektroniczną – w sytuacji gdy Użytkownik taką umowę
          zawarł z Usługodawcą na zasadach określonych w niniejszym Regulaminie
          (art. 6 ust. 1 lit. b RODO), <br />
          g) przyjmowania i obsługi innych niż reklamacje oraz sprawy związane z
          realizowanymi umowami, zgłoszeń i zapytań kierowanych do Usługodawcy
          (np. za pośrednictwem danych kontaktowych wskazanych na Stronie
          internetowej Wypożyczalni), co stanowi prawnie uzasadniony interes
          Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit.
          f RODO), <br />
          h) przyjmowania oświadczeń odstąpienia od zawartych Umów najmu na
          odległość, zgodnie z postanowieniami niniejszego Regulaminu oraz
          przepisami Rozdziału 4 ustawy z dnia 30 maja 2014 r. o prawach
          konsumenta, co stanowi prawnie uzasadniony interes Usługodawcy
          (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
          <br />
          i) rozpatrywania i dochodzenia roszczeń, obrony przed roszczeniami, a
          także w celach realizacji pozasądowych sposobów rozpatrywania
          reklamacji i dochodzenia roszczeń, co stanowi prawnie uzasadniony
          interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust.
          1 lit. f RODO), <br />
          j) monitorowania sposobu korzystania z usług świadczonych w ramach
          Wypożyczalni przez Użytkowników, pod kątem przestrzegania postanowień
          Regulaminu, a także rozwijania funkcjonalności Wypożyczalni, poprawy
          działania świadczonych usług za jego pośrednictwem, co stanowi prawnie
          uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych:
          art. 6 ust. 1 lit. f RODO) <br />
          k) w celach marketingu bezpośredniego, w tym profilowania, poprzez
          dobór i wyświetlanie dostępnych towarów Wypożyczalni z uwzględnieniem
          aktywności i preferencji konkretnych Użytkowników, a także poprzez
          tworzenie dopasowanych grup odbiorców reklam z uwzględnieniem ich
          preferencji, co stanowi prawnie uzasadniony interes Usługodawcy
          (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),
          <br />
          l) prowadzenia analiz statystycznych, co stanowi prawnie uzasadniony
          interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust.
          1 lit. f RODO), <br />
          m) realizacji wymagań prawnych w zakresie przepisów podatkowych i
          rachunkowych, w szczególności określonych przepisami ustawy z dnia 11
          marca 2004 r. o podatku od towarów i usług (VAT), ustawy z dnia z dnia
          15 lutego 1992 r. o podatku dochodowym od osób prawnych oraz ustawy z
          dnia 29 września 1994 r. o rachunkowości (podstawa prawna
          przetwarzania danych: art. 6 ust. 1 lit. c RODO), <br />
          n) przechowywania danych dla celów archiwizacyjnych oraz na potrzeby
          wykazywania prawidłowości spełnienia obowiązków prawnych
          spoczywających na Usługodawcy, co stanowi jego prawnie uzasadniony
          interes (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f
          RODO), <br />
          o) prowadzenia badań satysfakcji Użytkowników - jeśli konkretna osoba
          wyraziła odrębną zgodę na udział w takim badaniu (podstawa prawna
          przetwarzania danych: art. 6 ust. 1 lit. a RODO); <br />
          p) zapisywania danych w postaci plików cookies, gromadzenia danych ze
          Strony internetowej Wypożyczalni– jeśli konkretna osoba wyraziła na to
          odrębną zgodę na zasadach określonych w Polityce Plików Cookies
          obowiązującej na stronie internetowej https://4fchange.com.
        </p>
        <p>
          4. Dane osobowe Użytkowników mogą być ujawniane następującym
          kategoriom odbiorców:
          <br />
          a) podwykonawcom zapewniającym wsparcie techniczne Usługodawcy w
          prowadzeniu i utrzymaniu, a także rozwoju Wypożyczalni takim jak:
          podmioty świadczące usługi hostingu, dostawcy oprogramowania do
          zarządzania Wypożyczalnią, podmioty świadczące obsługę techniczną
          oprogramowania Wypożyczalni, dostawcy zapewniający oprogramowanie do
          wysyłania korespondencji handlowej drogą elektroniczną, dostawcy
          zapewniający obsługę Biura Obsługi Klienta, z którymi Usługodawca
          zawarł wymagane prawnie umowy powierzenia przetwarzania danych
          osobowych;
          <br />
          b) podmiotom wspierającym Usługodawcę w prowadzeniu działań
          marketingowych, takim jak podmioty prowadzące portale internetowe, w
          tym portale typu social media;
          <br />
          c) podmiotom wspierającym Usługodawcę w realizacji programów badania
          satysfakcji Użytkowników, w szczególności za pośrednictwem ankiet
          przeprowadzanych drogą elektroniczną lub telefoniczną; podmiotom
          wspierającym realizację przez Usługodawcę obowiązujących przepisów
          prawa oraz uprawnień i obowiązków wynikających z niniejszego
          Regulaminu, w związku ze świadczeniem usług za pośrednictwem
          Wypożyczalni, takim jak: kancelarie prawne oraz firmy windykacyjne;
          <br />
          d) podmiotom, którym przekazanie danych jest niezbędne w celu
          zapewnienia prawidłowej realizacji usług za pośrednictwem
          Wypożyczalni, zgodnie z żądaniem konkretnego Użytkownika – podmiotom
          świadczącym usługi płatności elektronicznych, podmiotom zapewniającym
          dostawę towaru pod wskazany adres (usługi pocztowe, przewozowe lub
          kurierskie, firmy spedytorskie), którym dane są udostępniane jako
          odrębnym administratorom lub z którymi Usługodawca zawarł wymagane
          prawnie umowy powierzenia przetwarzania danych osobowych (w zależności
          od statusu tych podmiotów pełnionego wobec przekazanych danych
          osobowych).
        </p>
        <p>
          5. Dane osobowe Użytkowników mogą być przekazywane przez Usługodawcę
          poza teren Europejskiego Obszaru Gospodarczego (EOG) w ramach
          korzystania przez Usługodawcę z usług podwykonawców (spośród kategorii
          odbiorców wskazanych w punkcie 4 wyżej). W takim wypadku Usługodawca
          gwarantuje wymagane prawnie środki ochrony danych osobowych, którymi
          będą (w zależności od przypadku): i) przekazanie danych do
          podwykonawcy zlokalizowanego w państwie trzecim, w stosunku do którego
          została wydana decyzja stwierdzająca odpowiedni stopień ochrony,
          zgodnie z wymaganiami art. 45 RODO, ii) przekazanie danych realizowane
          na podstawie zawartej z podwykonawcą umowy o transfer danych opartej
          na Standardowych Klauzulach Umownych przyjętych w drodze decyzji przez
          Komisję Europejską, iii) przekazanie danych realizowane w ramach
          stosowanych przez podwykonawcę wiążących reguł korporacyjnych, o
          których mowa w art. 47 RODO. Więcej informacji dotyczących stosowanych
          przez Usługodawcę środków bezpieczeństwa związanych z przekazywaniem
          danych poza obszar EOG można uzyskać kontaktując się z Inspektorem
          Ochrony Danych wyznaczonym przez Usługodawcę.
        </p>
        <p>
          6. Pozyskane dane osobowe będą przechowywane przez Usługodawcę przez
          okres realizacji zawartych Umów najmu Towarów i do czasu ich
          prawidłowego rozliczenia, a także przez okres świadczenia usług
          (Użytkownikom) przez czas obowiązywania umów o świadczenie usług drogą
          elektroniczną, a ponadto: <br />
          a) do czasu przedawnienia potencjalnych roszczeń wynikających z umów
          wskazanych powyżej, <br />
          b) przez czas niezbędny do dochodzenia konkretnych roszczeń, z którymi
          wystąpił Usługodawca lub ich odparcia (jeżeli z roszczeniami wystąpił
          Użytkownik w związku z zawartymi umowami, o których mowa wyżej, <br />
          c) przez czas wykonywania obowiązków wynikających z przepisów prawa, w
          tym w szczególności w zakresie regulacji podatkowych i rachunkowych,
          np. obowiązków związanych z przechowywaniem dokumentacji zgodnie z
          wymaganiami art. 74 ustawy z dnia 29 września 1994 r. o rachunkowości,
          <br />
          d) przez okres niezbędny do udokumentowania przez Usługodawcę przed
          organami administracji publicznej, w tym organem nadzoru w zakresie
          ochrony danych osobowych, prawidłowości spełnienia obowiązków prawnych
          na nim spoczywających, <br />
          e) w celach archiwizacyjnych, gdy dotyczy to historii prowadzonej
          korespondencji i udzielanych odpowiedzi na zgłaszane zapytania
          (niezwiązane bezpośrednio z zawartymi umowami) – przez okres nie
          dłuższy niż 3 lata od pozyskania danych, <br />
          f) w celach marketingu bezpośredniego – przez czas obowiązywania umowy
          o świadczenie usług drogą elektroniczną (Użytkownicy) oraz przez czas
          realizacji Umów najmu Towarów lub do czasu zgłoszenia sprzeciwu na
          przetwarzanie danych w tym celu,
          <br />
          g) do czasu wycofania zgody na przetwarzanie danych lub ich
          dezaktualizacji (stwierdzonej przez Usługodawcę) – jeżeli
          przetwarzanie danych odbywa się za zgodą udzieloną przez konkretną
          osobę.
        </p>
        <p>
          7. Usługodawca zapewnia każdemu Użytkownikowi prawo skorzystania ze
          wszystkich przysługujących im uprawnień przewidzianych na gruncie
          RODO, tj. prawa żądania dostępu do swoich danych osobowych, prawie ich
          sprostowania, usunięcia lub żądania ograniczenia przetwarzania, prawa
          do przenoszenia danych, a także prawa wniesienia sprzeciwu na ich
          przetwarzanie, na zasadach i w przypadkach przewidzianych w przepisach
          RODO.
        </p>
        <p>
          8. W przypadku przetwarzania danych osobowych przez Usługodawcę w celu
          realizacji prawnie uzasadnionych interesów (o których była mowa
          powyżej), każdemu Użytkownikowi przysługuje prawo do wniesienia
          sprzeciwu na przetwarzanie danych z przyczyn związanych z jego
          szczególną sytuacją.
        </p>
        <p>
          9. Dane przetwarzane w celu realizacji prawnie uzasadnionego interesu
          polegającego na prowadzeniu marketingu bezpośredniego Usługodawcy będą
          przetwarzane wyłącznie do czasu zgłoszenia sprzeciwu na tego typu
          formę przetwarzania. Użytkownikowi przysługuje prawo złożenia
          sprzeciwu na przetwarzanie jego danych osobowych na potrzeby
          marketingu bezpośredniego, w tym poprzez profilowanie, w każdym
          czasie.
        </p>
        <p>
          10. W przypadku przetwarzania przez Usługodawcę danych osobowych za
          zgodą wyrażoną przez Użytkownika, każdej osobie przysługuje prawo
          wycofania w dowolnym momencie zgody na przetwarzanie swoich danych bez
          wpływu na zgodność z prawem przetwarzania tych danych, które miało
          miejsce przed cofnięciem zgody.
        </p>
        <p>
          11. Podanie danych osobowych w odniesieniu do: <br />
          a) Użytkowników, którzy chcą zarejestrować się na Stronie internetowej
          Wypożyczalni – w celu Rejestracji niezbędne jest podanie danych w
          zakresie wskazanym w formularzu rejestracyjnym, tj. imię, nazwisko,
          adres zamieszkania, adres e-mail, numer telefonu. Niepodanie tych
          danych uniemożliwi Rejestrację (a w konsekwencji zawarcie umowy o
          świadczenie usług drogą elektroniczną), <br />
          b) Użytkowników, którzy składają zamówienia za pośrednictwem
          Wypożyczalni – w celu złożenia i umożliwienia Usługodawcy realizacji
          zamówienia (i tym samym Umowy najmu) niezbędne jest podanie danych w
          zakresie: imię, nazwisko, adres zamieszkania (lub inny adres do
          dostawy), adres e-mail, numer telefonu. Niepodanie tych danych będzie
          skutkować brakiem możliwości przyjęcia zamówienia (a tym samym
          zawarcia Umowy najmu); <br />
          c) Użytkowników, którzy składają oświadczenie o odstąpieniu od Umowy
          najmu Towarów – w celu złożenia oświadczenia woli o odstąpieniu od
          Umowy najmu Towarów zawartej na odległość niezbędne jest podanie
          danych w zakresie: imię, nazwisko, adres e-mail, adres (ulica, nr
          domu/nr mieszkania, kod pocztowy, miejscowość (poczta)), nr telefonu,
          numer zamówienia. Niepodanie tych informacji uniemożliwi skuteczne
          złożenie oświadczenia woli o odstąpieniu od Umowy najmu, <br />
          d) Użytkowników, którzy składają reklamacje w związku z zawartą Umową
          najmu Towarów – w celu złożenia reklamacji i umożliwienia jej
          rozpatrzenia przez Usługodawcę niezbędne jest podanie danych w
          zakresie: imię, nazwisko, adres e-mail, nr konta bankowego, adres
          (ulica, nr domu/nr mieszkania, kod pocztowy, miejscowość (poczta)),
          numer telefonu, formę oczekiwanej rekompensaty, numer zamówienia,
          podanie informacji jaki konkretnie Towar reklamują i z jakiego powodu.
          Niepodanie tych danych uniemożliwi Usługodawcy rozpatrzenie złożonej
          reklamacji, <br />
          e) Użytkowników, którzy składają reklamacje w związku z zawartą z
          Usługodawcą umową o świadczenie usług drogą elektroniczną na zasadach
          określonych w niniejszym Regulaminie – w celu złożenia reklamacji i
          umożliwienia jej rozpatrzenia przez Usługodawcę niezbędne jest podanie
          adresu e-mail, który Użytkownik podał w trakcie Rejestracji lub
          korzystania z innego formularza dostępnego na Stronie internetowe
          Wypożyczalni. Niepodanie tych danych może uniemożliwić Usługodawcy
          rozpatrzenie złożonej reklamacji, <br />
          f) pozostałych przypadków - jest dobrowolne.
        </p>
        <p>
          12. Usługodawca nie będzie prowadził operacji przetwarzania danych
          Użytkowników w sposób zautomatyzowany, które jednocześnie będą
          prowadziły do podejmowania wobec nich decyzji wywołujących skutki
          prawne lub w podobny sposób istotnie wpływających na ich sytuację.
          Ewentualne przetwarzanie danych w sposób zautomatyzowany, w tym
          poprzez profilowanie, będzie służyło wyłącznie analizom i prognozom
          indywidualnych preferencji Użytkowników korzystających z Wypożyczalni.
        </p>
        <p>
          13. Każdej osobie, której dane osobowe są przetwarzane przez
          Usługodawcę przysługuje prawo wniesienia skargi w związku z
          przetwarzaniem jej danych osobowych do organu nadzorczego, którym jest
          Generalny Inspektor Ochrony Danych Osobowych, a od momentu wejścia w
          życie nowej ustawy o ochronie danych osobowych jego następca prawny –
          Prezes Urzędu Ochrony Danych Osobowych, mający siedzibę przy ul.
          Stawki 2, 00-193 Warszawa.
        </p>
        <h4>
          XV. Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia
          roszczeń.
        </h4>
        <p>
          1. Konsument może skorzystać z pozasądowych sposobów rozpatrywania
          reklamacji i dochodzenia roszczeń. Zasady dostępu do tych procedur
          dostępne są w siedzibach lub na stronach internetowych podmiotów
          uprawnionych do pozasądowego rozpatrywania sporów, takich jak w
          szczególności: rzecznicy praw konsumenta lub Wojewódzkie Inspektoraty
          Inspekcji Handlowej, których lista jest dostępna na stronie
          internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem
          http://www.uokik.gov.pl/
        </p>
        <p>
          2. Pod adresem http://ec.europa.eu/consumers/odr dostępna jest
          platforma internetowego systemu rozstrzygania sporów pomiędzy
          konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR).
          Platforma ODR stanowi interaktywną i wielojęzyczną stronę internetową
          z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców
          dążących do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań
          umownych wynikających z internetowej umowy o świadczenie usług.
        </p>
        <h4>XVI. Postanowienia końcowe</h4>
        <p>
          1. Regulamin dostępny jest nieodpłatnie na Stronie internetowej
          Wypożyczalni: https://4fchange.com/regulamin-wypozyczalni/ w postaci
          umożliwiającej jego pozyskanie, odtwarzanie i utrwalanie za pomocą
          systemu teleinformatycznego Użytkownika.
        </p>
        <p>
          2. Regulamin jest także przesyłany - w postaci umożliwiającej jego
          pozyskanie, odtwarzanie i utrwalanie przez Użytkownika - na adres
          poczty elektronicznej podany przez Użytkownika w przypadku zawarcia ze
          Usługodawcą umowy o świadczenie usług drogą elektroniczną lub przez
          Klienta w przypadku zawarciu Umowy najmu Towarów za pośrednictwem
          Wypożyczalni.
        </p>
        <p>
          3. Postanowienia Regulaminu nie mają na celu ograniczyć lub wyłączyć
          jakichkolwiek praw Użytkowników wynikających z przepisów prawa.
          Usługodawca honoruje wszelkie prawa Użytkowników przewidziane w
          przepisach obowiązującego prawa, a w szczególności przewidziane w
          przepisach ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny oraz
          ustawy z dnia 30 kwietnia 2014 r. o prawach konsumenta.
        </p>
        <p>
          4. Usługodawca zastrzega sobie prawo do dokonywania zmian Regulaminu z
          ważnych przyczyn obejmujących: <br />
          a) zmiany w prawie; <br />
          b) przyczyny organizacyjne, w szczególności: <br />
          <p>
            i. zmiany w zakresie prowadzonej przez Usługodawcę działalności w
            postaci zmian jej profilu, zmian zakresu asortymentu Towarów,
            wprowadzania nowych usług lub udogodnień (w tym związanych z
            zawieraniem i rozwiązywaniem umów),
            <br />
            ii. zmiany w zakresie: danych adresowych, nazwy lub formy prawnej
            Usługodawcy,
            <br />
            iii. zmiany w zakresie sposobów płatności,
            <br />
            iv. zmiany w zakresie sposobów dostarczenia Towarów, <br />
            v. inne zmiany techniczne związane z funkcjonowaniem Wypożyczalni.
          </p>
        </p>
        <p>
          5. Każdy Użytkownik zostanie poinformowany o zmianie Regulaminu
          poprzez opublikowanie go na Stronie internetowej Wypożyczalni.
        </p>
        <p>
          6. Zmiany Regulaminu wejdą w życie po upływie 7 dni kalendarzowych od
          momentu publikacji nowej wersji Regulaminu na Stronie internetowej
          Wypożyczalni. Do Umów najmu Towarów oraz wszelkich innych umów
          zawartych przez Użytkownika z Usługodawcą na podstawie niniejszego
          Regulaminu, których data zawarcia przypada przed zmianą Regulaminu,
          stosuje się postanowienia Regulaminu w brzmieniu obowiązującym w dacie
          zawarcia danej umowy.
        </p>
        <p>7. Regulamin Wypożyczalni obowiązuje od dnia 24.01.2022 r. </p>
        <p>
          <strong>Do pobrania:</strong>
        </p>
        <p>
          1.{" "}
          <a href="/regulamin_wypozyczalni.pdf" target="_blank">
            Regulamin wypożyczalni
          </a>
        </p>
        <p>
          2. Załączniki do regulaminu:
          <ul>
            <li>
              <a href="/regulamin_wypozyczalni_zalacznik_1.pdf" target="_blank">
                Cennik
              </a>
            </li>
            <li>
              <a href="/regulamin_wypozyczalni_zalacznik_2.pdf" target="_blank">
                Sposoby i koszty dostawy i zwrotu
              </a>
            </li>
            <li>
              <a href="/regulamin_wypozyczalni_zalacznik_3.pdf" target="_blank">
                Informacje dotyczące korzystania z prawa do odstąpienia od umowy
              </a>
            </li>
            <li>
              <a href="/regulamin_wypozyczalni_zalacznik_4.pdf" target="_blank">
                Wzór formularza odstąpienia od umowy
              </a>
            </li>
          </ul>
        </p>
      </div>
    </StyledDescriptionPage>
  </Layout>
)

export default RentalTerms
