import styled from "styled-components"

const StyledContent = styled.section`
  padding: 90px 0 130px;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  .rental-content {
    &__hero {
      text-align: center;

      h3 {
        max-width: 644px;
        margin: 0 auto;
      }

      p {
        font-size: 25px;
        line-height: 33px;
        max-width: 858px;
        margin: 30px auto 60px;

        @media (max-width: 991px) {
          margin: 20px auto;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    &__join {
      margin: 140px 0 0 0;
      text-align: center;

      @media (max-width: 991px) {
        margin: 90px 0 0 0;
      }

      @media (max-width: 575px) {
        margin: 50px 0 0 0;
      }

      h3 {
        margin: 40px auto 0;

        @media (max-width: 991px) {
          margin: 20px auto 0;
        }
      }

      p {
        font-size: 25px;
        line-height: 33px;
        max-width: 858px;
        margin: 60px auto;

        @media (max-width: 991px) {
          margin: 30px auto 10px;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;

      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &__item {
      text-align: center;

      @media (max-width: 768px) {
        margin: 30px auto 0;
      }

      &-thumb {
        position: relative;
        min-height: 430px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        svg,
        img {
          position: absolute;
          top: 30px;
          left: 30px;
        }
      }

      h4 {
        margin: 30px 0;

        @media (max-width: 768px) {
          margin: 20px 0;
        }
      }
    }
  }
`

export default StyledContent
