import styled from "styled-components"

const StyledViamoda = styled.div`
  .viamoda-content {
    &__divider-smaller {
      min-height: 50px;

      @media (max-width: 768px) {
        min-height: 25px;
      }
    }

    &__divider {
      min-height: 100px;

      @media (max-width: 768px) {
        min-height: 50px;
      }
    }

    &__description {
      border: 5px solid #00fb6e;
      padding: 40px;
      background-color: #fff;
      margin-bottom: -80px;
      position: relative;
      z-index: 1;
      width: calc(100% + 100px);

      @media (max-width: 1365px) {
        margin-bottom: -40px;
      }

      @media (max-width: 1199px) {
        width: 100%;
        padding: 30px;
      }

      @media (max-width: 768px) {
        padding: 20px;
      }

      @media (max-width: 575px) {
        margin-bottom: -20px;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      min-height: 700px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @media (max-width: 1365px) {
        min-height: 500px;
      }

      &-wrapper {
        width: 260%;
        left: -80%;
        min-height: 700px;
        position: relative;

        @media (max-width: 1365px) {
          min-height: 500px;
        }
      }
    }

    .slick-slider {
      width: 200%;
      margin-top: -100px;

      .slick-slide {
        margin: 0 30px 0 0;
      }
    }

    &__slider-nav {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      margin: 15px 0 50px 0;

      div {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 30px;

        span {
          display: flex;
          width: 24px;
          height: 1px;
          background-color: #000;
          margin: 0 10px;
        }
      }

      button {
        width: 45px;
        height: 45px;
        border: 1px solid #000;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 150ms linear;

        svg {
          position: relative;
          right: -1px;

          path {
            transition: all 150ms linear;
          }
        }

        &:first-child {
          svg {
            transform: rotate(180deg);
            right: 1px;
          }
        }

        &:hover {
          border-color: #00fb6e;

          svg {
            path {
              stroke: #00fb6e;
            }
          }
        }
      }
    }

    &__row-image {
      padding: 0 0 0 230px;
      position: relative;
      margin: 25px 0 0 0;

      @media (max-width: 575px) {
        padding: 0;
      }

      img {
        position: absolute;
        top: 0;
        right: calc(100% - 200px);

        @media (max-width: 575px) {
          position: relative;
          right: 0;
          margin: 0 auto 30px;
          width: 100%;
        }
      }

      &--reverse {
        padding: 0 230px 0 0;

        @media (max-width: 575px) {
          padding: 0;
        }

        img {
          left: calc(100% - 200px);

          @media (max-width: 575px) {
            left: 0;
          }
        }
      }
    }

    &__designers {
      position: relative;
      z-index: 1;
      background-color: #000;
      color: #fff;
      padding: 30px 40px;
      display: flex;
      align-items: flex-start;
      margin: 80px 0 0 120px;

      @media (max-width: 768px) {
        margin: 50px 0 0 0;
        padding: 30px;
      }

      @media (max-width: 480px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 30px 0 0 0;
      }

      h4 {
        color: #00fb6e;
        margin: 0 40px 0 0 !important;

        @media (max-width: 480px) {
          margin: 0 0 15px 0 !important;
        }
      }

      span {
        display: block;

        &:not(:last-child) {
          margin: 0 0 5px 0;
        }
      }

      &--reverse {
        margin: 100px 120px 0 0;

        @media (max-width: 768px) {
          margin: 50px 0 0 0;
        }
      }
    }

    &__copy {
      margin: 75px 0 0 0;

      @media (max-width: 575px) {
        margin: 50px 0 0 0;
      }

      h4 {
        margin: 15px 0 0 0 !important;
        text-transform: uppercase;
      }

      span {
        display: block;
        margin: 0 0 10px 0;
      }

      address {
        font-style: normal;
        margin: 30px 0 0 0;
        line-height: 24px;

        a {
          font-weight: 700;
        }
      }
    }

    &__join {
      margin: 75px 0 0 0;
      background-color: #00fb6e;
      padding: 40px;

      @media (max-width: 575px) {
        margin: 50px 0 0 0;
        padding: 20px;
      }

      p {
        font-weight: 700;
        margin: 0 0 50px 0;
        max-width: 384px;
      }

      h4 {
        text-transform: uppercase;
      }
    }
  }
`

export default StyledViamoda
