import React from "react"
import { Link } from "gatsby"
import { useLocale } from "context/locale"

import StyledBreadcrumbs from "./styles"

const Breadcrumbs = ({ title }) => {
  const { t } = useLocale()
  return (
    <StyledBreadcrumbs>
      <div className="main-container">
        <div className="breadcrumbs">
          <Link to={t("index_link")}>{t("Homepage")}</Link>
          <Link to={t("news_link")}>{t("News")}</Link>
          <span>{title}</span>
        </div>
      </div>
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
