import * as React from "react"

import Layout from "components/Layout"
import Main from "page_components/strategy/Main"
import Program from "page_components/strategy/Program"
import ChangeDirection from "page_components/strategy/ChangeDirection"

const Strategy = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title:
        "Strategy - we change ourselves and the world for the better – 4F Change",
      description:
        "We implement the Kaizen philosophy for all sustainability activities: we make changes through small improvements to move to bigger and bigger challenges over time.",
    }}
  >
    <Main location={location} />
    <Program />
    <ChangeDirection />
  </Layout>
)

export default Strategy
