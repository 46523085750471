import React from "react"
import OrderProvider from "context/order"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Instruction from "page_components/order/Instruction"
import Form from "page_components/order/Form"

const OrderCourier = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Order a Curier - 4F Change",
      description:
        "Order a Curier - 3 steps that separate you from giving your clothes a second life",
    }}
  >
    <PageHeader title="Order a curier" />
    <OrderProvider>
      <Instruction />
      <Form />
    </OrderProvider>
  </Layout>
)

export default OrderCourier
