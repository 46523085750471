import StyledWoshGoSub from "../WoshGoNubuk/styles"

import React from "react"

import Button from "components/Button"

const WoshGoSport = () => {
  const data = [
    {
      icon: require("images/blog/wosh-go-sport-ico-1.svg").default,
      description:
        "Przede wszystkim najlepiej zapobiegać! Zanim więc ubrudzimy i przemoczymy buty, zadbajmy o ich właściwą impregnację. Taka ochrona przyda się zwłaszcza na trasach pełnych błota, górskich lub po lesie. Impregnat (zazwyczaj w sprayu) zapobiegnie powstawaniu plam, przemakaniu i przywieraniu błota. ",
    },
    {
      icon: require("images/blog/wosh-go-sport-ico-2.svg").default,
      description:
        "Po przyjściu z treningu, dobrze jest poluzować sznurówki, żeby przewietrzyć wnętrze butów. Przemoczony materiał zużywa się szybciej, więc należy pamiętać, by przed kolejnym treningiem obuwie całkowicie wyschło. Buty najlepiej pozostawić do wyschnięcia w suchym, wentylowanym pomieszczeniu, ale nie na kaloryferze!",
    },
    {
      icon: require("images/blog/wosh-go-sport-ico-3.svg").default,
      description:
        "Ważne jest, by czyścić obuwie z zabrudzeń na bieżąco. Przy ręcznym praniu butów sportowych należy zamoczyć je w misce lub zlewie z letnią wodą i przetrzeć je gąbką lub szczoteczką o miękkich, nylonowych włóknach. Używaj mydła lub specjalistycznych środków do czyszczenia obuwia, ale nie agresywnych detergentów! ",
    },
    {
      icon: require("images/blog/wosh-go-sport-ico-4.svg").default,
      description:
        "Brudna podeszwa? Stara szczoteczka do zębów i roztwór mydła pomogą przywrócić jej jasny, błyszczący wygląd.",
    },
    {
      icon: require("images/blog/wosh-go-sport-ico-5.svg").default,
      description:
        "Warto również regularnie korzystać z odświeżającego dezodorantu do butów. Taki preparat zapobiega przykrym zapachom i zapewnia ochronę przed bakteriami i grzybami. ",
    },
    {
      icon: require("images/blog/wosh-go-sport-ico-6.svg").default,
      description:
        "Dodatkowy tip! Biegacze, którzy trenują codziennie, powinni posiadać przynajmniej dwie pary butów. Podeszwa i warstwa amortyzująca są podczas biegu mocno ugniatane, więc dobrze dać im czas na powrót do pełnego rozprężenia, co w zależności od butów wynosi od 12 do 24 godzin. To również idealny czas na pełne wyschnięcie butów.",
    },
  ]

  return (
    <StyledWoshGoSub>
      <div className="wosh-go-sub-post">
        <div className="wosh-go-sub-post__video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/8FifC5Z9yRA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div className="wosh-go-sub-post__header">
          <img src={require("images/blog/wosh-go-header.svg").default} alt="" />
        </div>

        <div className="wosh-go-sub-post__items">
          {data.map((item, index) => (
            <div className="wosh-go-sub-post__item" key={index}>
              <img src={item.icon} alt="" />
              <p>{item.description}</p>
            </div>
          ))}
        </div>

        <h4>Potrzebujesz nowej pary butów?</h4>
        <p>
          Dowiedz się, jak kupując obuwie 4F uzyskać rabat <br />
          na usługę czyszczenia butów w woshwosh.
        </p>
        <Button
          text="zyskaj rabat"
          theme="current bordered-hover"
          as="link-out"
          href="https://4f.com.pl/landing/4f-change-woshgo"
        />
      </div>
    </StyledWoshGoSub>
  )
}

export default WoshGoSport
