exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-dla-mediow-js": () => import("./../../../src/pages/dla-mediow.js" /* webpackChunkName: "component---src-pages-dla-mediow-js" */),
  "component---src-pages-dolacz-js": () => import("./../../../src/pages/dolacz.js" /* webpackChunkName: "component---src-pages-dolacz-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-cookies-policy-js": () => import("./../../../src/pages/en/cookies_policy.js" /* webpackChunkName: "component---src-pages-en-cookies-policy-js" */),
  "component---src-pages-en-faq-js": () => import("./../../../src/pages/en/faq.js" /* webpackChunkName: "component---src-pages-en-faq-js" */),
  "component---src-pages-en-for-media-js": () => import("./../../../src/pages/en/for-media.js" /* webpackChunkName: "component---src-pages-en-for-media-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-join-js": () => import("./../../../src/pages/en/join.js" /* webpackChunkName: "component---src-pages-en-join-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-order-courier-js": () => import("./../../../src/pages/en/order-courier.js" /* webpackChunkName: "component---src-pages-en-order-courier-js" */),
  "component---src-pages-en-rent-index-js": () => import("./../../../src/pages/en/rent/index.js" /* webpackChunkName: "component---src-pages-en-rent-index-js" */),
  "component---src-pages-en-rent-rules-js": () => import("./../../../src/pages/en/rent/rules.js" /* webpackChunkName: "component---src-pages-en-rent-rules-js" */),
  "component---src-pages-en-rent-thanks-js": () => import("./../../../src/pages/en/rent/thanks.js" /* webpackChunkName: "component---src-pages-en-rent-thanks-js" */),
  "component---src-pages-en-service-terms-js": () => import("./../../../src/pages/en/service-terms.js" /* webpackChunkName: "component---src-pages-en-service-terms-js" */),
  "component---src-pages-en-strategy-js": () => import("./../../../src/pages/en/strategy.js" /* webpackChunkName: "component---src-pages-en-strategy-js" */),
  "component---src-pages-en-thanks-js": () => import("./../../../src/pages/en/thanks.js" /* webpackChunkName: "component---src-pages-en-thanks-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instrukcja-diy-js": () => import("./../../../src/pages/instrukcja-diy.js" /* webpackChunkName: "component---src-pages-instrukcja-diy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lista-cookies-podmiotow-trzecich-js": () => import("./../../../src/pages/lista-cookies-podmiotow-trzecich.js" /* webpackChunkName: "component---src-pages-lista-cookies-podmiotow-trzecich-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-podziekowanie-js": () => import("./../../../src/pages/podziekowanie.js" /* webpackChunkName: "component---src-pages-podziekowanie-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-potwierdzenie-js": () => import("./../../../src/pages/potwierdzenie.js" /* webpackChunkName: "component---src-pages-potwierdzenie-js" */),
  "component---src-pages-regulamin-serwisu-js": () => import("./../../../src/pages/regulamin-serwisu.js" /* webpackChunkName: "component---src-pages-regulamin-serwisu-js" */),
  "component---src-pages-strategia-js": () => import("./../../../src/pages/strategia.js" /* webpackChunkName: "component---src-pages-strategia-js" */),
  "component---src-pages-wypozyczalnia-index-js": () => import("./../../../src/pages/wypozyczalnia/index.js" /* webpackChunkName: "component---src-pages-wypozyczalnia-index-js" */),
  "component---src-pages-wypozyczalnia-podziekowanie-js": () => import("./../../../src/pages/wypozyczalnia/podziekowanie.js" /* webpackChunkName: "component---src-pages-wypozyczalnia-podziekowanie-js" */),
  "component---src-pages-wypozyczalnia-regulamin-js": () => import("./../../../src/pages/wypozyczalnia/regulamin.js" /* webpackChunkName: "component---src-pages-wypozyczalnia-regulamin-js" */),
  "component---src-pages-zamow-kuriera-js": () => import("./../../../src/pages/zamow-kuriera.js" /* webpackChunkName: "component---src-pages-zamow-kuriera-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

