import React, { useState } from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import StyledTextarea from "./styles"

const Textarea = ({ name, label, theme }) => {
  const [field, meta] = useField({ name })
  const [focused, setFocused] = useState(!!field?.value?.length > 0)

  return (
    <StyledTextarea theme={theme} focused={focused}>
      <label className="inp-label">{label}</label>
      <textarea
        {...field}
        onBlur={e => {
          setFocused(!!field?.value?.length > 0)
          field.onBlur(e)
        }}
        onFocus={() => setFocused(true)}
        id={name}
      />
      {meta.touched && meta.error ? (
        <p className="inp-error">{meta.error}</p>
      ) : null}
    </StyledTextarea>
  )
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default Textarea
