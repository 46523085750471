import styled from "styled-components"

const StyledHero = styled.section`
  .hero {
    &__wrapper {
      min-height: 551px;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
      padding: 0 0 70px 0;
      overflow: hidden;
      position: relative;

      @media (max-width: 575px) {
        min-height: 351px;
        padding: 0 0 30px 0;
      }

      &--top {
        background-position: top center;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.45));
        z-index: 1;
      }

      > div {
        position: relative;
        z-index: 2;
        width: 100%;
      }
    }

    &__date {
      display: flex;
      color: #fff;
      font-weight: 700;
      position: relative;
      padding: 0 0 0 20px;

      &:before {
        content: "";
        width: 14px;
        height: 13px;
        position: absolute;
        top: 3px;
        left: 0;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbl9pb25pYy1pb3MtY2FsZW5kYXIiIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtY2FsZW5kYXIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjEyLjkyMyIgdmlld0JveD0iMCAwIDE0IDEyLjkyMyI+CiAgPHBhdGggaWQ9IlBhdGhfMzkxIiBkYXRhLW5hbWU9IlBhdGggMzkxIiBkPSJNMTYuMDI5LDYuNzVIMTQuNjgzdi44MDhhLjI3LjI3LDAsMCwxLS4yNjkuMjY5aC0uNTM4YS4yNy4yNywwLDAsMS0uMjY5LS4yNjlWNi43NUg3LjE0NHYuODA4YS4yNy4yNywwLDAsMS0uMjY5LjI2OUg2LjMzN2EuMjcuMjcsMCwwLDEtLjI2OS0uMjY5VjYuNzVINC43MjFBMS4zNSwxLjM1LDAsMCwwLDMuMzc1LDguMVYxNy4yNUExLjM1LDEuMzUsMCwwLDAsNC43MjEsMTguNkgxNi4wMjlhMS4zNSwxLjM1LDAsMCwwLDEuMzQ2LTEuMzQ2VjguMUExLjM1LDEuMzUsMCwwLDAsMTYuMDI5LDYuNzVabS4yNjksMTAuMWEuNjc1LjY3NSwwLDAsMS0uNjczLjY3M0g1LjEyNWEuNjc1LjY3NSwwLDAsMS0uNjczLS42NzNWMTAuNzg4YS4yNy4yNywwLDAsMSwuMjY5LS4yNjlIMTYuMDI5YS4yNy4yNywwLDAsMSwuMjY5LjI2OVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjM3NSAtNS42NzMpIiBmaWxsPSIjZmZmIi8+CiAgPHBhdGggaWQ9IlBhdGhfMzkyIiBkYXRhLW5hbWU9IlBhdGggMzkyIiBkPSJNMTAuMDc3LDQuNzY5QS4yNy4yNywwLDAsMCw5LjgwOCw0LjVIOS4yNjlBLjI3LjI3LDAsMCwwLDksNC43Njl2LjgwOGgxLjA3N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjMwOCAtNC41KSIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGlkPSJQYXRoXzM5MyIgZGF0YS1uYW1lPSJQYXRoIDM5MyIgZD0iTTI1LjgyNyw0Ljc2OWEuMjcuMjcsMCwwLDAtLjI2OS0uMjY5aC0uNTM4YS4yNy4yNywwLDAsMC0uMjY5LjI2OXYuODA4aDEuMDc3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0LjUxOSAtNC41KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__label {
      display: inline-flex;
      padding: 3px 17px;
      color: #00fb6e;
      background-color: #000;
      margin: 10px 0;
      position: relative;
      left: -17px;
    }

    &__header {
      color: #fff;
      text-transform: uppercase;
      max-width: 50%;

      @media (max-width: 1199px) {
        max-width: 100%;
      }

      @media (max-width: 575px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
`

export default StyledHero
