import StyledFixWinter from "./styles"

import React from "react"
import { navigate } from "gatsby"
import { useLocale } from "context/locale"

const FixWinter = () => {
  const { t, lang } = useLocale()

  return (
    <StyledFixWinter
      text={lang === "en" ? "See location" : "Zobacz lokalizację"}
    >
      <div className="fix-winter-post">
        <p>
          {t(
            "Jeśli Twoja kurtka (wszystkie rodzaje) lub spodnie marki 4F (narciarskie/snowboardowe) wymagają naprawy – przynieś je do nas. Śniegu nie zagwarantujemy, ale zajmiemy się całą resztą! Wycenimy, naprawimy, a ty będziesz móc cieszyć się zimą i swoim ulubionym strojem."
          )}
        </p>

        <div className="fix-winter-post__dates">
          <h4>{t("Czekamy na Ciebie w dniach:")}</h4>
          <div>
            <img
              src={require("images/blog/fix-winter-calendar-1.svg").default}
              alt=""
            />
            <img
              src={require("images/blog/fix-winter-calendar-2.svg").default}
              alt=""
            />
            <img
              src={require("images/blog/fix-winter-calendar-3.svg").default}
              alt=""
            />
          </div>
          <h4>{t("w godzinach otwarcia sklepu")}</h4>
        </div>

        <p>{t("Akcja odbędzie się w wybranych salonach 4F:")}</p>

        <div className="fix-winter-post__locations">
          <div className="fix-winter-post__locations-wrapper">
            <img
              src={require("images/blog/fix-winter-locations-icon.svg").default}
              alt=""
            />
            <div>
              <button onClick={() => navigate("/dolacz?shop=ch-arkadia")}>
                <strong>{t("Warszawa")}</strong> <span>{t("CH Arkadia")}</span>
              </button>
              <button onClick={() => navigate("/dolacz?shop=ch-bonarka")}>
                <strong>{t("Kraków")}</strong> <span>{t("CH Bonarka")}</span>
              </button>
              <button onClick={() => navigate("/dolacz?shop=galeria-baltycka")}>
                <strong>{t("Gdańsk")}</strong>{" "}
                <span>{t("Galeria Bałtycka")}</span>
              </button>
              <button
                onClick={() => navigate("/dolacz?shop=galeria-katowicka")}
              >
                <strong>{t("Katowice")}</strong>{" "}
                <span>{t("Galeria Katowicka")}</span>
              </button>
            </div>
          </div>
        </div>

        <div className="custom-image-wrapper aligncenter">
          <img
            className="img-fluid"
            src={require("images/blog/fix-winter-content-image.jpg").default}
            alt=""
          />
        </div>

        <div className="fix-winter-post__pricing">
          <h3>Cennik</h3>
          <div className="fix-winter-post__pricing-header">
            <p>TYP NAPRAWY</p>
            <p>Cena (PLN)</p>
            <p>UWAGI</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Rozprucie na szwie</p>
            <p>35</p>
            <p>1 rozprucie</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Rozdarcie</p>
            <p>35</p>
            <p>1 rozdarcie</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Wymiana zamka (zamek zwykły)</p>
            <p>70</p>
            <p>zamek wliczony w koszt usługi</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Wymiana zamka (zamek wodoodporny)</p>
            <p>80</p>
            <p>zamek wliczony w koszt usługi</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Wymiana suwaka (slidera)</p>
            <p>20</p>
            <p>1 suwak</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Wymiana zatrzasku (napa)</p>
            <p>10</p>
            <p>1 zatrzask</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Wymiana rzepów</p>
            <p>10</p>
            <p>1 rzep</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Naprawa/wymiana gumy ściągającej (regulującej)</p>
            <p>35</p>
            <p>1 sztuka</p>
          </div>
          <div className="fix-winter-post__pricing-row">
            <p>Wymiana pullera/stopera</p>
            <p>25</p>
            <p>komplet cały produkt</p>
          </div>
        </div>

        <p>
          {t("Chcesz wiedzieć więcej?")}
          <br />
          <a
            href="https://4f.com.pl/landing/regulamin-naprawa-ubran/"
            target="_blank"
            rel="noreferrer"
          >
            SPRAWDŹ REGULAMIN
          </a>
        </p>

        <img src={require("images/blog/fix-winter-qr.jpg").default} alt="" />
      </div>
    </StyledFixWinter>
  )
}

export default FixWinter
