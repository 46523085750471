import React from "react"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Button from "components/Button"
import ThanksIcon from "icons/thanks.svg"

import StyledCourierThankYouPage from "styles/CourierThankYouStyles"

const CourierThankYouPage = ({ location }) => (
  <Layout seo={{ title: "Dziękujemy" }} location={location}>
    <PageHeader title="Dziękujemy!" />
    <StyledCourierThankYouPage>
      <div className="main-container">
        <div className="main-wrapper">
          <div className="icon-holder">
            <ThanksIcon />
          </div>
          <p>
            Na adres e-mail podany w formularzu otrzymasz link, który potwierdzi
            założenie Twojego konta w portalu Ubrania do Oddania. Jest ono
            potrzebne, aby kurier mógł odebrać od Ciebie spakowane ubrania, a
            Twoja paczka mogła trafić bezpośrednio do sortowni. Za każdy oddany
            1 kg przekażemy 1 zł na Fundację 4FPOMAGA.
          </p>
          <Button text="Strona główna" as="link" href="/" />
        </div>
      </div>
    </StyledCourierThankYouPage>
  </Layout>
)

export default CourierThankYouPage
