import styled from "styled-components"

const StyledContactThankYouPage = styled.section`
  padding: 0 0 150px;
  .main-wrapper {
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
    .icon-holder {
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 0 0 50px;
  }
`

export default StyledContactThankYouPage
