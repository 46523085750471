/* eslint-disable react/jsx-no-target-blank */

import StyledDontBuyAdopt from "./styles"

import React from "react"

import Button from "components/Button"
import { useLocale } from "context/locale"

const DontBuyAdopt = () => {
  const { t } = useLocale()

  return (
    <StyledDontBuyAdopt>
      <div className="dont-buy-adopt">
        <p>
          {t(
            "Jednak nie wszystkie czworonogi mają to szczęście, że mają dom, a co dopiero ciepłe ubranka. Dlatego postanowiliśmy zaangażować się w znalezienie nowych właścicieli dla podopiecznych schroniska Psie Pole – Lidii, Szufcia, Szymcia i Psotnicy. Znajdziemy im wspólnie dom przed zimą?"
          )}
        </p>
        <p>
          <strong>
            <a href="https://schroniskopsiepole.pl" target="_blank">
              {t("Schronisko Psie Pole")}
            </a>
          </strong>
        </p>
        <p>
          {t(
            "Poznajcie schronisko Psie Pole, w którym obecnie przebywają wspominane pieski. Celem działania schroniska jest zapewnienie opieki nad porzuconymi i niechcianymi psami i kotami, które trafiają do niego po zgłoszeniu przez urzędy gmin, policję i straż miejską. Często zostały one odebrane swoim właścicielom w związku z ich niewłaściwym traktowaniem lub przetrzymywaniem w złych warunkach. Bywają wśród nich zwierzaki bardzo młode (kilkunastodniowe), dorosłe, a także staruszki (powyżej 10 lat)."
          )}
        </p>
        <p>
          {t(
            "Opiekunowie w schronisku dokładają wszelkich starań, by naprawić to, co inni zaniedbali. Starają się, by zwierzęta wróciły do zdrowia i znalazły dobre i troskliwe domy, w których będą mogły wieść beztroskie życie, na jakie zasługują!"
          )}
        </p>

        <div className="dont-buy-adopt__grid">
          <h3>{t("Pieski polecają się do adopcji!")}</h3>
          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={require("images/blog/dont-buy-adopt-lidia.jpg").default}
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <p>
                  <strong>Lidia</strong>{" "}
                  {t(
                    "to dorosła, pięcioletnia suczka średniej wielkości (ok. 12kg). Lidia jest niesamowicie kontaktowa, uwielbia pieszczoty i kontakt z człowiekiem. Najchętniej siedziałaby na kolanach opiekunów całymi dniami. Dogaduje się z innymi pieskami, ale jest dość zaborcza i broni swojej miseczki. Lidia trafiła do schroniska po wypadku, w wyniku którego przeszła operację tylnej łapki – obecnie porusza się coraz sprawniej, biega, podskakuje i bawi się z innymi psami."
                  )}
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/lidia/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("więcej o")} Lidii
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-szufcio.jpg").default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <p>
                  <strong>Szufcio</strong>{" "}
                  {t(
                    "to psiak w wieku około trzech lat i wadze 8 kilogramów. Szufcio do niedawna błąkał się po okolicy i bardzo bał się podejść do człowieka. Do schroniska trafił w obroży, co oznacza, że prawdopodobnie miał w przeszłości swój dom i właścicieli. W tym momencie Szufcio jest nieco bardziej pewny siebie, daje się wziąć na ręce i głaskać. Piesek potrafi chodzić na smyczy."
                  )}
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/szufcio/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("więcej o")} Szufciu
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-szymcio.jpg").default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <p>
                  <strong>Szymcio</strong>{" "}
                  {t(
                    "to niewielki piesek o miłym charakterze i wielkim serduszku. Szymcio jest dorosłym psiakiem w wieku ok. 5 lat i wadze ok. 6,5 kg. To wyjątkowo kontaktowy pies, który uwielbia być głaskany i przebywać w towarzystwie człowieka. Nie znamy jego przeszłości, jednak jego zachowanie wskazuje na to, że miał kiedyś dom, w którym ktoś o niego dbał. Szymcio umie chodzić na smyczy i dobrze dogaduje się z innymi psami. Ma raczej spokojne usposobienie, więc może zamieszkać zarówno w domu z ogrodem, jak i w mieszkaniu w bloku."
                  )}
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/szymcio/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("więcej o")} Szymciu
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="dont-buy-adopt__item">
            <div className="dont-buy-adopt__item-row">
              <div className="dont-buy-adopt__item-col">
                <img
                  className="img-fluid"
                  src={
                    require("images/blog/dont-buy-adopt-psotnica.jpg").default
                  }
                  alt=""
                />
              </div>
              <div className="dont-buy-adopt__item-col">
                <p>
                  <strong>Psotnica</strong>{" "}
                  {t(
                    "to młodziutka suczka w wieku ok. 8 miesięcy. Jest malutka i drobniutka (ok. 6 kg), za to może poszczycić się imponujący przodozgryzem ;) Psotnica to imię nieprzypadkowe, bo sunia ma ogromne pokłady energii i uwielbia psoty, najlepiej w towarzystwie – zarówno ludzkim, jak i psim. Garnie się na ręce i najchętniej by z nich nie schodziła! Psotnica chodzi na smyczy, uwielbia spacery i jest bardzo ciekawska."
                  )}
                </p>
                <p>
                  <strong>
                    <a
                      href="https://schroniskopsiepole.pl/Project/psotnica/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("więcej o")} Psotnicy
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="dont-buy-adopt__description">
          <p>
            <strong>Lidia, Szufcio, Szymcio i Psotnica</strong>,{" "}
            {t("a także inne pieski ze")}
            pieski ze{" "}
            <strong>
              <a
                href="https://schroniskopsiepole.pl/"
                target="_blank"
                rel="noreferrer"
              >
                {t("schroniska Psie Pole")}
              </a>
            </strong>{" "}
            {t(
              "czekają na ciepły, troskliwy dom. Pomóżmy im go znaleźć przed zimą! Udostępniajcie nasz artykuł, komentujcie, a może… Może sami szukacie przyjaciela na lata? :)"
            )}
          </p>
        </div>

        <div
          className="dont-buy-adopt__baner"
          style={{
            backgroundImage: `url('${
              require("images/blog/dont-buy-adopt-baner-bg.jpg").default
            }')`,
          }}
        >
          <h3>
            UPCYCLED JACKETS.
            <br /> DOG APPROVED
          </h3>
          <Button
            text={t("Learn more")}
            as="link"
            href={t("upcycled_jacket_dog_approved_link")}
          />
        </div>
      </div>
    </StyledDontBuyAdopt>
  )
}

export default DontBuyAdopt
