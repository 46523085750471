import styled from "styled-components"

const StyledThanks = styled.section`
  padding: 100px 0 0;
  @media (max-width: 1199px) {
    padding: 75px 0 0;
  }
  text-align: center;
  margin: 0 auto;
  h2 {
    margin-bottom: 30px;
  }
  p {
    font-size: 25px;
    line-height: 33px;
  }
  .image-holder {
    margin-top: 30px;
  }
`

export default StyledThanks
