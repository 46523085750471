import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

const InstructionDIY = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Instrukcja DIY",
    }}
  >
    <PageHeader title="Instrukcja DIY" />
  </Layout>
)

export default InstructionDIY
