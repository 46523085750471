import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import NotFoundContent from 'page_components/not_found/Content'

import StyledNotFound from "styles/NotFoundStyles"

const NotFoundPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        not_found_bg: file(relativePath: { eq: "not_found_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Layout
      location={location}
      seo={{ title: "404" }}
      transparent={true}
      nav_dark={true}
      show_fixed_buttons={false}
    >
      <BackgroundImage
        className="item-background"
        fluid={data.not_found_bg.childImageSharp.fluid}
      >
        <StyledNotFound>
          <NotFoundContent/>
        </StyledNotFound>
      </BackgroundImage>
    </Layout>
  )
}

export default NotFoundPage
