import styled from "styled-components"

const StyledBreadcrumbs = styled.section`
  padding: 55px 0;

  @media (max-width: 575px) {
    padding: 30px 0;
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    a {
      transition: all 150ms linear;

      &:hover {
        color: #00fb6e;
      }
    }

    * {
      display: block;
      position: relative;

      &:after {
        content: "/";
        margin: 0 2px;
        color: #000;
      }

      &:last-child {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:after {
          content: none;
        }
      }
    }
  }
`

export default StyledBreadcrumbs
