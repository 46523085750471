import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"

import StyledDescriptionPage from "styles/DescriptionPageStyles"

const Faq = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      acf: wpPage(id: { eq: "cG9zdDoxMjU=" }) {
        title
        acfFaq {
          faqContent {
            faqContentQuestion
            faqContentAnswerDescription
          }
        }
      }
    }
  `)

  return (
    <Layout
      location={location}
      seo={{
        title: `${data?.acf?.title} - Pytania i odpowiedzi - 4F Change`,
        description:
          "Poznaj odpowiedzi na najczęściej zadawanie pytania dotyczące strategii 4F Change oraz programu wear_fair.",
      }}
    >
      <PageHeader title={data?.acf?.title} />

      <StyledDescriptionPage>
        <div className="description-page">
          {data?.acf?.acfFaq?.faqContent.map((item, index) => (
            <div key={index} className="description-page__item">
              <h4>
                {index + 1}. {item?.faqContentQuestion}
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.faqContentAnswerDescription,
                }}
              />
            </div>
          ))}
        </div>
      </StyledDescriptionPage>
    </Layout>
  )
}

export default Faq
