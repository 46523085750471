import React from "react"
import { Link } from "gatsby"
import { useLocale } from "context/locale"

import StyledHero from "./styles"

const Hero = ({ post }) => {
  const hero_top = ["cG9zdDozMTk="].includes(post?.id)
  const { t } = useLocale()
  return (
    <StyledHero>
      <div
        className={`hero__wrapper ${hero_top ? "hero__wrapper--top" : ""}`}
        style={{
          backgroundImage: `url('${post?.featuredImage?.node?.localFile?.publicURL}')`,
        }}
      >
        <div className="main-container">
          <date className="hero__date">
            {post?.date.split("T")[0].split("-").reverse().join(".")}
          </date>
          <h4 className="hero__label">{post?.categories?.nodes[0]?.name}</h4>
          <Link to={`${t("news_link")}${post?.slug}/`}>
            <h2 className="hero__header">{post?.title}</h2>
          </Link>
        </div>
      </div>
    </StyledHero>
  )
}

export default Hero
