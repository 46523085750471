import styled from "styled-components"

const StyledRentalThankYouPage = styled.section`
  padding: 0 0 100px;
  .main-wrapper {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    .icon-holder {
      margin-bottom: 40px;
    }
    h4 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 30px;
    }
    .button-group {
      display: flex;
      justify-content: center;
      a {
        margin: 0 15px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 0 0 50px;
    .main-wrapper {
      .button-group {
        flex-direction: column;
        a {
          margin: 10px 0;
        }
      }
    }
  }
`

export default StyledRentalThankYouPage
