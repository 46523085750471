import React from "react"
import PropTypes from "prop-types"

import StyledPageHeader from "./styles"

const PageHeader = ({ title }) => (
  <StyledPageHeader>
    <div className="main-container">
      <h1 className="title">{title}</h1>
    </div>
  </StyledPageHeader>
)
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageHeader
